import { V1 } from './net'
import { StoreThunkDispatch, StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { getProductById } from 'services/product'
import { createAction } from '@reduxjs/toolkit'
import { PPProduct } from 'shared'

export const startImpersonation = createAction<{ user: any, productInfo: PPProduct | undefined }>('admin/impersonate/start')
export const ACTION_IMPERSONATE_STOP = 'ACTION_IMPERSONATE_STOP'

export function impersonateUser(user: any) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.impersonate(user.userId)).subscribe(() => {
      const productInfo = dispatch(getProductById(user.product))

      dispatch(startImpersonation({ user, productInfo }))
    })
  }
}

export function stopImpersonateUser() {
  return {
    type: ACTION_IMPERSONATE_STOP
  }
}

export function searchUsers(query: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.searchUsers(query.trim())).map(response => {
      if (response.error) {
        throw (new Error('An error occured: ' + response.error))
      }

      return response.data
    })
  }
}
