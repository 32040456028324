import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import CircularProgress from '@mui/material/CircularProgress'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import { Observable } from 'rxjs/Observable'

const PAGE_TITLE = 'Team Members'
const LOADER_SIZE = 20
import styles from './Team.pcss'

interface TeamMembersSearch {
  emails: string
  userIds: string
  appIds: string
}

export interface TeamProps {
  data: any[]
  loading: boolean
  error?: Error
  message: (text: string) => void
  onSearch: (emails?: string[], userIds?: string[], appIds?: string[]) => void
  onSwitchChargifyAccounts: (ids: string[]) => Observable<any>
}

export function TeamMembers(props: TeamProps) {
  const [formValues, setFormValues] = React.useState<TeamMembersSearch>({ emails: '', userIds: '', appIds: '' })
  const [selectedRows, setSelectedRows] = React.useState<string[]>([])

  const setFormValue = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFormValues(prevForm => ({ ...prevForm, [key]: value }))
  }

  const onSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rowId = e.target.closest('td')?.dataset.id
    if (!rowId) {
      return
    }

    const index = selectedRows.indexOf(rowId)
    const next = [...selectedRows]

    if (index !== -1) {
      next.splice(index, 1)
    } else {
      next.push(rowId)
    }
    setSelectedRows(next)
  }

  const search = (e: React.FormEvent) => {
    e.preventDefault()
    if (props.loading) {
      return
    }

    const emails = formValues.emails.split(',').map(e => e.trim()).filter(Boolean)
    const userIds = formValues.userIds.split(',').map(i => i.trim()).filter(Boolean)
    const appIds = formValues.appIds.split(',').map(i => i.trim()).filter(Boolean)

    props.onSearch(emails, userIds, appIds)
  }

  const switchChargifyAccounts = () => {
    if (selectedRows.length !== 2) {
      return
    }
    const responseHandler = (response: any) => {
      props.message(response.result)
    }
    props.onSwitchChargifyAccounts(selectedRows).subscribe(responseHandler, responseHandler)
  }

  return (
    <AdminPage title={PAGE_TITLE}>
      <div className={styles.search}>
        <form className={styles.form} onSubmit={search}>
          <TextField
            placeholder="Emails"
            multiline
            className={styles.input}
            classes={{ root: styles['input-email'] }}
            value={formValues.emails}
            onChange={setFormValue('emails')}
          />
          <TextField
            placeholder="User ids"
            className={styles.input}
            value={formValues.userIds}
            onChange={setFormValue('userIds')}
          />
          <TextField
            placeholder="App ids"
            value={formValues.appIds}
            className={styles.input}
            onChange={setFormValue('appIds')}
          />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={styles['btn-search']}
            disabled={props.loading}
          >
            {!props.loading && 'Search'}
            {props.loading && <CircularProgress size={LOADER_SIZE} className={styles.loader} />}
          </Button>
        </form>
        <Paper className={styles.content}>
          {
            props.data?.length && (
              <Table className={styles.table}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell key="cell-id">User ID</TableCell>
                    <TableCell key="cell-email">Email</TableCell>
                    <TableCell key="cell-name">Name</TableCell>
                    <TableCell key="cell-fb">FB profile</TableCell>
                    <TableCell key="cell-admin">Master Admin</TableCell>
                    <TableCell key="cell-app">App ID</TableCell>
                    <TableCell key="csll-product">Product (Active)</TableCell>
                    <TableCell key="cell-token">Token</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.data.map(user => (
                      <TableRow key={`row-user-${user.userId}`} className={styles.tr}>
                        <TableCell data-id={user.userId}>
                          <Checkbox checked={selectedRows.includes(user.userId)} onChange={onSelectionChange} />
                        </TableCell>
                        <TableCell>{user.userId}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.fullName}</TableCell>
                        <TableCell dangerouslySetInnerHTML={{ __html: user.facebookLink }}></TableCell>
                        <TableCell>{user.masterAdmin}</TableCell>
                        <TableCell>{user.appId}</TableCell>
                        <TableCell dangerouslySetInnerHTML={{ __html: user.product }}></TableCell>
                        <TableCell>
                          <a href={`https://developers.facebook.com/tools/debug/accesstoken?access_token=${user.token}`} target="_blank">
                            {user.hasToken}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            )
          }
          {
            selectedRows.length === 2 && (
              <div className={styles.actions}>
                <p className={styles.warning}>Chargify Account Switch</p>
                <p className={styles.warning}>To switch Chargify accounts selected above, one of them needs to be on a PRO plan</p>
                <Button variant="contained" color="primary" className={styles['btn-switch']} onClick={switchChargifyAccounts}>
                  Switch Chargify Accounts
                </Button>
              </div>
            )
          }
        </Paper>
        <p>{props.error?.message}</p>
      </div>
    </AdminPage>
  )
}

export default TeamMembers
