import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import FeatureTableRow from './components/FeatureTableRow'
import { FeatureInfo, PlanLimitInfo } from 'interfaces'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { V1 as api } from 'admin/services/features/net'
import LimitTableRow from './components/LimitTableRow'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { sortByKeyAscending } from 'utils/sort/order'

import styles from './FeaturesManager.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector } from 'services/product'
import Button from '@mui/material/Button'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'

const TAB_FEATURES = 'features'
const TAB_LIMITS = 'limits'

interface FeaturesManagerProps {
  features: FeatureInfo[]
  limits: PlanLimitInfo[]
  plans: Record<string, string>
  loading: boolean
  onUpdateFeature: (feature: FeatureInfo, file: File | null, order?: number) => void
  onUpdateLimit: (limit: PlanLimitInfo, file: File | null, order?: number) => void
  openFeatureUpgradeDialog: (feature: string, product: string) => void
  openLimitUpgradeDialog: (limitKey: string, productHandle: string) => void
}

export function FeaturesManager(props: FeaturesManagerProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [activeTab, setActiveTab] = React.useState<string>(TAB_FEATURES)
  const [features, setFeatures] = React.useState(props.features.sort(sortByKeyAscending('order')))
  const [limits, setLimits] = React.useState(props.limits.sort(sortByKeyAscending('order')))
  const products = useSelector(productsSelector)
  const upgradePlan = products[products.length - 1]

  const openLimitPopup = (limit: string) => {
    props.openLimitUpgradeDialog(limit, upgradePlan?.handles.annual || '')
  }

  const openFeaturePopup = (feat: string) => {
    props.openFeatureUpgradeDialog(feat, upgradePlan?.handles.annual || '')
  }

  React.useEffect(() => {
    setFeatures(props.features.sort(sortByKeyAscending('order')))
  }, [props.features])

  React.useEffect(() => {
    setLimits(props.limits.sort(sortByKeyAscending('order')))
  }, [props.limits])

  const onTabChange = (_event: any, nextTab: string) => {
    setActiveTab(nextTab)
  }

  const resetCache = () => {
    dispatch(api.resetCache()).toPromise().then(() => {
      dispatch(message('Cache cleared!'))
    }, (e) => {
      dispatch(message(`Something went wrong: ${e.message}!`))
    })
  }

  return (
    <AdminPage>
      <Backdrop open={props.loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div className={styles.top}>
        <Button variant="contained" color="primary" onClick={resetCache}>Reset cache</Button>
      </div>
      <Tabs value={activeTab} centered indicatorColor="primary" onChange={onTabChange}>
        <Tab value={TAB_FEATURES} label={TAB_FEATURES} disableRipple className={styles.tab} />
        <Tab value={TAB_LIMITS} label={TAB_LIMITS} disableRipple className={styles.tab} />
      </Tabs>

      <Paper className={styles.paper}>
        <TableContainer className={styles['table-container']}>
          {
            activeTab === TAB_FEATURES && (
              <Table className={styles.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Feature</TableCell>
                    <TableCell>Sales Pitch</TableCell>
                    <TableCell>Sales Pitch (Mobile)</TableCell>
                    <TableCell>CTA (Mobile)</TableCell>
                    <TableCell>Dismiss popup text</TableCell>
                    <TableCell>Upgrade btn text</TableCell>
                    <TableCell>
                      <Tooltip placement="top" title="Lowest plan where feature is available for use">
                        <span>Plan</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell key="cell-data"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    features.map((feat) => (
                      <FeatureTableRow
                        key={feat.id}
                        feature={feat}
                        plans={props.plans}
                        onUpdate={props.onUpdateFeature}
                        onFileUploadError={console.error}
                        onPreviewClick={openFeaturePopup}
                      />
                    ))
                  }
                </TableBody>
              </Table>
            )
          }
          {
            activeTab === TAB_LIMITS && (
              <Table className={styles.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Limit</TableCell>
                    <TableCell>Sales Pitch</TableCell>
                    <TableCell>Sales Pitch (Mobile)</TableCell>
                    <TableCell>CTA (Mobile)</TableCell>
                    <TableCell>Dismiss popup text</TableCell>
                    <TableCell>Upgrade btn text</TableCell>
                    {Object.keys(props.plans).map(id => <TableCell key={id}>{props.plans[id]}</TableCell>)}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    limits.map((limit) => (
                      <LimitTableRow
                        key={limit.id}
                        limit={limit}
                        plans={props.plans}
                        onUpdate={props.onUpdateLimit}
                        onFileUploadError={console.error}
                        onPreviewClick={openLimitPopup}
                      />
                    ))
                  }
                </TableBody>
              </Table>
            )
          }
        </TableContainer>
      </Paper>
    </AdminPage>
  )
}

export default FeaturesManager
