import { PPProduct } from 'interfaces'

export const ADMIN_STATE_VERSION = 'admin:1'

export interface AdminState {
  isLogged: boolean
  impersonate?: {
    userId: string
    name: string
    token: string
    productInfo?: PPProduct
  }
}

export function initialState(): AdminState {
  return {
    isLogged: false,
    impersonate: undefined
  }
}
