import * as React from 'react'
import styles from './AppsRoute.pcss'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

export interface AppInfo {
  id: string
  userId: string
  adminLimit: string
  active: string
  product: string
  email: string
  chargifySubscriptionId: string
  fullName: string
  profilesLimit?: number
  postsPerDay?: number
  posts: any
  allowedLocations?: number
  isBeta: '0' | '1'
  masterAdmin: '0' | '1'
  fbTokens: string[]
  userBlocked: any
  impersonateAccessToken: string
}

interface AppTableRowProps {
  app: AppInfo
  highlight: boolean
  onClick: (app: AppInfo) => void
  onClearFBTokens: (userId: string) => void
  toggleUserBlocked: (userId: string, current: boolean) => void
  resetPassword: (userId: string) => void
  clearUserData: (userId: string) => void
  restartTrial: (appId: string) => void
  onViewData: (userId: string) => void
  onImpersonate: (user: { userId: string, product: string, fullName: string, accessToken: string }) => void
  makeMasterAdmin: (userId: string, appId: string) => void
}
export function AppTableRow({
  app,
  highlight,
  onClick,
  onClearFBTokens,
  toggleUserBlocked,
  resetPassword,
  clearUserData,
  onViewData,
  restartTrial,
  onImpersonate,
  makeMasterAdmin
}: AppTableRowProps) {
  const { id, userId, product, chargifySubscriptionId, email, fullName, posts, fbTokens, masterAdmin, userBlocked } = app
  const [anchorEl, setAnchorEl] = React.useState(null)

  const isBlocked = parseInt(userBlocked, 10) > 0

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const menuAction = (fn: () => void) => () => {
    closeMenu()
    fn()
  }

  const toggleBlocked = () => {
    toggleUserBlocked(userId, isBlocked)
  }

  const onMakeMasterAdmin = () => {
    makeMasterAdmin(userId, app.id)
  }

  const onResetPassword = () => {
    resetPassword(userId)
  }

  const onRestartTrial = () => {
    restartTrial(id)
  }

  const onRowClick = () => {
    onClick(app)
  }

  const viewData = () => {
    onViewData(userId)
  }

  const clearData = () => {
    clearUserData(userId)
  }

  const clearTokens = () => {
    onClearFBTokens(userId)
  }

  const onImpersonateClick = () => {
    onImpersonate({ userId, product, fullName, accessToken: app.impersonateAccessToken })
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  return (
    <tr className={`${styles.tr} ${highlight ? styles.active : ''}`} onClick={onRowClick}>
      <td className={styles['cell-s']}>{id}</td>
      <td className={styles['cell-s']}>{userId}</td>
      <td className={styles['cell-s']}>{chargifySubscriptionId}</td>
      <td>{email}</td>
      <td>{fullName}</td>
      <td className={styles['cell-s']}>
        <Button className={styles.btn} onClick={viewData}>View</Button>
        <Button className={styles.btn} onClick={clearData}>Clear</Button>
      </td>
      <td className={styles['cell-s']}>{posts}</td>
      <td>
        {fbTokens.map((token) => (
          <div>
            <a href={`https://developers.facebook.com/tools/debug/accesstoken?access_token=${fbTokens[0]}`} target="_blank">
              {`...${token.slice(-7)}`} {/* eslint-disable-line no-magic-numbers */}
            </a>
          </div>
        ))}
        {fbTokens.length === 0 && <div>N/A</div>}
        <Button className={styles.btn} onClick={clearTokens}>Clear</Button>
      </td>
      <td className={styles['cell-s']}>{masterAdmin === '1' ? 'Y' : 'N'}</td>
      <td>
        <Button
          variant="contained"
          color="primary"
          style={{ display: 'block' }}
          onClick={onImpersonateClick}
        >
          Impersonate
        </Button>
      </td>
      <td className={styles['cell-s']}>
        <IconButton
          aria-label="More"
          aria-haspopup="true"
          size="small"
          onClick={openMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          classes={{ paper: styles.menu }}
          onClose={closeMenu}
          onClick={stopPropagation}
        >
          <MenuItem onClick={menuAction(onMakeMasterAdmin)}>
            Make master admin
          </MenuItem>
          <MenuItem onClick={menuAction(onResetPassword)}>
            Reset password
          </MenuItem>
          <MenuItem onClick={menuAction(onRestartTrial)}>
            Restart trial
          </MenuItem>
          <MenuItem onClick={menuAction(toggleBlocked)}>
            {isBlocked ? 'Unblock user' : 'Block user'}
          </MenuItem>
        </Menu>
      </td>
      {/* <td>{props.app.active}</td>
      <td>{props.app.adminLimit}</td>
      <td>{props.app.product}</td>
      <td>{props.app.allowedLocations || 0}</td> */}
    </tr>
  )
}
