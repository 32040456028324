import React, { useEffect, useState } from 'react'
import styles from './CategoryList.pcss'
import Button from '@mui/material/Button'
import { PromptCategory } from 'shared/types'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { DndContext, useDroppable, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import { reorder } from 'shared/utils'
import { PromptCategoryType } from 'shared/types/Composer/writer'
import PPSwitch from 'components/PPSwitch'

interface CategoryListProps {
  categories: PromptCategory[]
  onEditCategory: (category: PromptCategory) => void
  onDeleteCategory: (category: PromptCategory) => void
  onCreateCategory: (name: string, type: string, visible: boolean) => void
  onReorder: (ids: number[]) => void
}

export const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  onEditCategory,
  onDeleteCategory,
  onCreateCategory,
  onReorder
}) => {
  const [newCategoryName, setNewCategoryName] = useState('')
  const [newCategoryVisible, setNewCategoryVisible] = useState(true)
  const [type, setType] = useState<PromptCategoryType>('text')
  const [order, setOrder] = useState(categories.map(c => c.id))
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: 'droppable'
  })

  useEffect(() => {
    setOrder(categories.map(c => c.id))
  }, [categories])

  const handleCreateCategory = () => {
    const name = newCategoryName.trim()
    if (name) {
      onCreateCategory(name, type, newCategoryVisible)
      setNewCategoryName('')
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCreateCategory()
    }
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      onReorder(reordered.map(id => parseInt(id, 10)))
    }
  }

  return (
    <div className={styles['category-list-container']}>
      <h3 className={styles.subtitle}>Categories</h3>
      <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]} layoutMeasuring={{ strategy: MeasuringStrategy.Always }}>
        <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
          <ul ref={setDroppableNodeRef}>
            {categories
              .sort((c1, c2) => order.indexOf(c1.id) - order.indexOf(c2.id))
              .map((category) => (
                <CategoryListItem
                  key={category.id}
                  category={category}
                  onEdit={onEditCategory}
                  onDelete={onDeleteCategory}
                />
              ))}
          </ul>

        </SortableContext>
      </DndContext>
      <h3 className={styles.subtitle}>Add New Category</h3>
      <div className={styles['create-category']}>
        <input
          type="text"
          placeholder="New Category Name..."
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
          onKeyDown={onKeyDown}
        />
        <PPSwitch
          selectedValue={type}
          className={styles.switch}
          options={[{
            value: 'text',
            label: 'Text'
          }, {
            value: 'image',
            label: 'Image'
          }, {
            value: 'video',
            label: 'Video'
          }, {
            value: 'story',
            label: 'Story'
          }, {
            value: 'reel',
            label: 'Reel'
          }]}
          onSelectedValueChange={setType as any}
        />
        <FormControlLabel
          label="Visible"
          labelPlacement="end"
          classes={{ label: styles.cbLabel, root: styles.cbRoot }}
          control={(
            <Checkbox
              checked={newCategoryVisible}
              color="primary"
              onChange={() => setNewCategoryVisible(current => !current)}
            />
          )}
        />
        <Button variant="contained" color="primary" onClick={handleCreateCategory}>Create</Button>
      </div>
    </div>
  )
}

type CategoryListItemProps = {
  category: PromptCategory
  onEdit: (category: PromptCategory) => void
  onDelete: (category: PromptCategory) => void
}

function CategoryListItem({ category, onEdit, onDelete }: CategoryListItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: category.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '2' : '1',
    transition,
    position: isDragging ? 'relative' : 'static',
    cursor: isDragging ? 'grabbing' : 'grab'
  } as any

  const handleEdit = () => onEdit(category)
  const handleDelete = () => onDelete(category)

  return (
    <li key={category.id} ref={setNodeRef} style={style}>
      <span className={styles.cname} {...listeners} {...attributes}>{category.name}</span>
      <Button onClick={handleEdit} className={styles['edit-button']}>
        Edit
      </Button>
      <Button className={styles['btn-delete']} onClick={handleDelete}>
        Delete
      </Button>
    </li>
  )
}
