import * as React from 'react'
import { IQuote } from 'admin/interfaces'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './Quotes.pcss'
import { Subject } from 'rxjs/Subject'
import { EditableQuote } from './EditableQuote'
import { deleteQuote, updateQuote } from 'admin/services/quotes/actions'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import { getTags } from 'services/content/quotes/actions'

export function QuotesTable(props: { quotes: IQuote[] }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [loading, setLoading] = React.useState(false)
  const update$ = React.useRef<Subject<{ id: string, status: string, author?: string, tags?: string[] }>>()
  const delete$ = React.useRef<Subject<string>>()
  const [quotes, setQuotes] = React.useState(props.quotes)
  const [tags, setTags] = React.useState<string[]>([])

  React.useEffect(() => {
    dispatch(getTags(true)).subscribe((response) => {
      setTags(response.map((t: any) => t.name))
    }, (error) => {
      dispatch(message('Failed to fetch tags: ', error.message))
    })
  }, [])

  React.useEffect(() => {
    setQuotes(props.quotes)
  }, [props.quotes])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current.flatMap((id: string) => dispatch(deleteQuote(id)).pipe(
      catchError((e: Error) => {
        dispatch(message(`Error deleting object: ${e.message}`))
        return Observable.of({ error: true })
      })
    ))
      .subscribe((response) => {
        if (!response.error) {
          dispatch(message('Quote deleted!'))
          setQuotes(current => current.filter(q => q.id !== response.id))
        }
        setLoading(false)
      })

    return () => delete$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current.flatMap((params: { id: string, status: string, author?: string, tags?: string[] }) => {
      return dispatch(updateQuote(params.id, params.status, params.author, params.tags)).pipe(
        catchError((e: Error) => {
          dispatch(message(`Error updating object: ${e.message}`))
          return Observable.of({ error: true })
        })
      )
    })
      .subscribe((response) => {
        if (!response.error) {
          dispatch(message('Quote updated!'))
        }
        setLoading(false)
      })

    return () => update$.current?.unsubscribe()
  }, [])

  const onDelete = (id: string) => {
    setLoading(true)
    delete$.current?.next(id)
  }

  const onUpdate = (id: string, status: string, author?: string, newTags?: string[]) => {
    setLoading(true)
    update$.current?.next({ id, status, author, tags: newTags })
  }

  return (
    <div className={styles.wrapper}>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      {quotes.map(q => (
        <EditableQuote
          key={q.id}
          quote={q}
          savedTags={tags}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      ))}
    </div>
  )
}
