import { connect } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import AdminLogin from './AdminLogin'
import { adminLogin } from 'admin/services/actions'

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    login: (username: string, password: string) => dispatch(adminLogin(username, password))
  }
}

export default connect(null, mapDispatchToProps)(AdminLogin)
