import * as React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import AvatarUploader from 'components/AvatarUploader'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Fab from '@mui/material/Fab'
import SaveIcon from '@mui/icons-material/Check'
import UndoIcon from '@mui/icons-material/Undo'
import PreviewIcon from '@mui/icons-material/Visibility'
import Tooltip from '@mui/material/Tooltip'
import { IndexedObject, PlanLimitInfo } from 'interfaces'
import { LIMIT_MY_LIBRARY_STORAGE } from 'shared/constants'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { bytesToSize } from 'utils/format/byteToSize'
import { BYTES_IN_MB, BYTES_IN_GB } from 'utils/file'

import styles from '../FeaturesManager.pcss'
const BASE_TEN = 10

export interface LimitTableRowProps {
  limit: PlanLimitInfo
  plans: Record<string, string>
  onUpdate: (limit: PlanLimitInfo, file: File | null, order?: number) => void
  onFileUploadError: (error: string) => void
  onPreviewClick: (key: string, value: number) => void
}

export function LimitTableRow(props: LimitTableRowProps) {
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [data, setData] = React.useState<PlanLimitInfo>(props.limit)
  const [file, setFile] = React.useState<File | null>(null)
  const [edited, setEdited] = React.useState(false)
  const [limitsByPlan, setLimitsByPlan] = React.useState<IndexedObject<{ isUnlimited: boolean, value: number }>>(
    Object.keys(props.limit.plans).reduce((map: IndexedObject<{ isUnlimited: boolean, value: number }>, plan: string) => {
      map[plan] = {
        isUnlimited: props.limit.plans[plan] === -1,
        value: props.limit.plans[plan]
      }
      return map
    }, {})
  )
  const nameRef = React.useRef<HTMLDivElement>(null)
  const upgradeButtonTextRef = React.useRef<HTMLDivElement>(null)
  const dismissPopupTextRef = React.useRef<HTMLDivElement>(null)
  const salesPitchRef = React.useRef<HTMLDivElement>(null)
  const mobileCtaRef = React.useRef<HTMLDivElement>(null)
  const mobileSalesPitchRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setEdited(false)
    setData(props.limit)
  }, [props.limit])

  const onUnlimitedCheckboxChange = (plan: string) => () => {
    const currentLimit = limitsByPlan[plan]
    setEdited(true)
    setData(current => ({
      ...current,
      plans: {
        ...current.plans,
        [plan]: currentLimit.isUnlimited ? currentLimit.value : -1
      }
    }))
    setLimitsByPlan(current => ({
      ...current,
      [plan]: {
        isUnlimited: !current[plan].isUnlimited,
        value: current[plan].value
      }
    }))
  }

  const onStorageLimitChange = (plan: string) => (bytes: number) => {
    setEdited(true)
    setData(current => ({
      ...current,
      plans: {
        ...current.plans,
        [plan]: bytes
      }
    }))
  }

  const onLimitChange = (plan: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setEdited(true)
    const value = parseInt(e.target.value, BASE_TEN)
    setData(current => ({
      ...current,
      plans: {
        ...current.plans,
        [plan]: value
      }
    }))
    setLimitsByPlan(current => ({
      ...current,
      [plan]: {
        isUnlimited: false,
        value
      }
    }))
  }

  const renderLimitValue = (plan: string) => {
    switch (props.limit.key) {
      case LIMIT_MY_LIBRARY_STORAGE:
        return (
          <StorageSizeInput bytes={data.plans[plan] as number} onChange={onStorageLimitChange(plan)} />
        )
      default:
        const isUnlimited = limitsByPlan[plan].isUnlimited
        return (
          <React.Fragment>
            {!isUnlimited && (
              <input
                className={styles.input}
                type="number"
                value={data.plans[plan]}
                min={0}
                onChange={onLimitChange(plan)}
              />
            )}
            <FormControlLabel
              label="unlimited"
              classes={{ label: styles['cb-label'] }}
              control={(
                <Checkbox
                  checked={isUnlimited}
                  color="primary"
                  size="small"
                  onChange={onUnlimitedCheckboxChange(plan)}
                />
              )}
            />
          </React.Fragment>
        )
      // return (
      //   <input className={styles.input} type="number" min={0} value={data.plans[plan]} onChange={onLimitChange(plan)} />
      // )
    }
  }

  const undoChanges = () => {
    setEdited(false)
    setData(props.limit)
    setRefreshKey(current => current + 1)
  }

  const handleImageUploaded = (file: File) => {
    setFile(file)
    setEdited(true)
  }

  const handleInput = () => {
    setEdited(true)
  }

  const saveChanges = () => {
    const draft: PlanLimitInfo = {
      id: props.limit.id,
      key: props.limit.key,
      name: nameRef.current?.innerText.trim() as string,
      salesPitch: salesPitchRef.current?.innerText.trim() as string,
      upgradePopupDismissText: dismissPopupTextRef.current?.innerText.trim() as string,
      upgradeActionText: upgradeButtonTextRef.current?.innerText.trim() as string,
      plans: data.plans,
      image: '',
      isLimit: true,
      mobileCta: mobileCtaRef.current?.innerText.trim() as string,
      mobileSalesPitch: mobileSalesPitchRef.current?.innerText.trim() as string,
      visibility: data.visibility,
      order: props.limit.order // NOTE: Pass previous order to be used for reordering if needed
    }

    const updateOrder = data.order !== props.limit.order
    props.onUpdate(draft, file, updateOrder ? data.order : undefined)
  }

  const openUpgradeDialog = () => {
    const maxValue = data.plans.agency
    if (typeof maxValue === 'number') {
      props.onPreviewClick(props.limit.key, maxValue + 1)
    }
  }

  return (
    <TableRow className={styles.tr} key={refreshKey}>
      <TableCell className={styles['cell-image']}>
        <AvatarUploader
          imageUrl={props.limit.image}
          labelAdd="ADD IMAGE"
          labelChange="CHANGE IMAGE"
          className={styles.uploader}
          onFileUploaded={handleImageUploaded}
          onError={props.onFileUploadError}
        />
        <a href={props.limit.image} target="_blank" className={styles['link-img']}>see full size</a>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={nameRef}
          data-key="name"
          className={`${styles['cell-textarea']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.name}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={salesPitchRef}
          data-key="sales-pitch"
          className={`${styles['cell-textarea']} ${styles['cell-wide']}`}
          onInput={handleInput}
        >
          {data.salesPitch}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={mobileSalesPitchRef}
          data-key="mobile-sales-pitch"
          className={`${styles['cell-textarea']} ${styles['cell-wide']}`}
          onInput={handleInput}
        >
          {data.mobileSalesPitch}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={mobileCtaRef}
          data-key="mobile-cta"
          className={styles['cell-textarea']}
          onInput={handleInput}
        >
          {data.mobileCta}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={dismissPopupTextRef}
          data-key="dismiss-popup-text"
          className={`${styles['cell-textarea']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.upgradePopupDismissText}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={upgradeButtonTextRef}
          data-key="upgrade-action-text"
          className={styles['cell-textarea']}
          onInput={handleInput}
        >
          {data.upgradeActionText}
        </div>
      </TableCell>
      {Object.keys(props.plans).map(id => (
        <TableCell className={styles['td-number']} key={id}>
          {renderLimitValue(id)}
        </TableCell>
      ))}
      <TableCell className={styles['cell-actions']}>
        <Tooltip title="Save" placement="top">
          <span>
            <Fab size="small" color="primary" disabled={!edited} onClick={saveChanges}>
              <SaveIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title="Undo" placement="top">
          <span>
            <Fab size="small" disabled={!edited} onClick={undoChanges}>
              <UndoIcon fontSize="small" />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title="Undo" placement="top">
          <span>
            <Fab size="small" disabled={!edited} onClick={undoChanges}>
              <UndoIcon fontSize="small" />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title="Open upgrade dialog" placement="top">
          <span>
            <Fab size="small" color="primary" onClick={openUpgradeDialog}>
              <PreviewIcon fontSize="small" />
            </Fab>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default LimitTableRow

function StorageSizeInput(props: { bytes: number, onChange: (size: number) => void }) {
  const sizeFormatted = bytesToSize(props.bytes)
  const value = parseInt(sizeFormatted.split(' ')[0], BASE_TEN)
  let suffix = sizeFormatted.split(' ')[1].toUpperCase()
  if (suffix !== 'MB' && suffix !== 'GB') {
    // Force MB/GB suffix
    suffix = 'MB'
  }

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, BASE_TEN)

    if (suffix === 'MB') {
      props.onChange(newValue * BYTES_IN_MB)
    }

    if (suffix === 'GB') {
      props.onChange(newValue * BYTES_IN_GB)
    }
  }

  const onSuffixChange = (e: SelectChangeEvent) => {
    const newSuffix = e.target.value

    if (newSuffix === 'MB') {
      props.onChange(value * BYTES_IN_MB)
    }

    if (newSuffix === 'GB') {
      props.onChange(value * BYTES_IN_GB)
    }
  }

  return (
    <div className={styles['storage-box']}>
      <input type="number" value={value} className={styles.input} min={0} onChange={onNumberChange} />
      <Select value={suffix} onChange={onSuffixChange} classes={{ select: styles['select-input'] }}>
        <MenuItem value="MB">MB</MenuItem>
        <MenuItem value="GB">GB</MenuItem>
      </Select>
    </div>
  )
}
