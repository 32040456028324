import * as React from 'react'

import styles from './AdminPage.pcss'

export function AdminPage(props: { title?: string, children: any, subtitle?: string }) {
  const subtitle = props.subtitle ? <h2 className={styles.subtitle}>{props.subtitle}</h2> : null

  return (
    <section className={styles.page}>
      <header>
        {props.title && <h1 className={styles.title}>{props.title}</h1>}
        {subtitle}
      </header>
      <div className={styles.content}>
        {props.children}
      </div>
    </section>
  )
}

export default AdminPage
