import * as React from 'react'
import { connect } from 'react-redux'
import StoreState, { StoreThunkDispatch } from 'store/state'
import Impersonate from './Impersonate'
import { impersonationSelector } from 'admin/services/selectors'
import { searchUsers, impersonateUser, stopImpersonateUser } from 'admin/services/impersonate/actions'
import { Observable } from 'rxjs/Observable'
import { clearFBTokens, toggleUserBlocked, getIntercomData, resetPassword, clearUserData, getUserData } from 'admin/services/users/actions'
import { message } from 'services/snackbar'

interface ConnectedImpersonateProps {
  current: any,
  searchUsers: (query: string) => Observable<any>
  impersonate: (user: any) => any
  stopImpersonation: () => void
  clearFBTokens: (id: string) => Observable<any>
  toggleUserBlocked: (id: string) => Observable<any>
  getIntercomData: (id: string) => Observable<any>
  message: (text: string) => void
  resetPassword: (userId: string) => Observable<any>
  clearUserData: (userId: string) => Observable<any>
  getUserData: (userId: string) => Observable<any>
}

export function ImpersonateContainer(props: ConnectedImpersonateProps) {
  const [searchUsersResult, setSearchUsersResult] = React.useState([])
  const [searchError, setSearchError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const searchUsers = (query: string) => {
    setLoading(true)
    setSearchError('')
    return props.searchUsers(query)
      .subscribe(
        (users) => {
          setSearchUsersResult(users)
          setSearchError('')
          setLoading(false)
        },
        (error) => {
          setSearchError('There was a problem with your request. Please try again. \n' + error)
          setLoading(false)
        }
      )
  }

  const onClearSearch = () => {
    setSearchUsersResult([])
    setSearchError('')
  }

  return React.createElement(Impersonate, {
    current: props.current,
    users: searchUsersResult,
    searchError,
    loading,
    onSearch: searchUsers,
    onImpersonate: props.impersonate,
    onStopImpersonation: props.stopImpersonation,
    onClearSearch,
    onClearFBTokens: props.clearFBTokens,
    toggleUserBlocked: props.toggleUserBlocked,
    getIntercomData: props.getIntercomData,
    message: props.message,
    resetPassword: props.resetPassword,
    clearUserData: props.clearUserData,
    getUserData: props.getUserData
  })
}

function mapStateToProps(state: StoreState) {
  return {
    current: impersonationSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    searchUsers: (query: string) => dispatch(searchUsers(query)),
    impersonate: (user: any) => dispatch(impersonateUser(user)),
    stopImpersonation: () => dispatch(stopImpersonateUser()),
    clearFBTokens: (id: string) => dispatch(clearFBTokens(id)),
    toggleUserBlocked: (id: string) => dispatch(toggleUserBlocked(id)),
    getIntercomData: (id: string) => dispatch(getIntercomData(id)),
    message: (text: string) => dispatch(message(text)),
    resetPassword: (userId: string) => dispatch(resetPassword(userId)),
    clearUserData: (userId: string) => dispatch(clearUserData(userId)),
    getUserData: (userId: string) => dispatch(getUserData(userId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateContainer)
