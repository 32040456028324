import * as React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Fab from '@mui/material/Fab'
import SaveIcon from '@mui/icons-material/Check'
import UndoIcon from '@mui/icons-material/Undo'
import PreviewIcon from '@mui/icons-material/Visibility'
import Tooltip from '@mui/material/Tooltip'
import AvatarUploader from 'components/AvatarUploader'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { FeatureInfo } from 'interfaces'

import styles from '../FeaturesManager.pcss'

export interface FeatureTableRowProps {
  feature: FeatureInfo
  plans: Record<string, string>
  onUpdate: (feature: FeatureInfo, file: File | null, order?: number) => void
  onFileUploadError: (error: string) => void
  onPreviewClick: (key: string) => void
}

export function FeatureTableRow(props: FeatureTableRowProps) {
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [data, setData] = React.useState<FeatureInfo>(props.feature)
  const [file, setFile] = React.useState<File | null>(null)
  const [edited, setEdited] = React.useState(false)
  const nameRef = React.useRef<HTMLDivElement>(null)
  const upgradeButtonTextRef = React.useRef<HTMLDivElement>(null)
  const dismissPopupTextRef = React.useRef<HTMLDivElement>(null)
  const salesPitchRef = React.useRef<HTMLDivElement>(null)
  const mobileCtaRef = React.useRef<HTMLDivElement>(null)
  const mobileSalesPitchRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setEdited(false)
    setData(props.feature)
  }, [props.feature])

  const undoChanges = () => {
    setEdited(false)
    setData(props.feature)
    setRefreshKey(current => current + 1)
  }

  const handleImageUploaded = (file: File) => {
    setEdited(true)
    setFile(file)
  }

  const handlePlanChange = (e: SelectChangeEvent) => {
    setEdited(true)
    const value = e.target.value
    setData(current => ({ ...current, plan: value }))
  }

  const handleInput = () => {
    setEdited(true)
  }

  const saveChanges = () => {
    const draft: FeatureInfo = {
      id: props.feature.id,
      key: props.feature.key,
      name: nameRef.current?.innerText.trim() as string,
      upgradeActionText: upgradeButtonTextRef.current?.innerText.trim() as string,
      salesPitch: salesPitchRef.current?.innerText.trim() as string,
      upgradePopupDismissText: dismissPopupTextRef.current?.innerText.trim() as string,
      plan: data.plan,
      isFeature: true,
      image: '',
      mobileCta: mobileCtaRef.current?.innerText.trim() as string,
      mobileSalesPitch: mobileSalesPitchRef.current?.innerText.trim() as string,
      visibility: data.visibility,
      order: props.feature.order // NOTE: Pass previous order to be used for reordering if needed
    }

    const updateOrder = data.order !== props.feature.order
    props.onUpdate(draft, file, updateOrder ? data.order : undefined)
  }

  const openUpgradeDialog = () => {
    props.onPreviewClick(props.feature.key)
  }

  return (
    <TableRow className={styles.tr} key={refreshKey}>
      <TableCell className={styles['cell-image']}>
        <AvatarUploader
          imageUrl={props.feature.image}
          labelAdd="ADD IMAGE"
          labelChange="CHANGE IMAGE"
          className={styles.uploader}
          onFileUploaded={handleImageUploaded}
          onError={props.onFileUploadError}
        />
        <a href={props.feature.image} target="_blank" className={styles['link-img']}>see full size</a>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={nameRef}
          data-key="name"
          className={`${styles['cell-textarea']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.name}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={salesPitchRef}
          data-key="sales-pitch"
          className={`${styles['cell-textarea']} ${styles['cell-wide']}`}
          onInput={handleInput}
        >
          {data.salesPitch}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={mobileSalesPitchRef}
          data-key="mobile-sales-pitch"
          className={`${styles['cell-textarea']} ${styles['cell-wide']}`}
          onInput={handleInput}
        >
          {data.mobileSalesPitch}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={mobileCtaRef}
          data-key="mobile-cta"
          className={styles['cell-textarea']}
          onInput={handleInput}
        >
          {data.mobileCta}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={dismissPopupTextRef}
          data-key="dismiss-popup-text"
          className={`${styles['cell-textarea']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.upgradePopupDismissText}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={upgradeButtonTextRef}
          data-key="upgrade-action-text"
          className={styles['cell-textarea']}
          onInput={handleInput}
        >
          {data.upgradeActionText}
        </div>
      </TableCell>
      <TableCell>
        <Select
          value={data.plan}
          inputProps={{ 'data-key': 'plan' }}
          classes={{ select: styles['select-input'] }}
          onChange={handlePlanChange}
        >
          {
            Object.keys(props.plans).map(planId => (
              <MenuItem value={planId} key={planId}>{props.plans[planId]}</MenuItem>
            ))
          }
        </Select>
      </TableCell>
      <TableCell className={styles['cell-actions']}>
        <Tooltip title="Save" placement="top">
          <span>
            <Fab size="small" color="primary" disabled={!edited} onClick={saveChanges}>
              <SaveIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title="Undo" placement="top">
          <span>
            <Fab size="small" disabled={!edited} onClick={undoChanges}>
              <UndoIcon fontSize="small" />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title="Open upgrade dialog" placement="top">
          <span>
            <Fab size="small" color="primary" onClick={openUpgradeDialog}>
              <PreviewIcon fontSize="small" />
            </Fab>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default FeatureTableRow
