import React, { useEffect, useState } from 'react'
import styles from './SectionList.pcss'
import Button from '@mui/material/Button'
import { DndContext, useDroppable, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import { reorder } from 'shared/utils'
import { VideoTrainingSection } from 'admin/interfaces'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'

interface SectionListProps {
  sections: VideoTrainingSection[]
  onEditSection: (section: VideoTrainingSection) => void
  onDeleteSection: (section: VideoTrainingSection) => void
  onCreateSection: (name: string, slug: string, label: string) => void
  onReorder: (ids: number[]) => void
}

export const SectionList: React.FC<SectionListProps> = ({
  sections,
  onEditSection,
  onDeleteSection,
  onCreateSection,
  onReorder
}) => {
  const dispatch = useDispatch()
  const [newSection, setNewSection] = useState({ name: '', slug: '', label: '' })
  const [order, setOrder] = useState(sections.map(c => c.id))
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: 'droppable'
  })

  useEffect(() => {
    setOrder(sections.map(c => c.id))
  }, [sections])

  const handleCreate = () => {
    const name = newSection.name.trim()
    const slug = newSection.slug.trim()
    const label = newSection.label.trim()

    if (name && slug && label) {
      onCreateSection(name, slug, label)
      setNewSection({ name: '', slug: '', label: '' })
    } else {
      dispatch(message('Please fill all fields', 'warning'))
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCreate()
    }
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      onReorder(reordered.map(id => parseInt(id, 10)))
    }
  }

  return (
    <div className={styles['category-list-container']}>
      <h3 className={styles.subtitle}>Sections</h3>
      <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]} layoutMeasuring={{ strategy: MeasuringStrategy.Always }}>
        <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
          <ul ref={setDroppableNodeRef}>
            {sections
              .sort((c1, c2) => order.indexOf(c1.id) - order.indexOf(c2.id))
              .map((section) => (
                <SectionListItem
                  key={section.id}
                  section={section}
                  onEdit={onEditSection}
                  onDelete={onDeleteSection}
                />
              ))}
          </ul>

        </SortableContext>
      </DndContext>
      <h3 className={styles.subtitle}>Add New Section</h3>
      <div className={styles['create-category']}>
        <input
          type="text"
          placeholder="New Section Name..."
          value={newSection.name}
          onChange={(e) => setNewSection({ ...newSection, name: e.target.value })}
          onKeyDown={onKeyDown}
        />

        <input
          type="text"
          placeholder="Section sidebar label"
          value={newSection.label}
          onChange={(e) => setNewSection({ ...newSection, label: e.target.value })}
          onKeyDown={onKeyDown}
        />

        <input
          type="text"
          placeholder="Section slug"
          value={newSection.slug}
          onChange={(e) => setNewSection({ ...newSection, slug: e.target.value })}
          onKeyDown={onKeyDown}
        />
        <Button variant="contained" color="primary" onClick={handleCreate}>Create</Button>
      </div>
    </div>
  )
}

type SectionListItemProps = {
  section: VideoTrainingSection
  onEdit: (s: VideoTrainingSection) => void
  onDelete: (s: VideoTrainingSection) => void
}

function SectionListItem({ section, onEdit, onDelete }: SectionListItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: section.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '2' : '1',
    transition,
    position: isDragging ? 'relative' : 'static',
    cursor: isDragging ? 'grabbing' : 'grab'
  } as any

  const handleEdit = () => onEdit(section)
  const handleDelete = () => onDelete(section)

  return (
    <li key={section.id} ref={setNodeRef} style={style}>
      <span className={styles.cname} {...listeners} {...attributes}>{section.title}</span>
      <Button onClick={handleEdit} className={styles['edit-button']}>
        Edit
      </Button>
      <Button className={styles['btn-delete']} onClick={handleDelete}>
        Delete
      </Button>
    </li>
  )
}
