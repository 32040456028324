import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import { useDispatch } from 'react-redux'

import styles from './InfoBoxesRoute.pcss'
import { StoreThunkDispatch } from 'store/state'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { Infobox } from 'interfaces'
import InfoboxTableRow from './components/InfoboxTableRow'
import { updateConfig } from 'admin/services/infoboxes/net'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

export function InfoBoxesRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [infoboxes, setInfoboxes] = React.useState<Infobox[]>([])
  const [version, setVersion] = React.useState<number>(1)
  const [fetchKey, setFetchKey] = React.useState(0)

  React.useEffect(() => {
    const configUrl = `https://staticpp.postplanner.com/config/callouts.json?v=${Date.now()}`
    fetch(configUrl)
      .then(response => response.json())
      .then(res => {
        setError(false)
        setInfoboxes(res.callouts)
        setVersion(res.version)
      }).catch(() => {
        dispatch(message('Error fetching config. Please try again.'))
        setError(true)
      })
  }, [dispatch, fetchKey])

  const updateInfoboxes = (updatedInfoboxes: Infobox[], v: number) => {
    dispatch(updateConfig(updatedInfoboxes, v)).pipe(
      catchError(() => {
        return Observable.of({ error: true })
      })
    )
      .subscribe((response: any) => {
        if (response.error) {
          dispatch(message('Update failed!'))
        } else {
          setFetchKey(current => current + 1)
          dispatch(message('Config updated!'))
        }
        setLoading(false)
      })
  }

  const addInfobox = (addedInfobox: Infobox) => {
    setLoading(true)
    delete addedInfobox.new
    updateInfoboxes([...infoboxes.filter(i => !i.new), addedInfobox], version)
  }

  const updateInfobox = (updatedInfobox: Infobox, oldSlug?: string) => {
    setLoading(true)
    const findBySlug = oldSlug || updatedInfobox.slug
    const ibIndexToUpdate = infoboxes.findIndex(ib => ib.slug === findBySlug)
    infoboxes[ibIndexToUpdate] = updatedInfobox
    updateInfoboxes(infoboxes, version)
  }

  const deleteInfobox = (slug: string) => {
    setLoading(true)
    const remainingInfoboxes = infoboxes.filter(i => i.slug !== slug)
    setInfoboxes(remainingInfoboxes)
    updateInfoboxes(remainingInfoboxes, version)
  }

  const updateVersion = () => {
    setLoading(true)
    updateInfoboxes(infoboxes, version + 1)
  }

  const addNewRow = () => {
    setInfoboxes([...infoboxes, {
      slug: '',
      title: '',
      linkText: '',
      linkText2: '',
      linkUrl: '',
      linkUrl2: '',
      linkText3: '',
      linkUrl3: '',
      linkIcon: 'mdiHelpNetwork',
      linkIcon2: 'mdiHelpNetwork',
      linkIcon3: 'mdiHelpNetwork',
      paragraph: '',
      new: true,
      exact: false
    }])
  }

  return (
    <AdminPage title="Info boxes config">
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      {!error && !infoboxes.length && (
        <div>Loading...</div>
      )}
      {error && (
        <div>An error occurred. Please refresh.</div>
      )}
      <div>
        <Button onClick={updateVersion}>Update version</Button>
      </div>
      <Paper className={styles.paper}>
        <TableContainer className={styles['table-container']}>

          <Table className={styles.table} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Page slug</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Paragraph</TableCell>
                <TableCell>Texts</TableCell>
                <TableCell>Links</TableCell>
                <TableCell>Icons</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {infoboxes.map((infobox, index) => (
                <InfoboxTableRow infobox={infobox} key={index} onAdd={addInfobox} onUpdate={updateInfobox} onDelete={deleteInfobox} />
              ))}
              <TableRow className={styles.tr}>
                <TableCell>
                  <Button className={styles.add_button} onClick={addNewRow}>Add new</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </AdminPage>
  )
}

export default InfoBoxesRoute
