import * as React from 'react'
import { connect } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { searchTeamMembers, switchChargifyAccounts } from 'admin/services/team/actions'
import TeamMembers from './Team'
import useAsyncAction from 'hooks/useAsyncAction'
import { message } from 'services/snackbar'

export function TeamContainer(props: any) {
  const [triggerSearch, searchResponse, searchError, searchLoading] = useAsyncAction(props.search)

  return (
    <TeamMembers
      loading={searchLoading}
      data={searchResponse}
      error={searchError}
      message={props.message}
      onSearch={triggerSearch}
      onSwitchChargifyAccounts={props.switchChargifyAccounts}
    />
  )
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    search: (emails?: string[], userIds?: string[], appIds?: string[]) => dispatch(searchTeamMembers(emails, userIds, appIds)),
    switchChargifyAccounts: (userIds: string[]) => dispatch(switchChargifyAccounts(userIds)),
    message: (text: string) => dispatch(message(text))
  }
}

export default connect(null, mapDispatchToProps)(TeamContainer)
