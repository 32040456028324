import * as React from 'react'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import styles from './Statuses.pcss'
import { StoreThunkDispatch } from 'store/state'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import { createStatusIdea } from 'services/content/statuses/actions'

export function CreateStatusIdeaPanel(props: { onCreated: () => void }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const textRef = React.useRef<HTMLTextAreaElement>(null)
  const tagsRef = React.useRef<HTMLInputElement>(null)

  const create$ = React.useRef<Subject<{ status: string, tags?: string[] }>>()

  React.useEffect(() => {
    create$.current = new Subject()
    create$.current.flatMap((params: { status: string, tags?: string[] }) => {
      return dispatch(createStatusIdea(params.status, params.tags)).pipe(
        catchError((e: Error) => {
          dispatch(message(`An error occurred: ${e.message}`))
          return Observable.of({ error: true })
        })
      )
    })
      .subscribe((response: any) => {
        if (!response.error) {
          dispatch(message('Status added!'))
          props.onCreated()
        }
      })

    return () => create$.current?.unsubscribe()
  }, [])

  const create = () => {
    const status = textRef.current?.value.trim()
    const tags = tagsRef.current?.value.split(',').map(value => value.trim()).filter(Boolean)

    if (status) {
      create$.current?.next({ status, tags })
      ; (textRef.current as any).value = ''
      ; (tagsRef.current as any).value = ''
    }
  }
  return (
    <section className={styles['box-create']}>
      <h3 className={styles['add-title']}>Add new</h3>
      <textarea className={styles.textarea} ref={textRef} placeholder="Enter text" rows={1} />
      <div className={styles['box-create-footer']}>
        <input type="text" placeholder="Tags (comma-separated)" ref={tagsRef} />
        <Button color="primary" variant="contained" size="small" onClick={create}>
          <FormattedMessage id="actions.save" />
        </Button>
      </div>
    </section>
  )
}
