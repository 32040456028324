import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import { HomeContentBlock as IHomeContentBlock } from 'admin/interfaces'
import { useDispatch } from 'react-redux'
import {
  createHomeBlock,
  deleteHomeBlock,
  getHomeContentBlocks,
  reorderHomeBlocks,
  updateHomeBlock
} from 'admin/services/home-config/actions'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { HomeContentBlock, HomeContentBlockTableHeader } from './components/HomeContentBlock'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import Input from '@mui/material/TextField'
import Button from '@mui/material/Button'
import styles from './HomeConfig.pcss'
import { Subject } from 'rxjs/Subject'
import { reorder } from 'shared/utils'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { Observable } from 'rxjs/Observable'

export function HomeConfig() {
  const [blocks, setBlocks] = React.useState<IHomeContentBlock[]>([])
  const [loading, setLoading] = React.useState(true)
  const [newBlock, setNewBlock] = React.useState({ name: '', key: '' })
  const dispatch = useDispatch<StoreThunkDispatch>()

  const fetch$ = React.useRef<Subject<void>>()
  const reorder$ = React.useRef<Subject<string[]>>()
  const update$ = React.useRef<Subject<IHomeContentBlock>>()
  const delete$ = React.useRef<Subject<string>>()
  const create$ = React.useRef<Subject<{ name: string, key: string, order: number }>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .flatMap(() => {
        setLoading(true)
        return dispatch(getHomeContentBlocks()).pipe(catchError(() => {
          dispatch(message('Could not fetch data! Please refresh.'))
          return Observable.of([])
        }))
      })
      .subscribe(response => {
        if (response.length > 0) {
          setBlocks(response)
        }
        setLoading(false)
      })

    fetch$.current.next()
  }, [])

  React.useEffect(() => {
    reorder$.current = new Subject()
    update$.current = new Subject()
    delete$.current = new Subject()
    create$.current = new Subject()

    create$.current
      .flatMap(({ name, key, order }) => dispatch(createHomeBlock(name, key, order)).pipe(
        tap(() => {
          dispatch(message('Block created!'))
        }),
        catchError(() => {
          dispatch(message('Action failed!'))
          return Observable.of({})
        })
      ))
      .subscribe(() => {
        fetch$.current?.next()
      })

    reorder$.current
      .flatMap((ids: string[]) => {
        return dispatch(reorderHomeBlocks(ids)).pipe(
          tap(() => {
            dispatch(message('Reorder successful!'))
          }),
          catchError(() => {
            dispatch(message('Reorder failed!'))
            return Observable.of({})
          })
        )
      })
      .subscribe(() => {
        fetch$.current?.next()
      })

    update$.current
      .flatMap((data) => {
        return dispatch(updateHomeBlock(data)).pipe(
          tap(() => {
            dispatch(message('Update successful!'))
          }),
          catchError(() => {
            dispatch(message('Update failed!'))
            return Observable.of({})
          })
        )
      })
      .subscribe(() => {
        fetch$.current?.next()
      })

    delete$.current
      .flatMap((id) => {
        return dispatch(deleteHomeBlock(id)).pipe(
          tap(() => {
            dispatch(message('Delete successful!'))
          }),
          catchError(() => {
            dispatch(message('Delete failed!'))
            return Observable.of({})
          })
        )
      })
      .subscribe(() => {
        fetch$.current?.next()
      })
  }, [])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const reordered = reorder<IHomeContentBlock>(blocks, result.source.index, result.destination.index)
    setBlocks(reordered)
    setLoading(true)
    reorder$.current?.next(reordered.map(b => b.id))
  }

  const onBlockUpdate = (block: IHomeContentBlock) => {
    setLoading(true)
    update$.current?.next(block)
  }

  const onDeleteBlock = (id: string) => {
    setLoading(true)
    delete$.current?.next(id)
  }

  const createBlock = () => {
    const name = newBlock.name.trim()
    const key = newBlock.key.trim()
    if (name.length > 0 && key.length > 0) {
      setLoading(true)
      const order = blocks.length
      create$.current?.next({ ...newBlock, order })
      setNewBlock({ name: '', key: '' })
    } else {
      alert('Please enter name and key')
    }
  }

  const onNewBlockChange = (prop: 'name' | 'key') => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setNewBlock(current => ({ ...current, [prop]: value }))
  }

  return (
    <AdminPage title="Home Carousels">
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <HomeContentBlockTableHeader />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {blocks.map((block, index) => (
                <Draggable draggableId={block.id} key={block.id} index={index}>
                  {(provided) => (
                    <HomeContentBlock
                      block={block}
                      innerRef={provided.innerRef}
                      dragHandleProps={provided.dragHandleProps}
                      draggableProps={provided.draggableProps}
                      onUpdate={onBlockUpdate}
                      onDelete={onDeleteBlock}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={styles.footer}>
        <h3>Create new carousel</h3>
        <Input value={newBlock.name} placeholder="Carousel Name" onChange={onNewBlockChange('name')} />
        <Input value={newBlock.key} placeholder="NEW_CAROUSEL_KEY" onChange={onNewBlockChange('key')} />
        <Button variant="contained" color="primary" size="small" onClick={createBlock}>Create</Button>
      </div>
    </AdminPage>
  )
}

export default HomeConfig
