import { V1 } from './net'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { FeatureInfo, PlanLimitInfo } from 'interfaces'
import { tap } from 'rxjs/operators/tap'
import { Observable } from 'rxjs/Observable'
import toSnakeCase from 'utils/toSnakeCase'
const snakeCase = require('snake-case')
const BASE_TEN = 10

export function getFeatures() {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getFeatures())
      .map(feats => feats.map((f: any) => ({
        ...f,
        id: f.key,
        plan: f.productId,
        isFeature: true,
        order: parseInt(f.order, BASE_TEN),
        visibility: f.visibility ? f.visibility.split(',').map((p: string) => p.trim()) : [],
        salesPitch: f.salesPitch || '',
        upgradePopupDismissText: f.popupDismissText || '',
        upgradeActionText: f.upgradeActionText || '',
        mobileCta: f.mobileCta || '',
        mobileSalesPitch: f.mobileSalesPitch || ''
      })))
  }
}

export function getLimits(): StoreThunkAction<Observable<PlanLimitInfo[]>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getLimits()).map((response: any) => {
      const { details, limits } = response
      const planLimitsByKey: Record<string, Record<string, number>> = {}
      limits.forEach((data: any) => {
        const planId = data.productId
        Object.keys(data.limits).forEach(limitKey => {
          const keyFormatted = snakeCase(limitKey).toUpperCase()
          if (planLimitsByKey[keyFormatted]) {
            planLimitsByKey[keyFormatted][planId] = data.limits[limitKey]
          } else {
            planLimitsByKey[keyFormatted] = { [planId]: data.limits[limitKey] }
          }
        })
      })

      return details.map((limitInfo: any) => {
        return {
          id: limitInfo.key.toString(),
          name: limitInfo.name,
          jtbd: limitInfo.jtbd,
          image: limitInfo.image,
          order: parseInt(limitInfo.order, BASE_TEN),
          key: limitInfo.key,
          isLimit: true,
          description: limitInfo.description,
          salesPitch: limitInfo.salesPitch || '',
          mobileCta: limitInfo.mobileCta || '',
          mobileSalesPitch: limitInfo.mobileSalesPitch || '',
          upgradePopupDismissText: limitInfo.popupDismissText || '',
          upgradeActionText: limitInfo.upgradeActionText || '',
          plans: planLimitsByKey[limitInfo.key],
          visibility: limitInfo.visibility ? limitInfo.visibility.split(',').map((p: string) => p.trim()) : []
        }
      })
    })
  }
}

export function updateFeature(feature: FeatureInfo, file?: File) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.updateFeature(feature, file)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}

export function updateLimit(limit: PlanLimitInfo, file?: File) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.updateLimit(limit, file)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}

export function reorderFeatures(order: string[]) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.reorderFeatures(order)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}

export function reorderLimits(order: string[]) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.reorderLimits(order)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}
