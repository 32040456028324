import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import IconAdd from '@mui/icons-material/Add'
import IconDelete from '@mui/icons-material/Close'
import styles from './CheckoutUIRoute.pcss'
import { PPSwitch } from 'components/PPSwitch'
import Button from '@mui/material/Button'

export function FAQEditor(props: {
  faqs: Record<string, { id: string, q: string, a: string, isTrial: boolean }>
  onAddOrUpdateItem: ({ id, q, a, isTrial }: { id: string, q?: string, a?: string, isTrial?: boolean }) => void,
  onRemoveItem: (id: string) => void
}) {
  const [newItem, setNewItem] = React.useState({ id: Date.now().toString(), q: '', a: '', isTrial: false })

  const onUpdateNewItem = (faq: { id: string, q?: string, a?: string, isTrial?: boolean }) => {
    setNewItem({
      id: faq.id,
      q: typeof faq.q === 'undefined' ? newItem.q : faq.q,
      a: typeof faq.a === 'undefined' ? newItem.a : faq.a,
      isTrial: typeof faq.isTrial === 'undefined' ? newItem.isTrial : faq.isTrial
    })
  }

  const onAddNew = () => {
    props.onAddOrUpdateItem(newItem)
    setNewItem({ id: Date.now().toString(), q: '', a: '', isTrial: false })
  }

  return (
    <div className={`${styles['css-table']} ${styles['faq-table']}`}>
      <h5>FAQs</h5>
      {Object.keys(props.faqs).map(id => {
        const faq = props.faqs[id]
        return (
          <ListItem faq={faq} key={faq.id} onUpdate={props.onAddOrUpdateItem} onRemove={props.onRemoveItem} />
        )
      })}
      <div className={styles['css-row']}>
        <ListItem faq={newItem} onUpdate={onUpdateNewItem} onAdd={onAddNew} />
      </div>
    </div>
  )
}

type ListItemProps = {
  faq: { id: string, q: string, a: string, isTrial: boolean }
  onUpdate: (faq: { id: string, q?: string, a?: string, isTrial?: boolean }) => void
  onRemove?: (id: string) => void
  onAdd?: () => void
}
function ListItem({ faq, onUpdate, onRemove, onAdd }: ListItemProps) {
  const { id, q, a, isTrial } = faq

  const onQChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onUpdate({ id, q: value })
  }

  const onAChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onUpdate({ id, a: value })
  }

  const remove = () => {
    onRemove && onRemove(id)
  }

  const onToggleTrial = (value: string) => {
    onUpdate({ id, isTrial: value === 'on' })
  }

  return (
    <div className={styles['css-row']}>
      <input
        type="text"
        value={q}
        placeholder="Question"
        onChange={onQChange}
      />
      <input
        type="text"
        value={a}
        placeholder="Answer"
        onChange={onAChange}
      />
      <div style={{ margin: '0 24px' }}>
        <span style={{ marginRight: '8px' }}>Visibility:</span>
        <PPSwitch
          selectedValue={isTrial ? 'on' : 'off'}
          options={[{ value: 'on', label: 'Trial' }, { value: 'off', label: 'Non-trial' }]}
          onSelectedValueChange={onToggleTrial}
        />
      </div>
      {onAdd && (
        <Button size="small" startIcon={<IconAdd />} onClick={onAdd}>
          Add
        </Button>
      )}
      {onRemove && (
        <IconButton size="small" onClick={remove}>
          <IconDelete />
        </IconButton>
      )}
    </div>
  )
}
