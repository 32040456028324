import React from 'react'
import { IndexedObject, PPProduct } from 'interfaces'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { getPlans } from 'services/plans/actions'
import { message } from 'services/snackbar'
import { Subject } from 'rxjs/Subject'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AdminPage from 'admin/components/AdminPage'
import PPSwitch from 'components/PPSwitch'
import styles from './PlansRoute.pcss'
import { CURRENCY_SIGN_MAP } from 'shared/constants'

export function PlansRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [plans, setPlans] = React.useState<PPProduct[]>([])
  const [currency, setCurrency] = React.useState<string>('usd')

  const currencies = [{
    value: 'usd',
    label: 'USD'
  }, {
    value: 'eur',
    label: 'EUR'
  }, {
    value: 'gbp',
    label: 'GBP'
  }, {
    value: 'inr',
    label: 'INR'
  }]

  const fetch$ = React.useRef<Subject<void>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current.flatMap(() => dispatch(getPlans()))
      .subscribe((data) => {
        setPlans(data)
      }, () => {
        dispatch(message('Error fetching plans!'))
      })

    fetch$.current.next()

    return () => {
      fetch$.current?.unsubscribe()
    }
  }, [dispatch])

  return (
    <AdminPage title="Subscription Plans">
      <div className={styles.heading}>
        <span className={styles.label}>Currency:</span>
        <PPSwitch
          selectedValue={currency}
          options={currencies}
          onSelectedValueChange={setCurrency as any}
        />
      </div>
      <TableContainer component={Paper} sx={{ marginBottom: '24px' }}>
        <Table sx={{ minWidth: 850 }} aria-label="plans table">
          <TableHead>
            <TableRow className={styles.thead}>
              <TableCell>Name</TableCell>
              <TableCell align="center">Handle</TableCell>
              <TableCell align="center">Price (monthly)</TableCell>
              <TableCell align="center">Price (annual)</TableCell>
              <TableCell align="center">Price (lifetime)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((plan) => (
              <PlanTableRow
                key={plan.id}
                plan={plan}
                visibleCurrency={currency}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AdminPage>
  )
}

interface PlanTableRowProps {
  plan: PPProduct
  visibleCurrency: string
}

const PlanTableRow = ({ plan, visibleCurrency }: PlanTableRowProps) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {plan.name}
      </TableCell>
      <TableCell align="center">{plan.handles.monthly}</TableCell>
      <TableCell align="center">{CURRENCY_SIGN_MAP[visibleCurrency]}{plan.price.monthly[visibleCurrency]}</TableCell>
      <TableCell align="center">{CURRENCY_SIGN_MAP[visibleCurrency]}{plan.price.annual[visibleCurrency]}</TableCell>
      <TableCell align="center">
        {plan.price.lifetime ? CURRENCY_SIGN_MAP[visibleCurrency] + plan.price.lifetime[visibleCurrency] : 'not available'}
      </TableCell>
    </TableRow>
  )
}

export default PlansRoute
