import { ADMIN_SERVICE_URL } from 'config'
import { RequestBuilder, authorizedPipe } from 'services/net'

const ENDPOINT_UPDATE_CONFIG = `${ADMIN_SERVICE_URL}/configs/update_ui_config`

export function updateConfig(config: any, prod: boolean) {
  const builder = new RequestBuilder(ENDPOINT_UPDATE_CONFIG).asPost().expectJSON().asFormUrlEncoded()
  return authorizedPipe<any>(
    builder
      .body({ name: 'ui.config', config: JSON.stringify(config), prod })
      .build()
  )
}
