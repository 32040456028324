import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import Table from '@mui/material/Table'
import CircularProgress from '@mui/material/CircularProgress'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog'
import WarningIcon from '@mui/icons-material/Warning'
import { Observable } from 'rxjs/Observable'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import styles from './Impersonate.pcss'
import IconButton from '@mui/material/IconButton'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { restartTrial } from 'admin/services/users/actions'
const PAGE_TITLE = 'Users'
const LOADER_SIZE = 20

export interface ImpersonateProps {
  current: any
  users: any[]
  loading: boolean
  searchError?: string

  onStopImpersonation: () => void
  onImpersonate: (user: any) => void
  onSearch: (query: string) => void
  onClearSearch: () => void
  onClearFBTokens: (id: string) => Observable<any>
  toggleUserBlocked: (id: string) => Observable<any>
  getIntercomData: (id: string) => Observable<any>
  message: (text: string) => void
  resetPassword: (userId: string) => Observable<any>
  clearUserData: (userId: string) => Observable<any>
  getUserData: (userId: string) => Observable<any>
}

export function Impersonate(props: ImpersonateProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [intercomData, setIntercomData] = React.useState<any>(null)
  const [userData, setUserData] = React.useState<any>(null)

  const closeUserDataDialog = () => {
    setIntercomData(null)
    setUserData(null)
  }

  const genericErrorHandler = (error: Error) => {
    props.message(`An error occurred: ${error.message}`)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement
    setSearchQuery(input.value)
  }

  const clearSearch = () => {
    setSearchQuery('')
    props.onClearSearch()
  }

  const search = (e: any) => {
    e.preventDefault()
    props.onClearSearch() // Clear old search results
    props.onSearch(searchQuery)
  }

  const clearFBTokens = (id: string) => {
    const confirmed = confirm('Are you sure?')
    if (confirmed) {
      props.onClearFBTokens(id).subscribe(() => {
        props.message('Tokens cleared.')
      }, genericErrorHandler)
    }
  }

  const toggleUserBlocked = (id: string, isBlocked: boolean) => {
    let confirmed = true
    if (!isBlocked) {
      confirmed = confirm('Are you sure you want to block this user? Their posts will be deleted irreversably!')
    }
    if (confirmed) {
      props.toggleUserBlocked(id).subscribe(() => {
        props.onSearch(searchQuery)
        props.message(`User ${isBlocked ? 'unblocked' : 'blocked'}.`)
      }, genericErrorHandler)
    }
  }

  const showUserData = (id: string) => {
    props.getIntercomData(id).subscribe(setIntercomData, genericErrorHandler)
  }

  const resetPassword = (userId: string) => {

    props.resetPassword(userId).subscribe(
      async res => {
        if (res.error) {
          props.message(`Password reset failed: ${res.error}`)
        } else {
          await navigator.clipboard.writeText(res.password)
          props.message(`Password reset successful! New password: ${res.password}. Copied to clipboard!`)
        }
      },
      (error: Error) => {
        props.message(`Password reset failed: ${error.message}`)
      }
    )
  }

  const btnClasses = { label: styles['btn-label'] }
  const warning = props.current ? (
    <div className={styles.current}>
      <p>
        <WarningIcon className={styles['icon-warn']} />
        {`You are currently impersonating user ${props.current.userId} - ${props.current.name}`}
        <Button onClick={props.onStopImpersonation} className={styles['btn-stop-imp']}>Stop</Button>
      </p>
    </div>
  ) : null

  const promptDeleteUserData = (userId: string) => {
    props.getUserData(userId).subscribe(response => {
      if (response.success) {
        setUserData({ ...response.result, userId })
      } else {
        props.message('Error occurred: ' + response.error?.message)
      }
    }, error => {
      props.message('Error occurred: ' + error)
    })
  }

  const deleteUserData = React.useCallback(() => {
    if (!userData?.userId) {
      closeUserDataDialog()
      return
    }
    props.clearUserData(userData.userId).subscribe(response => {
      if (response.success) {
        props.message('User data deleted!')
      } else {
        props.message('Error occurred: ' + response.error?.message)
      }
      props.onSearch(searchQuery)
      closeUserDataDialog()
    }, error => {
      props.message('Error occurred: ' + error.message)
      closeUserDataDialog()
    })
  }, [props, searchQuery, userData?.userId])

  const onRestartTrial = (appId: string) => {
    dispatch(restartTrial(appId)).toPromise()
      .then(() => {
        props.message('Trial restarted!')
      })
      .catch((e: any) => {
        props.message(`An error occurred: ${e.message}`)
      })
  }

  const searchDisabled = props.loading || searchQuery.trim().length === 0

  return (
    <AdminPage title={PAGE_TITLE}>
      {warning}
      <div className={styles['section-search']}>
        <div className={styles['input-row']}>
          <form onSubmit={search} className={styles.form}>
            <Input
              type="text"
              classes={{ input: styles.input }}
              className={styles['input-control']}
              onChange={handleInputChange}
              placeholder="Search by User ID, email or name"
              name="search"
              value={searchQuery}
            />
            <div className={styles['form-actions']}>
              {
                !props.loading && Boolean(props.users.length) && (
                  <Button variant="contained" onClick={clearSearch}>
                    Clear
                  </Button>
                )
              }
              <Button
                disabled={searchDisabled}
                type="submit"
                variant="contained"
                color="primary"
                className={styles['btn-search']}
                classes={btnClasses}
              >
                {!props.loading && 'Search'}
                {props.loading && <CircularProgress size={LOADER_SIZE} className={styles.loader} />}
              </Button>
            </div>
          </form>
        </div>

        <p className={styles['search-error']}>{props.searchError || ''}</p>
        {
          props.users.length === 0 ? null : (
            <div>
              <Paper>
                <Table className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell key="cell-id">ID</TableCell>
                      <TableCell key="cell-email">Email</TableCell>
                      <TableCell key="cell-fname">Name</TableCell>
                      <TableCell key="cell-data">Intercom Data</TableCell>
                      <TableCell key="cell-posts">
                        <Tooltip title="Planned / Posted (3 days) / Posted (10 days)" placement="top">
                          <span>Posts</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell key="fb-token">FB Tokens</TableCell>
                      <TableCell key="cell-block">Block</TableCell>
                      <TableCell key="cell-imp">Impersonate</TableCell>
                      <TableCell key="cell-pass-reset">Reset Password</TableCell>
                      <TableCell key="cell-trial-reset">Restart Trial</TableCell>
                      <TableCell key="cell-data-clear">Clear user data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      props.users.map(user => (
                        <UserInfoTableRow
                          key={user.userId}
                          user={user}
                          onClearFBTokens={clearFBTokens}
                          onResetPassword={resetPassword}
                          onImpersonate={props.onImpersonate}
                          onShowData={showUserData}
                          onClearUserData={promptDeleteUserData}
                          toggleUserBlocked={toggleUserBlocked}
                          onRestartTrial={onRestartTrial}
                        />
                      ))
                    }
                  </TableBody>
                </Table>
              </Paper>
              <Dialog open={Boolean(intercomData)} maxWidth="xl" onClose={closeUserDataDialog}>
                <div className={styles['dialog-content']}>
                  {
                    intercomData && [
                      <div className={styles['dialog-left']}>
                        <h3>Intercom Data</h3>
                        <pre>
                          {JSON.stringify(intercomData.intercomUser, null, 2)}
                        </pre>
                      </div>,
                      <div className={styles['dialog-right']}>
                        <h3>Database User Data</h3>
                        <pre>
                          {JSON.stringify(intercomData.databaseUser, null, 2)}
                        </pre>
                      </div>
                    ]
                  }
                </div>
              </Dialog>
              <Dialog open={Boolean(userData)} onClose={closeUserDataDialog}>
                <div className={`${styles['dialog-content']} ${styles.sm}`}>
                  {userData && (
                    <div>
                      <h3>User data to be deleted:</h3>
                      <pre>
                        {JSON.stringify(userData, null, 2)}
                      </pre>
                    </div>
                  )}
                </div>
                <div className={styles.actions}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={deleteUserData}
                  >
                    Delete data
                  </Button>
                </div>
              </Dialog>
            </div>
          )
        }
      </div>
    </AdminPage>
  )
}

function UserInfoTableRow(props: {
  user: any,
  onShowData: (userId: string) => void
  onClearFBTokens: (userId: string) => void
  toggleUserBlocked: (userId: string, current: boolean) => void
  onImpersonate: (user: any) => void
  onResetPassword: (userId: string) => void
  onClearUserData: (userId: string) => void
  onRestartTrial: (appId: string) => void
}) {
  const user = props.user

  const onShowDataClick = () => {
    props.onShowData(props.user.userId)
  }

  const onClearFBTokens = () => {
    props.onClearFBTokens(props.user.userId)
  }

  const onToggleUserBlocked = () => {
    props.toggleUserBlocked(user.userId, user.blocked > 0)
  }

  const onImpersonate = () => {
    props.onImpersonate(props.user)
  }

  const onResetPassword = () => {
    props.onResetPassword(user.userId)
  }

  const onClearData = () => {
    props.onClearUserData(props.user.userId)
  }

  const onRestartTrial = () => {
    props.onRestartTrial(props.user.appId)
  }

  return (
    <TableRow key={`row-user-${user.userId}`} className={styles.tr}>
      <TableCell>{user.userId}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.fullName}</TableCell>
      <TableCell>
        <Button size="small" onClick={onShowDataClick}>
          View Data
        </Button>
      </TableCell>
      <TableCell>
        {`${user.posts}/${user.threeDaysPosted}/${user.tenDaysPosted}`}
      </TableCell>
      <TableCell>
        <Button size="small" onClick={onClearFBTokens}>
          Clear
        </Button>
      </TableCell>
      <TableCell>
        <Button onClick={onToggleUserBlocked} size="small">
          {`${user.blocked > 0 ? 'Unblock' : 'Block'}`}
        </Button>
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={onImpersonate}
          className={styles['btn-impersonate']}
        >
          Impersonate
        </Button>
      </TableCell>
      <TableCell>
        <Button size="small" onClick={onResetPassword}>
          Reset password
        </Button>
      </TableCell>
      <TableCell>
        <Button size="small" onClick={onRestartTrial}>
          Restart trial
        </Button>
      </TableCell>
      <TableCell>
        <Tooltip title="Delete user data">
          <IconButton
            size="small"
            onClick={onClearData}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default Impersonate
