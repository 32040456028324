import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import StreamGroupsTableRow from './components/StreamGroupsTableRow'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { sortByKeyAscending } from 'utils/sort/order'
import Input from '@mui/material/Input'
import styles from './StreamGroupsManager.pcss'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { ContentPanel, StreamGroup } from 'admin/interfaces'
import ConfirmDialog from 'components/ConfirmDialog'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import EditableLabel from 'components/EditableLabel'

interface StreamGroupsManagerProps {
  panels: ContentPanel[]
  loading: boolean
  onUpdateStreamGroup: (panel: ContentPanel, group: Partial<StreamGroup>, order?: number) => void
  onStreamRemove: (groupId: string, streamId: string) => void
  onDeleteGroup: (groupId: string) => void
  onDeletePanel: (id: string) => void
  onCreateGroup: (name: string, panelId: string) => void
  onCreatePanel: (name: string) => void
  onUpdatePanel: (id: string, name: string, active: boolean) => void
}

export function StreamGroupsManager(props: StreamGroupsManagerProps) {
  const [activePanel, setActivePanel] = React.useState<ContentPanel | null>(null)
  const [newGroupName, setNewGroupName] = React.useState('')
  const [panelName, setPanelName] = React.useState('')

  const onNewGroupNameValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroupName(e.target.value)
  }

  const onNewPanelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPanelName(e.target.value)
  }

  const createNewGroup = () => {
    if (activePanel) {
      props.onCreateGroup(newGroupName, activePanel.id)
    }
    setNewGroupName('')
  }

  const createNewPanel = () => {
    props.onCreatePanel(panelName)
    setPanelName('')
  }

  const onUpdateGroup = (group: Partial<StreamGroup>, order?: number) => {
    if (activePanel) {
      props.onUpdateStreamGroup(activePanel, group, order)
    }
  }

  React.useEffect(() => {
    if (!activePanel || props.panels.find(p => p.id === activePanel.id) === undefined) {
      setActivePanel(props.panels[0] || null)
    }
  }, [props.panels])

  return (
    <AdminPage title="Content panels">
      <Backdrop open={props.loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div className={styles['container-outer']}>
        <div className={styles['panels-box']}>
          <div>
            <Input
              type="text"
              classes={{ input: styles.input }}
              className={styles['input-control']}
              placeholder="New panel name"
              value={panelName}
              onChange={onNewPanelNameChange}
            />
            <Button size="small" color="primary" variant="contained" className={styles.btn} onClick={createNewPanel}>
              Create Panel
            </Button>
          </div>
          <div className={styles['panels-list']}>
            {props.panels.map(p => (
              <ContentPanelChip
                key={p.id}
                panel={p}
                selected={p.id === activePanel?.id}
                onClick={setActivePanel}
                onDelete={props.onDeletePanel}
                onUpdate={props.onUpdatePanel}
              />
            ))}
          </div>
        </div>
        <div className={styles['container-inner']}>
          <Input
            type="text"
            classes={{ input: styles.input }}
            className={styles['input-control']}
            placeholder="New group name"
            name="newGroupName"
            value={newGroupName}
            onChange={onNewGroupNameValChange}
          />
          <Button size="small" color="primary" variant="contained" className={styles.btn} onClick={createNewGroup}>
            Create Group
          </Button>
          <Paper className={styles.paper}>
            <TableContainer className={styles['table-container']}>
              <Table className={styles.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Streams</TableCell>
                    <TableCell>Order</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {activePanel && activePanel.streamGroups.sort(sortByKeyAscending('order')).map((group, index) => (
                    <StreamGroupsTableRow
                      key={group.id}
                      group={group}
                      index={index}
                      onUpdate={onUpdateGroup}
                      onStreamRemove={props.onStreamRemove}
                      onDeleteGroup={props.onDeleteGroup}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </AdminPage>
  )
}

function ContentPanelChip(props: {
  panel: ContentPanel,
  selected: boolean,
  onClick: (p: ContentPanel) => void,
  onDelete: (id: string) => void,
  onUpdate: (id: string, name: string, active: boolean) => void
}) {
  const [editKey, setEditKey] = React.useState(0)

  React.useEffect(() => {
    setEditKey(current => current + 1)
  }, [props.selected])

  const onClick = () => {
    props.onClick(props.panel)
  }

  const onDelete = () => {
    props.onDelete(props.panel.id)
  }

  const toggleActive = () => {
    if (!props.selected) {
      return
    }
    props.onUpdate(props.panel.id, props.panel.name, !props.panel.active)
  }

  const onNameChange = (value: string) => {
    const newName = value?.trim()
    if (newName && newName !== props.panel.name) {
      props.onUpdate(props.panel.id, newName, props.panel.active)
    }
  }

  return (
    <ConfirmDialog message="Delete Content Panel?">
      {(confirm) => (
        <Chip
          size="medium"
          icon={(
            <Tooltip title={props.panel.active ? 'Active' : 'Inactive'} placement="top">
              <Checkbox
                size="small"
                checked={props.panel.active}
                className={styles['chip-cb']}
                onChange={toggleActive}
              />
            </Tooltip>
          )}
          label={(
            <span className={styles['chip-label-box']}>
              {`ID: ${props.panel.id}`}
              <EditableLabel
                key={editKey}
                value={props.panel.name}
                editMode={props.selected}
                inputClassName={styles['input-edit']}
                className={styles['input-label']}
                onChange={onNameChange}
              />
              {`(${props.panel.streamGroups.length})`}
            </span>
          )}
          className={`${styles.chip} ${styles['chip-big']} ${props.selected ? styles['chip-active'] : ''}`}
          classes={{ label: styles['chip-label'] }}
          onClick={onClick}
          onDelete={confirm(onDelete)}
        />
      )}
    </ConfirmDialog>

  )
}

export default StreamGroupsManager
