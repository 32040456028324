import { ADMIN_SERVICE_URL } from 'config'
import { RequestBuilder, authorizedPipe } from 'services/net'

const ENDPOINT_UPDATE_CONFIG = `${ADMIN_SERVICE_URL}/infoboxes/update_config`

export function updateConfig(callouts: any, version: number) {
  const builder = new RequestBuilder(ENDPOINT_UPDATE_CONFIG).asPost().expectJSON().asFormUrlEncoded()
  const data = { callouts, version }
  return authorizedPipe<any>(
    builder
      .body({ name: 'callouts', config: JSON.stringify(data), prod: true })
      .build()
  )
}
