import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL } from 'config'

export const ENDPOINT_ADMIN_LOGIN = `${ADMIN_SERVICE_URL}/partner/admin_login_v3`

export const V1 = Object.freeze({
  login: function () {
    const builder = new RequestBuilder(ENDPOINT_ADMIN_LOGIN).asPost().asFormUrlEncoded().expectJSON()

    return function (user: string, pass: string) {
      return authorizedPipe<any>(
        builder
          .body({ user, pass })
          .build()
      )
    }
  }()
})
