import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import styles from './CSTagsManager.pcss'
import { StoreThunkDispatch } from 'store/state'
import { useDispatch } from 'react-redux'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { IndexedObject } from 'interfaces'
import { NavLink } from 'react-router-dom'
import { updateTag, getTags } from 'services/content/statuses/actions'

export function CSTagsManager() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [tags, setTags] = React.useState<IndexedObject<{ id: number, name: string, active: boolean, statusIdeasCount: number }>>({})
  const [showAll, setShowAll] = React.useState(false)
  const [showActiveOnly, setShowActiveOnly] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [filter, setFilter] = React.useState('')

  const fetch$ = React.useRef<Subject<void>>()
  const update$ = React.useRef<Subject<{ id: number, active: boolean }>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .flatMap(() => {
        setLoading(true)
        return dispatch(getTags(true)).pipe(catchError(() => {
          dispatch(message('Could not fetch data! Please refresh.'))
          return Observable.of([])
        }))
      })
      .subscribe(response => {
        setTags(response.reduce((map: IndexedObject<any>, tag: any) => {
          map[tag.id] = tag
          return map
        }, {}))
        setLoading(false)
      })

    fetch$.current.next()

    return () => {
      fetch$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current.flatMap(
      ({ id, active }) => dispatch(updateTag(id, active))
        .pipe(
          catchError((error: Error) => {
            dispatch(message('Error! Update failed: ' + error.message))
            return Observable.of({ error: true })
          })
        )
    )
      .subscribe((response: any) => {
        if (!response.error) {
          dispatch(message('Tag updated!'))
          setTags(current => ({
            ...current,
            [response.id]: {
              ...current[response.id],
              active: response.active
            }
          }))
        }
      })
  }, [])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setShowAll(false)
    setShowActiveOnly(false)
    setFilter(value)
  }

  const onTagClick = (id: number) => {
    update$.current?.next({ id, active: !tags[id].active })
  }

  const toggleShowAll = () => {
    setFilter('')
    setShowActiveOnly(false)
    setShowAll(current => !current)
  }

  const toggleShowActiveOnly = () => {
    setFilter('')
    setShowAll(false)
    setShowActiveOnly(current => !current)
  }

  return (
    <AdminPage title="Tags">
      <NavLink to="/admin/conversation-starters">Back to Post Ideas</NavLink>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div className={styles['tags-wrapper']}>
        <div className={styles['search-box']}>
          <FormControlLabel
            label="Show all"
            control={
              <Checkbox checked={showAll} onChange={toggleShowAll} />
            }
            className={styles.cb}
          />
          <FormControlLabel
            label="Show active only"
            control={
              <Checkbox checked={showActiveOnly} onChange={toggleShowActiveOnly} />
            }
            className={styles.cb}
          />
          <TextField
            placeholder="Filter tags"
            value={filter}
            classes={{ root: styles['search-input'] }}
            onChange={onSearchChange}
          />
        </div>
        <div className={styles.grid}>
          {showAll && !filter && Object.values(tags).map(t => (
            <TagChip tag={t} key={t.id} onClick={onTagClick} />
          ))}
          {filter && Object.values(tags).map(t => {
            if (t.name.toLowerCase().indexOf(filter) !== -1) {
              return (
                <TagChip tag={t} key={t.id} onClick={onTagClick} />
              )
            }
            return null
          })}
          {showActiveOnly && Object.values(tags).filter(t => t.active).map(t => (
            <TagChip tag={t} key={t.id} onClick={onTagClick} />
          ))}
        </div>
      </div>
    </AdminPage>
  )
}

const TagChip = React.memo(function (props: {
  tag: { id: number, name: string, active: boolean, statusIdeasCount: number },
  onClick: (id: number) => void
}
) {
  const onClick = () => {
    props.onClick(props.tag.id)
  }

  return (
    <Chip
      size="small"
      label={`${props.tag.name} (${props.tag.statusIdeasCount})`}
      onClick={onClick}
      className={`${styles.chip} ${props.tag.active ? styles.active : ''}`}
    />
  )
})

export default CSTagsManager
