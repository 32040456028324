import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL } from 'config'
import { FeatureInfo, PlanLimitInfo } from 'interfaces'

export const ENDPOINT_GET_FEATURES = `${ADMIN_SERVICE_URL}/features`
export const ENDPOINT_GET_LIMITS = `${ADMIN_SERVICE_URL}/limits`
export const ENDPOINT_UPDATE_FEATURE = `${ADMIN_SERVICE_URL}/features/update`
export const ENDPOINT_UPDATE_LIMIT = `${ADMIN_SERVICE_URL}/limits/update`
export const ENDPOINT_REORDER_FEATURES = `${ADMIN_SERVICE_URL}/features/reorder`
export const ENDPOINT_REORDER_LIMITS = `${ADMIN_SERVICE_URL}/limits/reorder`

export const V1 = Object.freeze({
  getFeatures: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_FEATURES).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  getLimits: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_LIMITS).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  updateFeature: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_FEATURE).asPost().expectJSON()

    return function (feature: FeatureInfo, image?: File) {
      const data = new FormData()
      data.append('key', feature.key)
      data.append('product_id', feature.plan)
      data.append('name', feature.name)
      data.append('sales_pitch', feature.salesPitch)
      data.append('mobile_sales_pitch', feature.mobileSalesPitch)
      data.append('mobile_cta', feature.mobileCta)
      data.append('upgrade_action_text', feature.upgradeActionText)
      data.append('popup_dismiss_text', feature.upgradePopupDismissText)
      if (image) {
        data.append('file', image)
      }

      return authorizedPipe<any>(
        builder
          .form(data)
          .build()
      )
    }
  }(),
  updateLimit: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_LIMIT).asPost().expectJSON()

    return function (limit: PlanLimitInfo, image?: File) {
      const data = new FormData()
      data.append('key', limit.key)
      Object.keys(limit.plans).forEach(plan => {
        data.append(plan, limit.plans[plan].toString())
      })
      data.append('name', limit.name)
      data.append('upgrade_action_text', limit.upgradeActionText)
      data.append('sales_pitch', limit.salesPitch)
      data.append('mobile_sales_pitch', limit.mobileSalesPitch)
      data.append('mobile_cta', limit.mobileCta)
      data.append('popup_dismiss_text', limit.upgradePopupDismissText)
      if (image) {
        data.append('file', image)
      }

      return authorizedPipe<any>(
        builder
          .form(data)
          .build()
      )
    }
  }(),
  reorderFeatures: function () {
    const builder = new RequestBuilder(ENDPOINT_REORDER_FEATURES).asPost().expectJSON().asFormUrlEncoded()
    return function (order: string[]) {
      return authorizedPipe<any>(
        builder.body({ order: order.join(',') }).build()
      )
    }
  }(),
  reorderLimits: function () {
    const builder = new RequestBuilder(ENDPOINT_REORDER_LIMITS).asPost().expectJSON().asFormUrlEncoded()
    return function (order: string[]) {
      return authorizedPipe<any>(
        builder.body({ order: order.join(',') }).build()
      )
    }
  }(),
  resetCache: function () {
    const builder = new RequestBuilder(`${ADMIN_SERVICE_URL}/subscriptions/products/clear_cache`).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }()
})
