import { V1 } from './net'
import { StoreThunkDispatch } from 'store/state'
import { StreamGroup } from 'admin/interfaces'
import { tap } from 'rxjs/operators/tap'
import { streamAdapter } from 'services/content/adapters/streams'
import slugify from 'utils/format/slugify'
import { sortByKeyAscending } from 'utils/sort/order'

export const ACTION_GET_STREAM_GROUPS_SUCCESS = 'ACTION_GET_STREAM_GROUPS_SUCCESS'
export type GetStreamGroupsAction = { type: typeof ACTION_GET_STREAM_GROUPS_SUCCESS, payload: StreamGroup[] }
export function getStreamGroups(fromAdmin?: boolean) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getStreamGroups(fromAdmin)).map(response => {
      const groups = response.map(group => ({
        ...group,
        active: Boolean(group.active),
        slug: slugify(group.name),
        streams: group.streams.map((s: any) => streamAdapter(s))
      })).sort(sortByKeyAscending('order'))

      dispatch({
        type: ACTION_GET_STREAM_GROUPS_SUCCESS,
        payload: groups
      })
      return groups
    })
  }
}

export function updateStreamGroup(data: Partial<StreamGroup>) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.updateStreamGroup(data)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}

export function reorderStreamGroups(panelId: string, order: string[]) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.reorderStreamGroups(panelId, order)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}

export function streamRemove(groupId: string, streamId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.streamRemove(groupId, streamId)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}

export function streamAdd(groupId: string, streamId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.streamAdd(groupId, streamId)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    })).map(folder => streamAdapter(folder))
  }
}

export function createGroup(name: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.createGroup(name)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}

export function deleteGroup(groupId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.deleteGroup(groupId)).pipe(tap((response) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}
