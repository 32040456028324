import * as React from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Observable } from 'rxjs/Observable'
import { AdminHeader } from '../../components/AdminHeader'

import styles from './AdminLogin.pcss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'

const SNACK_TIMEOUT = 3000

export interface AdminLoginProps {
  login: (username: string, password: string) => Observable<any>
}

export function AdminLogin(props: AdminLoginProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUsername(value)
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPassword(value)
  }

  const handleLogin = (e: any) => {
    e.preventDefault()
    if (!username.trim() || !password.trim()) {
      setError('Username and password are required.')
      return
    }

    setError('')
    props.login(username, password)
      .subscribe(
        () => {
          console.log('Admin login success')
          navigate('/admin/users')
        },
        (error: any) => {
          setError('Something went wrong! ' + error?.message)
          if (error?.message?.indexOf('Missing access token') > -1) {
            dispatch(message('Please log in to the app first. Redirecting...', 'info', SNACK_TIMEOUT, true))
            setTimeout(() => {
              navigate('/login')
            // eslint-disable-next-line no-magic-numbers
            }, 4000)
          }
        }
      )
  }

  return (
    <section>
      <AdminHeader className={styles.header} />
      <form onSubmit={handleLogin} className={styles.form}>
        <TextField
          type="text"
          placeholder="username"
          value={username}
          onChange={handleUsernameChange}
          required
          className={styles.input}
        />
        <TextField
          type="password"
          placeholder="password"
          value={password}
          onChange={handlePasswordChange}
          required
          className={styles.input}
        />
        <Button variant="contained" color="primary" type="submit">Log in</Button>
        <p className={styles.error}>{error}</p>
      </form>
    </section>
  )
}

export default AdminLogin
