import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import Input from '@mui/material/Input'
import SearchIcon from '@mui/icons-material/Search'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { searchApps, updateAppLimits } from 'admin/services/apps/net'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { catchError } from 'rxjs/operators/catchError'
import styles from './AppsRoute.pcss'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import WarningIcon from '@mui/icons-material/Warning'
import {
  clearFBTokens,
  clearUserData,
  getIntercomData,
  getUserData,
  makeAdmin,
  resetPassword,
  restartTrial,
  toggleUserBlocked
} from 'admin/services/users/actions'
import { AppInfo, AppTableRow } from './AppTableRow'
import Dialog from '@mui/material/Dialog'
import { impersonateUser, stopImpersonateUser } from 'admin/services/actions'
import { impersonationSelector } from 'admin/services/selectors'

export function AppsRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const impersonate = useSelector(impersonationSelector)
  const [apps, setApps] = React.useState<AppInfo[]>([])
  const [loading, setLoading] = React.useState(false)
  const [selectedApp, setSelectedApp] = React.useState<AppInfo | null>(null)
  const [intercomData, setIntercomData] = React.useState<any>(null)
  const [userData, setUserData] = React.useState<any>(null)
  const fetch$ = React.useRef<Subject<string>>()
  const update$ = React.useRef<Subject<{
    appId: string,
    profilesLimit?: number,
    postsPerDay?: number,
    locations?: number,
    cid?: string,
    isBeta: '0' | '1'
  }>>()
  const profilesInputRef = React.useRef<HTMLInputElement>(null)
  const postsInputRef = React.useRef<HTMLInputElement>(null)
  const listingsInputRef = React.useRef<HTMLInputElement>(null)
  const chargifyInputRef = React.useRef<HTMLInputElement>(null)
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(() => {
        setLoading(true)
      }))
      .flatMap(id => dispatch(searchApps(id)).pipe(catchError(() => {
        return Observable.of({ error: true })
      })))
      .subscribe((response: any) => {
        setLoading(false)
        if (response.error) {
          dispatch(message('Error fetching apps!'))
        } else {
          setApps(response)
        }
      })

    return () => fetch$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current
      .pipe(tap(() => {
        setLoading(true)
      }))
      .flatMap((data) => dispatch(updateAppLimits(data.appId, data.profilesLimit, data.postsPerDay, data.locations, data.cid, data.isBeta))
        .pipe(catchError(() => {
          return Observable.of({ error: true })
        }))
      )
      .subscribe((response: any) => {
        setLoading(false)
        if (response.result === 'success') {
          if (selectedApp) {
            fetch$.current?.next(selectedApp.id)
          }
          dispatch(message('App updated!'))
          setSelectedApp(null)
        } else {
          dispatch(message('App update failed!'))
        }
      })

    return () => update$.current?.unsubscribe()
  }, [selectedApp, dispatch])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const id = (e.target as HTMLInputElement).value.trim()
    if (e.key === 'Enter') {
      setApps([])
      setSelectedApp(null)
      fetch$.current?.next(id)
    }
  }

  const closeUserDataDialog = () => {
    setIntercomData(null)
    setUserData(null)
  }

  const toggleIsBeta = () => {
    if (selectedApp) {
      setSelectedApp({ ...selectedApp, isBeta: selectedApp.isBeta === '0' ? '1' : '0' })
    }
  }

  const onChange = (key: 'profiles' | 'posts' | 'allowedLocations' | 'chargifyId') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedApp) {
      return
    }

    const value = e.target.value.trim()

    if (key === 'profiles') {
      setSelectedApp((current: AppInfo) => ({
        ...current,
        profilesLimit: parseInt(value, 10)
      }))
    } else if (key === 'allowedLocations') {
      setSelectedApp((current: AppInfo) => ({
        ...current,
        allowedLocations: parseInt(value, 10)
      }))
    } else if (key === 'posts') {
      setSelectedApp((current: AppInfo) => ({
        ...current,
        postsPerDay: parseInt(value, 10)
      }))
    } else if (key === 'chargifyId') {
      setSelectedApp((current: AppInfo) => ({
        ...current,
        chargifySubscriptionId: value
      }))
    }
  }

  const promptDeleteUserData = (userId: string) => {
    dispatch(getUserData(userId))
      .toPromise()
      .then(response => {
        if (response.success) {
          setUserData({ ...response.result, userId })
        } else {
          dispatch(message('Error occurred: ' + response.error?.message))
        }
      }).catch(error => {
        dispatch(message('Error occurred: ' + error))
      })
  }

  const updateApp = () => {
    if (selectedApp?.profilesLimit === undefined && selectedApp?.postsPerDay === undefined) {
      dispatch(message('Please enter Profiles or Posts limits!'))
      return
    }

    if (!selectedApp) {
      return
    }

    update$.current?.next({
      appId: selectedApp.id,
      postsPerDay: selectedApp.postsPerDay,
      profilesLimit: selectedApp.profilesLimit,
      locations: selectedApp.allowedLocations,
      cid: selectedApp.chargifySubscriptionId,
      isBeta: selectedApp.isBeta
    })
  }

  const genericErrorHandler = (error: Error | any) => {
    dispatch(message(`An error occurred: ${error.response?.error?.message || error.message}`))
    return false
  }

  const clearFacebookTokens = (userId: string) => {
    const confirmed = confirm('Are you sure?')
    if (confirmed) {
      dispatch(clearFBTokens(userId))
        .toPromise()
        .then(() => {
          dispatch(message('Tokens cleared.'))
        })
        .catch(genericErrorHandler)
    }
  }

  const showUserData = (userId: string) => {
    dispatch(getIntercomData(userId)).toPromise().then(setIntercomData).catch(genericErrorHandler)
  }

  const deleteUserData = () => {
    if (!userData?.userId) {
      closeUserDataDialog()
      return
    }
    dispatch(clearUserData(userData.userId))
      .toPromise()
      .then(response => {
        if (response.success) {
          dispatch(message('User data deleted!'))
        } else {
          dispatch(message('Error occurred: ' + response.error?.message))
        }
        closeUserDataDialog()
      })
      .catch(error => {
        dispatch(message('Error occurred: ' + error.message))
        closeUserDataDialog()
      })
  }

  const onToggleUserBlocked = (userId: string) => {
    dispatch(toggleUserBlocked(userId))
      .toPromise()
      .then(() => {
        dispatch(message('Success!'))
        const search = searchInputRef.current?.value || selectedApp?.id
        if (search) {
          fetch$.current?.next(search)
        }
      })
      .catch(genericErrorHandler)
  }

  const onRestartTrial = (appId: string) => {
    dispatch(restartTrial(appId))
      .toPromise()
      .then(() => {
        dispatch(message('Trial restarted!'))
      })
      .catch((e: any) => {
        dispatch(message(`An error occurred: ${e.response?.error?.message}`))
      })
  }

  const onResetPassword = (userId: string) => {
    dispatch(resetPassword(userId))
      .toPromise()
      .then(() => {
        dispatch(message('Password reset!'))
      })
      .catch(genericErrorHandler)
  }

  const onMakeMasterAdmin = async (userId: string, appId: string) => {
    const confirmed = confirm(`Do you want to make the user a master admin of app ${appId}?`)
    if (confirmed) {
      const makeAdminResult = await dispatch(makeAdmin(userId))
        .toPromise()
        .then(() => {
          dispatch(message('Success!'))
          return true
        })
        .catch(genericErrorHandler)

      if (makeAdminResult !== undefined && makeAdminResult === true) {
        fetch$.current?.next(appId)
      }
    }
  }

  const onImpersonate = (user: any) => {
    dispatch(impersonateUser(user))
  }

  const onStopImpersonation = () => {
    dispatch(stopImpersonateUser())
  }

  React.useEffect(() => {
    if (selectedApp) {
      if (profilesInputRef.current) {
        profilesInputRef.current.value = selectedApp.profilesLimit + ''
      }
      if (postsInputRef.current) {
        postsInputRef.current.value = selectedApp.postsPerDay + ''
      }
      if (listingsInputRef.current) {
        listingsInputRef.current.value = selectedApp.allowedLocations + ''
      }
      if (chargifyInputRef.current) {
        chargifyInputRef.current.value = selectedApp.chargifySubscriptionId + ''
      }
    }
  }, [selectedApp])

  return (
    <AdminPage title="Apps">
      {impersonate && (
        <div className={styles.current}>
          <p>
            <WarningIcon className={styles['icon-warn']} />
            {`You are currently impersonating user ${impersonate.userId} - ${impersonate.name}`}
            <Button onClick={onStopImpersonation} className={styles['btn-stop-imp']}>Stop</Button>
          </p>
        </div>
      )}
      <section className={styles.container}>
        <header className={styles.header}>
          <Input
            ref={searchInputRef}
            startAdornment={<SearchIcon />}
            placeholder="Search Apps by ID"
            classes={{ root: styles['search-input'] }}
            onKeyDown={onKeyDown}
          />
          <div className={`${styles['edit-box']} ${selectedApp ? styles.active : ''}`}>
            {selectedApp && (
              <div className={styles['edit-flex']}>
                <div>App: {selectedApp.id}</div>
                <div>User: {selectedApp.userId}</div>
                <div>
                  Profiles limit:
                  <Input
                    type="number"
                    inputRef={profilesInputRef}
                    classes={{ root: styles['edit-input'] }}
                    onChange={onChange('profiles')}
                  />
                </div>
                <div>
                  Posts per day:
                  <Input
                    type="number"
                    inputRef={postsInputRef}
                    classes={{ root: styles['edit-input'] }}
                    onChange={onChange('posts')}
                  />
                </div>
                <div>
                  Business Listings:
                  <Input
                    type="number"
                    inputRef={listingsInputRef}
                    classes={{ root: styles['edit-input'] }}
                    onChange={onChange('allowedLocations')}
                  />
                </div>
                <div>
                  Chargify ID:
                  <Input
                    type="text"
                    inputRef={chargifyInputRef}
                    classes={{ root: styles['edit-input'] }}
                    onChange={onChange('chargifyId')}
                  />
                </div>
                <div>
                  <FormControlLabel
                    label="Beta user"
                    labelPlacement="start"
                    classes={{ label: styles.label }}
                    control={(
                      <Checkbox
                        checked={selectedApp.isBeta === '1'}
                        color="primary"
                        onChange={toggleIsBeta}
                      />
                    )}
                  />
                </div>
                <div>
                  <Button size="small" variant="contained" color="primary" onClick={updateApp}>Save</Button>
                </div>
              </div>
            )}
          </div>
        </header>
        <div className={styles.content}>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr className={styles.tr}>
                <td className={styles['cell-s']}>App ID</td>
                <td className={styles['cell-s']}>User ID</td>
                <td className={styles['cell-s']}>Chargify ID</td>
                <td>Email</td>
                <td>Name</td>
                <td className={styles['cell-s']}>Data</td>
                <td className={styles['cell-s']}>Posts</td>
                <td>FB Token</td>
                <td className={styles['cell-s']}>Master Admin</td>
                <td>Impersonate</td>
                <td className={styles['cell-s']}>Actions</td>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {apps.map((app, i) => (
                <AppTableRow
                  key={`${app.userId}-${i}`}
                  app={app}
                  highlight={app.userId === selectedApp?.userId}
                  onClick={setSelectedApp}
                  onClearFBTokens={clearFacebookTokens}
                  clearUserData={promptDeleteUserData}
                  makeMasterAdmin={onMakeMasterAdmin}
                  resetPassword={onResetPassword}
                  restartTrial={onRestartTrial}
                  toggleUserBlocked={onToggleUserBlocked}
                  onViewData={showUserData}
                  onImpersonate={onImpersonate}
                />
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog open={Boolean(intercomData)} maxWidth="xl" onClose={closeUserDataDialog}>
        <div className={styles['dialog-content']}>
          {intercomData && [
            <div className={styles['dialog-left']}>
              <h3>Intercom Data</h3>
              <pre>
                {JSON.stringify(intercomData.intercomUser, null, 2)}
              </pre>
            </div>,
            <div className={styles['dialog-right']}>
              <h3>Database User Data</h3>
              <pre>
                {JSON.stringify(intercomData.databaseUser, null, 2)}
              </pre>
            </div>
          ]}
        </div>
      </Dialog>
      <Dialog open={Boolean(userData)} onClose={closeUserDataDialog}>
        <div className={`${styles['dialog-content']} ${styles.sm}`}>
          {userData && (
            <div>
              <h3>User data to be deleted:</h3>
              <pre>
                {JSON.stringify(userData, null, 2)}
              </pre>
            </div>
          )}
        </div>
        <div className={styles['dialog-actions']}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={deleteUserData}
          >
            Delete data
          </Button>
        </div>
      </Dialog>
    </AdminPage>
  )
}

export default AppsRoute
