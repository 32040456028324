import * as React from 'react'
import FeaturesManager from './FeaturesManager'
import { FeatureInfo, PlanLimitInfo } from 'interfaces'
import { StoreThunkDispatch } from 'store/state'
import { getFeatures, updateFeature, updateLimit, getLimits, reorderLimits, reorderFeatures } from 'admin/services/actions'
import { connect, useSelector } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { tap } from 'rxjs/operators/tap'
import { zip } from 'rxjs/operators/zip'
import { Subscription } from 'rxjs/Subscription'
import { sortByKeyAscending, reorder } from 'utils/sort/order'
import { promptUpgrade, productsSelector } from 'services/product'
import { checkTokens } from 'services/auth'
import { HIDDEN_FEATURES, HIDDEN_LIMITS } from 'shared/constants'

function getNewOrder(features: FeatureInfo[], limits: PlanLimitInfo[], itemToMove: { order: number }, newPosition: number) {
  const currentOrder = [...features, ...limits].sort(sortByKeyAscending('order'))

  const startIndex = currentOrder.findIndex(item => item.order === itemToMove.order)
  const endIndex = currentOrder.findIndex(item => item.order === newPosition)

  const newOrder = reorder(currentOrder, startIndex, endIndex).map((item, index) => ({ ...item, order: index + 1 }))
  const orderedFeatures = newOrder.filter(item => item.isFeature).sort(sortByKeyAscending('id')).map(item => item.order)
  const orderedLimits = newOrder.filter(item => item.isLimit).sort(sortByKeyAscending('id')).map(item => item.order)
  return [orderedFeatures, orderedLimits]
}

interface FeaturesManagerContainerConnectedProps {
  getFeatures: () => Observable<any>
  getLimits: () => Observable<any>
  updateFeature: (feature: FeatureInfo, file?: File) => Observable<any>
  updateLimit: (limit: PlanLimitInfo, file?: File) => Observable<any>
  message: (text: string) => void
  reorderFeatures: (order: string[]) => Observable<any>
  reorderLimits: (order: string[]) => Observable<any>
  openUpgradeDialog: (key: string, product: string) => void
  openLimitUpgradeDialog: (limitKey: string, productHandle: string) => void
  getProducts: () => Observable<any>
}

export function FeaturesManagerContainer(props: FeaturesManagerContainerConnectedProps) {
  const [features, setFeatures] = React.useState<FeatureInfo[]>([])
  const [limits, setLimits] = React.useState<PlanLimitInfo[]>([])
  const [loading, setLoading] = React.useState(true)
  const [updatingFeature, setUpdatingFeature] = React.useState<{ feat: FeatureInfo, file: File | null, order?: string[][] } | null>(null)
  const [updatingLimit, setUpdatingLimit] = React.useState<{ limit: PlanLimitInfo, file: File | null, order?: string[][] } | null>(null)
  const plans = useSelector(productsSelector).reduce((map: Record<string, string>, p) => {
    map[p.id] = p.name
    return map
  }, {})

  React.useEffect(() => {
    const sub = props.getFeatures().zip(props.getLimits(), props.getProducts())
      .subscribe(([feats, limits]) => {
        setFeatures(feats)
        setLimits(limits)
        setLoading(false)
      }, (error) => {
        props.message('Failed to fetch features: ' + error.message)
        setLoading(false)
      })
    return () => sub.unsubscribe()
  }, [])

  React.useEffect(() => {
    let sub: Subscription
    if (updatingFeature) {
      sub = props.updateFeature(updatingFeature.feat, updatingFeature.file || undefined)
        .pipe(
          updatingFeature.order
            ? zip(
              props.reorderFeatures(updatingFeature.order[0]),
              props.reorderLimits(updatingFeature.order[1])
            )
            : tap(() => { /** no-op */ })
        )
        .flatMap(props.getFeatures)
        .zip(props.getLimits())
        .pipe(tap(() => {
          props.message('Update successful')
        }, (error) => {
          props.message('Update error: ' + error.message)
          setLoading(false)
        }))
        .subscribe(([feats, limits]) => {
          setFeatures(feats)
          setLimits(limits)
          setLoading(false)
        })
    }
    return () => {
      if (sub) {
        sub.unsubscribe()
      }
    }
  }, [updatingFeature])

  React.useEffect(() => {
    let sub: Subscription
    if (updatingLimit) {
      sub = props.updateLimit(updatingLimit.limit, updatingLimit.file || undefined)
        .pipe(
          updatingLimit.order
            ? zip(
              props.reorderFeatures(updatingLimit.order[0]),
              props.reorderLimits(updatingLimit.order[1])
            )
            : tap(() => { /** no-op */ })
        )
        .flatMap(props.getLimits)
        .zip(props.getFeatures())
        .pipe(tap(() => {
          props.message('Update successful')
        }, (error) => {
          props.message('Update error: ' + error.message)
          setLoading(false)
        }))
        .subscribe(([limits, feats]) => {
          setLimits(limits)
          setFeatures(feats)
          setLoading(false)
        })
    }
    return () => {
      if (sub) {
        sub.unsubscribe()
      }
    }
  }, [updatingLimit])

  const updateFeature = (feat: FeatureInfo, file: File | null, order?: number) => {
    const featuresLimitsOrder = order ? getNewOrder(features, limits, feat, order) : undefined
    setLoading(true)
    setUpdatingFeature({ feat, file, order: featuresLimitsOrder })
  }

  const updateLimit = (limit: PlanLimitInfo, file: File | null, order?: number) => {
    const featuresLimitsOrder = order ? getNewOrder(features, limits, limit, order) : undefined
    setLoading(true)
    setUpdatingLimit({ limit, file, order: featuresLimitsOrder })
  }

  return (
    <FeaturesManager
      features={features}
      limits={limits}
      plans={plans}
      loading={loading}
      onUpdateFeature={updateFeature}
      onUpdateLimit={updateLimit}
      openFeatureUpgradeDialog={props.openUpgradeDialog}
      openLimitUpgradeDialog={props.openLimitUpgradeDialog}
    />
  )
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    getFeatures: () => dispatch(getFeatures()).map(response => response.filter((f: any) => !HIDDEN_FEATURES.includes(f.key))),
    getLimits: () => dispatch(getLimits()).map(response => response.filter((l: any) => !HIDDEN_LIMITS.includes(l.key))),
    getProducts: () => dispatch(checkTokens()),
    updateFeature: (feature: FeatureInfo, file?: File) => dispatch(updateFeature(feature, file)),
    updateLimit: (limit: PlanLimitInfo, file?: File) => dispatch(updateLimit(limit, file)),
    message: (text: string) => dispatch(message(text)),
    reorderFeatures: (order: string[]) => dispatch(reorderFeatures(order)),
    reorderLimits: (order: string[]) => dispatch(reorderLimits(order)),
    openUpgradeDialog: (key: string, product: string) => dispatch(promptUpgrade({ feature: key, product })),
    openLimitUpgradeDialog: (limitKey: string, productHandle: string) => {
      dispatch(promptUpgrade({ feature: limitKey, product: productHandle }))
    }
  }
}

export default connect(null, mapDispatchToProps)(FeaturesManagerContainer)
