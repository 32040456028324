import * as React from 'react'
import { Routes, Route, NavLink, Navigate } from 'react-router-dom'
import { AdminHeader } from '../../components/AdminHeader'
import Impersonate from 'admin/routes/impersonate'
import PersonIcon from '@mui/icons-material/AccountCircle'
import TeamIcon from '@mui/icons-material/Group'
import UIIcon from '@mui/icons-material/Palette'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import FeaturesIcon from '@mui/icons-material/Widgets'
import CarouselIcon from '@mui/icons-material/ViewCarousel'
import CommentIcon from '@mui/icons-material/Comment'
import LogoutIcon from '@mui/icons-material/PowerSettingsNew'
import CollectionsIcon from '@mui/icons-material/Ballot'
import QuotesIcon from '@mui/icons-material/FormatQuote'
import AppsIcon from '@mui/icons-material/Settings'
import AIIcon from '@mui/icons-material/AutoFixNormal'
import VideoIcon from '@mui/icons-material/VideoLibrary'
import ConnectedSnackbar from 'components/ConnectedSnackbar'
import TeamMembers from '../../routes/team'
import FeaturesManager from '../../routes/features'
import UpgradePopup from 'components/UpgradePopup'
import StreamGroupsManager from '../../routes/stream_groups'
import QuotesRoute from '../../routes/quotes'
import QuoteTagsManager from '../../routes/quotes/QuoteTagsManager'
import QuoteKeywords from '../../routes/quotes/QuoteKeywords'
import CSTagsManager from '../../routes/statuses/CSTagsManager'
import CSKeywords from '../../routes/statuses/CSKeywords'
import CheckoutUI from '../../routes/checkout/CheckoutUIRoute'
import InfoBoxes from '../../routes/info_boxes/InfoBoxesRoute'
import HomeConfig from '../../routes/home-config'
import AppsRoute from '../../routes/apps'
import PlansRoute from '../../routes/plans'
import styles from './AdminRoot.pcss'
import StatusesRoute from 'admin/routes/statuses'
import { useDispatch } from 'react-redux'
import { adminLogout } from 'admin/services/actions'
import { PPLogo } from 'components/Icons'
import { WriterConfig } from '../../routes/writer'
import { VideoTrainingConfig } from '../../routes/training'
import ErrorLogo from 'components/Icons/ErrorLogo'
import { ErrorsRoute } from 'admin/routes/errors'
import { StoreThunkDispatch } from '../../../store/state'

export const ADMIN_PATHNAME = '/admin'

export function AdminRoot() {
  const dispatch = useDispatch<StoreThunkDispatch>()

  const logout = () => dispatch(adminLogout())

  const getClassName = (props: { isActive: boolean }) => `${styles['nav-link']} ${props.isActive ? styles.active : ''}`

  return (
    <div className={styles.layout}>
      <AdminHeader className={styles.header} />
      <div className={styles.sidenav}>
        <div className={styles.nav}>
          <NavLink to="users" className={getClassName}>
            <PersonIcon className={styles['nav-icon']} /> Users
          </NavLink>
          <NavLink to="apps" className={getClassName}>
            <AppsIcon className={styles['nav-icon']} /> Apps
          </NavLink>
          <NavLink to="writer" className={getClassName}>
            <AIIcon className={styles['nav-icon']} /> AI Writer
          </NavLink>
          <NavLink to="video-training" className={getClassName}>
            <VideoIcon className={styles['nav-icon']} /> Video Training
          </NavLink>
          <NavLink to="info-boxes" className={getClassName}>
            <InfoIcon className={styles['nav-icon']} /> Info boxes
          </NavLink>
          <NavLink to="features" className={getClassName}>
            <FeaturesIcon className={styles['nav-icon']} /> Features
          </NavLink>
          <NavLink to="home-config" className={getClassName}>
            <CarouselIcon className={styles['nav-icon']} /> Home Carousels
          </NavLink>
          <NavLink to="content-panels" className={getClassName}>
            <CollectionsIcon className={styles['nav-icon']} /> Content panels
          </NavLink>
          <NavLink to="quotes" className={getClassName}>
            <QuotesIcon className={styles['nav-icon']} /> Quotes
          </NavLink>
          <NavLink to="conversation-starters" className={getClassName}>
            <CommentIcon className={styles['nav-icon']} /> Post Ideas
          </NavLink>
          <NavLink to="checkout-ui" className={getClassName}>
            <UIIcon className={styles['nav-icon']} /> Checkout UI
          </NavLink>
          <NavLink to="team" className={getClassName}>
            <TeamIcon className={styles['nav-icon']} /> Team Members
          </NavLink>
          <NavLink to="plans" className={getClassName}>
            <PPLogo className={`${styles['nav-icon']} ${styles['icon-pp']}`} /> Plans
          </NavLink>
          <NavLink to="errors" className={getClassName}>
            <ErrorLogo className={`${styles['nav-icon']} ${styles['icon-pp']}`} /> Post Errors
          </NavLink>
          <NavLink to="#" onClick={logout} className={styles['nav-link']}>
            <LogoutIcon className={styles['nav-icon']} /> Log out
          </NavLink>
        </div>
      </div>
      <div className={styles.content} data-test="admin-content">
        <Routes>
          <Route path="users" element={<Impersonate />} />
          <Route path="apps" element={<AppsRoute />} />
          <Route path="writer/*" element={<WriterConfig />} />
          <Route path="video-training/*" element={<VideoTrainingConfig />} />
          <Route path="team" element={<TeamMembers />} />
          <Route path="plans" element={<PlansRoute />} />
          <Route path="features" element={<FeaturesManager />} />
          <Route path="home-config" element={<HomeConfig />} />
          <Route path="content-panels" element={<StreamGroupsManager />} />
          <Route path="quotes/tags" element={<QuoteTagsManager />} />
          <Route path="quotes/keywords" element={<QuoteKeywords />} />
          <Route path="quotes" element={<QuotesRoute />} />
          <Route path="conversation-starters/tags" element={<CSTagsManager />} />
          <Route path="conversation-starters/keywords" element={<CSKeywords />} />
          <Route path="conversation-starters" element={<StatusesRoute />} />
          <Route path="checkout-ui" element={<CheckoutUI />} />
          <Route path="info-boxes" element={<InfoBoxes />} />
          <Route path="errors" element={<ErrorsRoute />} />
          <Route path="/" element={<Navigate to="users" replace />} />
        </Routes>
      </div>
      <UpgradePopup />
    </div>
  )
}

export default AdminRoot
