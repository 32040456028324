import React, { useCallback, useEffect, useState } from 'react'
import styles from './CategoryList.pcss'
import { PromptCategory, Prompt } from 'shared/types'
import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import { deletePrompt, reorderPrompts, savePrompt, savePromptCategory } from 'services/compose/assistant/net'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { DndContext, useDroppable, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import { reorder } from 'shared/utils'
import Icon from '@mdi/react'
import { mdiDragVertical } from '@mdi/js'
import { getPrompts } from 'services/compose/assistant'
import PPSwitch from 'components/PPSwitch'
import { PromptCategoryType } from 'shared/types/Composer/writer'

interface CategoryDetailsProps {
  categories: PromptCategory[]
}

export const CategoryDetails: React.FC<CategoryDetailsProps> = ({ categories }) => {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const params = useParams()
  const navigate = useNavigate()
  const id = params.categoryId

  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: 'droppable'
  })

  const category = categories.find((category) => category.id === id)
  const [selectedType, setSelectedType] = useState<PromptCategoryType>(category?.type || 'text')
  const [newCategoryName, setNewCategoryName] = useState(category?.name || '')
  const [newPrompt, setNewPrompt] = useState('')
  const [visible, setVisible] = useState(Boolean(category?.isVisible))
  const [loading, setLoading] = useState(false)
  const [prompts, setPrompts] = useState<Prompt[]>([])
  const [order, setOrder] = useState(prompts.map((p) => p.id))

  useEffect(() => {
    setOrder(prompts.map((p) => p.id))
  }, [prompts])

  const fetchPrompts = useCallback(() => {
    if (id) {
      dispatch(getPrompts(id, true))
        .toPromise()
        .then((response) => {
          setPrompts(response)
        })
        .catch((err) => {
          console.log(err)
          dispatch(message(`Error fetching prompts: ${err.message}`, 'error'))
        })
    }
  }, [dispatch, id])

  const saveUpdates = () => {
    const name = newCategoryName.trim()
    if (!name) {
      dispatch(message('Category name cannot be empty'))
      return
    }

    setLoading(true)
    dispatch(savePromptCategory(name, selectedType, visible, id))
      .toPromise()
      .then(() => {
        dispatch(message('Category updated!'))
      })
      .catch((err) => {
        console.log(err)
        dispatch(message(`Error: ${err.message}`, 'error'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addUpdatePrompt = React.useCallback((text: string, categoryId: string, id?: string) => {
    dispatch(savePrompt(text, categoryId, id))
      .toPromise()
      .then(() => {
        dispatch(message(`Prompt ${id ? 'updated' : 'created'}!`))
        setNewPrompt('')
      })
      .catch((err) => {
        console.log(err)
        dispatch(message(`Error: ${err.message}`, 'error'))
      })
      .finally(() => {
        fetchPrompts()
      })
  }, [dispatch, fetchPrompts])

  const handleAddPrompt = () => {
    const text = newPrompt.trim()
    if (!text) {
      dispatch(message('Prompt text cannot be empty'))
      return
    }

    if (category) {
      addUpdatePrompt(text, category.id)
    }
  }

  React.useEffect(() => {
    fetchPrompts()
  }, [fetchPrompts])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddPrompt()
    }
  }

  const onUpdatePrompt = (id: string, text: string) => {
    if (category?.id) {
      addUpdatePrompt(text, category.id, id)
    }
  }

  const onDeletePrompt = (id: string) => {
    dispatch(deletePrompt(id))
      .toPromise()
      .then(() => {
        dispatch(message('Prompt deleted!'))
        setPrompts(prompts.filter((p) => p.id !== id))
      })
      .catch((err) => {
        console.log(err)
        dispatch(message(`Error deleting prompt: ${err.message}`, 'error'))
      })
  }

  const back = () => {
    navigate('/admin/writer')
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      dispatch(reorderPrompts('prompt', reordered.map(id => parseInt(id, 10))))
        .toPromise()
        .then(() => {
          dispatch(message('Prompts reordered!'))
        })
        .catch((e) => {
          console.log('[error]', e)
          dispatch(message(`Reordering error: ${e.message}`))
        })
    }
  }

  if (!category) {
    return <h2>Category not found!</h2>
  }

  return (
    <div className={styles['category-details-container']}>
      <div><Button className={styles['back-button']} onClick={back}>Back</Button></div>
      <h3 className={styles.subtitle}>Category Name</h3>
      <div className={styles.row}>
        <input
          type="text"
          value={newCategoryName}
          placeholder="Category Name"
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <PPSwitch
          selectedValue={selectedType}
          className={styles.switch}
          options={[{
            value: 'text',
            label: 'Text'
          }, {
            value: 'image',
            label: 'Image'
          }, {
            value: 'video',
            label: 'Video'
          }, {
            value: 'story',
            label: 'Story'
          }, {
            value: 'reel',
            label: 'Reel'
          }]}
          onSelectedValueChange={setSelectedType as any}
        />
        <FormControlLabel
          label="Visible"
          labelPlacement="end"
          classes={{ root: styles.cbRoot }}
          control={(
            <Checkbox
              checked={visible}
              color="primary"
              onChange={() => setVisible(current => !current)}
            />
          )}
        />
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={saveUpdates}
          className={styles['save-button']}
        >
          Save
        </Button>
      </div>
      <h3 className={styles.subtitle}>Prompts</h3>
      <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]} layoutMeasuring={{ strategy: MeasuringStrategy.Always }}>
        <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
          <ul ref={setDroppableNodeRef}>
            {prompts
              .sort((p1, p2) => order.indexOf(p1.id) - order.indexOf(p2.id))
              .map((prompt) => (
                <PromptListItem
                  key={prompt.id}
                  prompt={prompt}
                  onUpdate={onUpdatePrompt}
                  onDelete={onDeletePrompt}
                />
              ))}
          </ul>
        </SortableContext>
      </DndContext>
      <h3 className={styles.subtitle}>Add New Prompt</h3>
      <div className={styles['create-category']}>
        <input
          type="text"
          placeholder="New Prompt text..."
          value={newPrompt}
          onChange={(e) => setNewPrompt(e.target.value)}
          onKeyDown={onKeyDown}
        />
        <Button variant="contained" color="primary" onClick={handleAddPrompt}>Add</Button>
      </div>
    </div>
  )
}

interface PromptListItemProps {
  prompt: Prompt
  onUpdate: (id: string, text: string) => void
  onDelete: (id: string) => void
}
function PromptListItem({ prompt, onUpdate, onDelete }: PromptListItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: prompt.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '2' : '1',
    transition,
    position: isDragging ? 'relative' : 'static',
    cursor: isDragging ? 'grabbing' : 'grab'
  } as any

  const [text, setText] = useState(prompt.text)

  const onPromptTextChange = (e: any) => {
    setText(e.target.value)
  }

  const handleDelete = () => {
    onDelete(prompt.id)
  }

  const handleUpdate = () => {
    onUpdate(prompt.id, text)
  }

  return (
    <li key={prompt.id} className={styles.row} ref={setNodeRef} style={style}>
      <div className={styles.handle} {...listeners} {...attributes}>
        <Icon path={mdiDragVertical} size="24px" color="#333" />
      </div>
      <textarea
        value={text}
        placeholder="Prompt Text"
        onChange={onPromptTextChange}
      />
      <Button variant="contained" color="primary" onClick={handleUpdate}>
        Update
      </Button>
      <Button onClick={handleDelete} className={styles['delete-button']}>
        Delete
      </Button>
    </li>
  )
}
