import { HomeContentBlock } from 'admin/interfaces'
import { Observable } from 'rxjs/Observable'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { sortByKeyAscending } from 'utils/sort/order'
import { V1 } from './net'
import { createAction } from '@reduxjs/toolkit'

export const contentBlocksFetched = createAction<any>('content/blocksFetched')

export function getHomeContentBlocks(fromCache?: boolean): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getHomeContentBlocks(fromCache))
    .map(blocks => {
      const data = blocks.sort(sortByKeyAscending('order')).map((b: any) => ({
        ...b,
        contentPanelId: b.contentPanelId?.toString(),
        active: Boolean(b.active),
        id: b.id.toString()
      }))

      dispatch(contentBlocksFetched(data))
      return data
    })
}

export function createHomeBlock(name: string, key: string, order: number): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.createBlock(name, key, order))
}

export function reorderHomeBlocks(order: string[]): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.reorderHomeBlocks(order))
}

export function updateHomeBlock(data: HomeContentBlock): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.updateBlock(data))
}

export function deleteHomeBlock(id: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.deleteBlock(id))
}
