import { V1 } from './net'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/throw'

export const ACTION_ADMIN_LOGIN_SUCCESS = 'ACTION_ADMIN_LOGIN_SUCCESS'
export const ACTION_ADMIN_LOGIN_FAILURE = 'ACTION_ADMIN_LOGIN_FAILURE'
export const ACTION_ADMIN_LOGOUT = 'ACTION_ADMIN_LOGOUT'

export function adminLogin(username: string, password: string): StoreThunkAction<any> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.login(username, password))
      .map(response => {
        if (response.status === 'ok') {
          return Observable.of(response)
        }

        throw Observable.throw(response)
      })
      .do({
        next: (response: any) => dispatch({ type: ACTION_ADMIN_LOGIN_SUCCESS, payload: response }),
        error: (error: any) => dispatch({ type: ACTION_ADMIN_LOGIN_FAILURE, payload: error })
      })
  }
}

export function adminLogout() {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch({ type: ACTION_ADMIN_LOGOUT })
  }
}
