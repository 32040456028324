import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { StreamGroup } from 'admin/interfaces'

export const ENDPOINT_GET_STREAM_GROUPS = `${CONTENT_SERVICE_URL}/stream_groups`
export const ENDPOINT_UPDATE_STREAM_GROUPS = `${CONTENT_SERVICE_URL}/stream_groups/:id`
export const ENDPOINT_REMOVE_STREAM_FROM_GROUP = `${CONTENT_SERVICE_URL}/stream_groups/:id/streams`
export const ENDPOINT_ADD_STREAM_TO_GROUP = `${CONTENT_SERVICE_URL}/stream_groups/:id/streams`
export const ENDPOINT_DELETE_GROUP = `${CONTENT_SERVICE_URL}/stream_groups/:id`
export const ENDPOINT_REORDER_STREAM_GROUPS = `${CONTENT_SERVICE_URL}/content_panel/:panelId/reorder`
export const ENDPOINT_SEARCH_STREAMS = `${CONTENT_SERVICE_URL}/search/algolia`
export const ENDPOINT_CREATE_GROUP = `${CONTENT_SERVICE_URL}/stream_groups`

export const V1 = Object.freeze({
  getStreamGroups: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_STREAM_GROUPS).asGet().expectJSON()
    return function (admin?: boolean) {
      if (admin) {
        builder.param('query_from_admin', 'true')
      }
      return authorizedPipe<StreamGroup[]>(
        builder.build()
      )
    }
  }(),
  updateStreamGroup: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_STREAM_GROUPS).asPatch().expectJSON().asJSON()
    return function (streamGroup: Partial<StreamGroup>) {
      const data = { name: streamGroup.name, active: streamGroup.active ? 1 : 0 }

      return authorizedPipe<any>(
        builder
          .partial(':id', streamGroup.id as string)
          .body(data)
          .build()
      )
    }
  }(),
  streamRemove: function () {
    const builder = new RequestBuilder(ENDPOINT_REMOVE_STREAM_FROM_GROUP).asDelete().expectJSON().asJSON()
    return function (groupId: string, streamId: string) {
      const data = { stream_id: streamId }
      return authorizedPipe<any>(
        builder
          .partial(':id', groupId)
          .body(data)
          .build()
      )
    }
  }(),
  streamAdd: function () {
    const builder = new RequestBuilder(ENDPOINT_ADD_STREAM_TO_GROUP).asPost().expectJSON().asJSON()
    return function (groupId: string, streamId: string) {
      const data = { stream_id: streamId }
      return authorizedPipe<any>(
        builder
          .partial(':id', groupId)
          .body(data)
          .build()
      )
    }
  }(),
  createGroup: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_GROUP).asPut().expectJSON().asJSON()
    return function (name: string) {
      const data = { name }
      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  deleteGroup: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_GROUP).asDelete().expectJSON().asJSON()
    return function (groupId: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', groupId)
          .build()
      )
    }
  }(),
  reorderStreamGroups: function () {
    const builder = new RequestBuilder(ENDPOINT_REORDER_STREAM_GROUPS).asPatch().expectJSON().asJSON()
    return function (panelId: string, order: string[]) {
      return authorizedPipe<any>(
        builder
          .partial(':panelId', panelId)
          .body({ ids: order })
          .build()
      )
    }
  }()
})
