import * as React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Fab from '@mui/material/Fab'
import SaveIcon from '@mui/icons-material/Check'
import UndoIcon from '@mui/icons-material/Undo'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip from '@mui/material/Tooltip'
import { Stream } from 'interfaces'
import { StreamGroup } from 'admin/interfaces'
import ConfirmDialog from 'components/ConfirmDialog'
import Chip from '@mui/material/Chip'
import styles from '../StreamGroupsManager.pcss'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import CircularProgress from '@mui/material/CircularProgress'
import Input from '@mui/material/Input'
import { useDispatch } from 'react-redux'
import { search } from 'services/search/actions'
import { streamAdd } from 'admin/services/stream_groups/actions'
import { StoreThunkDispatch } from 'store/state'
import Checkbox from '@mui/material/Checkbox'
import { message } from 'services/snackbar'

const LOADER_SIZE = 24
const RADIX_TEN = 10
const SEARCH_PAGE_SIZE = 100
export interface StreamGroupsTableRowProps {
  // onClearSearch: () => void
  group: StreamGroup
  index: number
  deleteConfirmationMessage?: string
  onUpdate: (group: Partial<StreamGroup>, order?: number) => void
  onDeleteGroup: (groupId: string) => void
  onStreamRemove: (groupId: string, streamId: string) => void
}

export function StreamGroupsTableRow(props: StreamGroupsTableRowProps) {
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [data, setData] = React.useState<StreamGroup>(props.group)
  const [edited, setEdited] = React.useState(false)
  const nameRef = React.useRef<HTMLDivElement>(null)
  const orderRef = React.useRef<HTMLDivElement>(null)
  const [searchQuery, setSearchQuery] = React.useState('')
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [streamsSearchResult, setStreamsSearchResult] = React.useState<Stream[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [loading, setLoading] = React.useState(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement
    setSearchQuery(input.value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      setLoading(true)
      setStreamsSearchResult([])
      setAnchorEl(e.currentTarget)
      let streamId: undefined | number = parseInt(searchQuery, RADIX_TEN)
      if (Number.isNaN(streamId)) {
        streamId = undefined
      }
      dispatch(search(searchQuery, ['streams'], 0, SEARCH_PAGE_SIZE, undefined, streamId?.toString())).subscribe(val => {
        setStreamsSearchResult(val.streams)
        setLoading(false)
      }, (e) => {
        console.log('search streams error: ', e)
        setLoading(false)
      })
    }
  }

  React.useEffect(() => {
    setEdited(false)
    setData(props.group)
  }, [props.group])

  const undoChanges = () => {
    setEdited(false)
    setData(props.group)
    setRefreshKey(current => current + 1)
  }

  const handleInput = () => {
    setEdited(true)
  }

  const handleStreamRemove = (groupId: string, streamId: string) => {
    props.onStreamRemove(groupId, streamId)
  }

  const toggleActive = () => {
    setEdited(true)
    setData(current => ({ ...current, active: !current.active }))
  }

  const saveChanges = () => {
    const order = parseInt(orderRef.current?.innerText + '', 10)

    const draft: Partial<StreamGroup> = {
      id: props.group.id,
      streams: props.group.streams,
      name: nameRef.current?.innerText.trim() as string,
      order: props.group.order,
      active: data.active
    }
    const updateOrder = !Number.isNaN(order) && order !== props.group.order
    const nextOrder = updateOrder ? order : undefined
    props.onUpdate(draft, nextOrder)
  }

  const deleteGroup = () => {
    props.onDeleteGroup(props.group.id)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const streamChosen = (stream: Stream) => () => {
    handleClose()
    const streamId = stream.originalId || stream.id
    const streamExists = props.group.streams.find(s => {
      const id = s.originalId || s.id
      return id === streamId
    })

    if (streamExists) {
      dispatch(message('Stream already exists'))
      return
    }

    dispatch(streamAdd(props.group.id, stream.id)).subscribe(val => {
      data.streams.push(Object.assign(val, { feedsCount: stream.feedsCount }))
      setStreamsSearchResult([])
    })
  }

  return (
    <TableRow
      className={styles.tr}
      key={refreshKey}
    >
      <TableCell>
        <span>ID: {data.id}</span>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={nameRef}
          data-key="name"
          className={styles['cell-textarea']}
          onInput={handleInput}
        >
          {data.name}
        </div>
      </TableCell>
      <TableCell>
        <div className={styles['cell-textarea']}>
          {data.streams.map(s => (
            <StreamChip
              key={s.id}
              stream={s}
              groupId={data.id}
              onDelete={handleStreamRemove}
            />
          ))}
        </div>
        <Input
          type="text"
          classes={{ input: styles.input }}
          className={styles['input-control']}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
          placeholder="Search for streams"
          name="search"
          value={searchQuery}
        />
        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {
            streamsSearchResult.map((s) => (
              <MenuItem
                key={s.id}
                onClick={streamChosen(s)}
                value={s.id}
                className={styles['menu-item']}
              >
                <span>{`${s.title}${s.feedsCount ? ' (' + s.feedsCount.total + ')' : ''}`}</span>
              </MenuItem>
            ))
          }
          {!loading && streamsSearchResult.length === 0 && (
            <MenuItem key="empty" className={styles['menu-item']}>
              No results found :(
            </MenuItem>
          )}
          {loading && (
            <MenuItem key="loader" className={styles['menu-item']}>
              <CircularProgress size={LOADER_SIZE} className={styles.loader} />
            </MenuItem>
          )}
        </Menu>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          data-key="order"
          ref={orderRef}
          className={styles['cell-textarea']}
          onInput={handleInput}
        >
          {data.order}
        </div>
      </TableCell>
      <TableCell>
        <Checkbox color="primary" checked={data.active} onChange={toggleActive} />
      </TableCell>
      <TableCell className={styles['cell-actions']}>
        <Tooltip title="Save" placement="top">
          <span>
            <Fab size="small" color="primary" disabled={!edited} onClick={saveChanges}>
              <SaveIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title="Undo" placement="top">
          <span>
            <Fab size="small" disabled={!edited} onClick={undoChanges}>
              <UndoIcon fontSize="small" />
            </Fab>
          </span>
        </Tooltip>
        <ConfirmDialog message="Are you sure?">
          {(confirm) => (
            <Tooltip title="Delete group" placement="top">
              <span>
                <Fab size="small" onClick={confirm(deleteGroup)}>
                  <DeleteIcon fontSize="small" />
                </Fab>
              </span>
            </Tooltip>
          )}
        </ConfirmDialog>
      </TableCell>
    </TableRow>
  )
}

function StreamChip(props: { groupId: string, stream: Stream, onDelete: (groupId: string, streamId: string) => void }) {
  const onDelete = () => { props.onDelete(props.groupId, props.stream.id) }
  const feedsCount = props.stream.feedsCount !== undefined ? props.stream.feedsCount.total : 'unknown'

  return (
    <Chip
      size="small"
      label={`${props.stream.title} (${feedsCount}) ${props.stream.id}`}
      className={styles.chip}
      onDelete={onDelete}
    />
  )
}

export default StreamGroupsTableRow
