import * as React from 'react'
import Logo from 'components/PPLogo'
import { Link } from 'react-router-dom'

import ToAppIcon from '@mui/icons-material/ExitToApp'
import Tooltip from '@mui/material/Tooltip'

import styles from './AdminHeader.pcss'

export function AdminHeader(props: { className?: string }) {
  return (
    <header className={`${styles.header} ${props.className || ''}`}>
      <Link to="/admin/dashboard">
        <Logo key="logo" className={styles.logo} />
      </Link>
      <h1 className={styles.title}>Post Planner Admin Panel</h1>
      <Link to="/" target="_blank" className={styles['btn-to-app']}>
        <Tooltip title="To the App" placement="left">
          <ToAppIcon />
        </Tooltip>
      </Link>
    </header>
  )
}

export default AdminHeader
