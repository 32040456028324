import { ADMIN_SERVICE_URL } from 'config'
import { authorizedPipe, RequestBuilder } from 'services/net'

const ENDPOINT_SEARCH_APPS = `${ADMIN_SERVICE_URL}/app/search`
const ENDPOINT_UPDATE_APP = `${ADMIN_SERVICE_URL}/app/update`

export function searchApps(id: string) {
  const builder = new RequestBuilder(ENDPOINT_SEARCH_APPS).asPost().expectJSON()
  return authorizedPipe<any>(
    builder
      .body({ needle: id })
      .build()
  )
}

export function updateAppLimits(
  appId: string,
  profilesLimit?: number,
  postsPerDay?: number,
  locations?: number,
  cid?: string,
  isBeta?: '0' | '1'
) {
  const data: any = { id: appId, is_beta: isBeta }
  if (profilesLimit) {
    data.profiles_limit = profilesLimit
  }
  if (postsPerDay) {
    data.posts_per_day = postsPerDay
  }
  if (typeof locations === 'number') {
    data.allowed_locations = locations
  }
  if (typeof cid === 'string') {
    data.chargify_subscription_id = cid
  }
  const builder = new RequestBuilder(ENDPOINT_UPDATE_APP).asPost().expectJSON()
  return authorizedPipe<any>(
    builder
      .body(data)
      .build()
  )
}
