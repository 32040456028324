import * as React from 'react'
import Chip from '@mui/material/Chip'
import AdminPage from 'admin/components/AdminPage'
import { NavLink } from 'react-router-dom'
import styles from './QuoteTagsManager.pcss'
import { Subject } from 'rxjs/Subject'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { V1 } from 'admin/services/quotes/net'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'

export function QuoteKeywords() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [keywords, setKeywords] = React.useState<Array<{ id: number, name: string }>>([])

  const fetch$ = React.useRef<Subject<void>>()
  const create$ = React.useRef<Subject<string>>()
  const delete$ = React.useRef<Subject<number>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .flatMap(() => {
        return dispatch(V1.getKeywords()).pipe(catchError(() => {
          dispatch(message('Could not fetch data! Please refresh.'))
          return Observable.of([])
        }))
      })
      .subscribe(response => {
        setKeywords(response)
      })

    fetch$.current.next()

    return () => {
      fetch$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    create$.current = new Subject()
    create$.current
      .flatMap((value: string) => {
        return dispatch(V1.addKeyword(value))
          .pipe(
            catchError((e: Error) => {
              dispatch(message('An error occurred: ' + e.message))
              return Observable.of({ error: true, value })
            })
          )
      })
      .flatMap(response => {
        if (response.error) {
          return Observable.of(response)
        }
        return dispatch(V1.getKeywords()).map(response => ({ keywords: response }))
      })
      .subscribe(response => {
        if (response.error && response.value) {
          setKeywords(current => current.filter(kw => kw.name !== response.value))
          return
        }

        if (response.keywords) {
          setKeywords(response.keywords)
        }
        dispatch(message('Keyword added!'))
      })

    return () => {
      create$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .flatMap((id: number) => {
        return dispatch(V1.deleteKeyword(id)).pipe(
          catchError((e: Error) => {
            dispatch(message('An error occurred: ' + e.message))
            return Observable.of({ error: true, id })
          })
        )
      })
      .subscribe(response => {
        const id = parseInt(response.id, 10) // eslint-disable-line no-magic-numbers
        dispatch(message('Keyword deleted!'))
        setKeywords(current => current.filter(kw => kw.id !== id))
      })

    return () => {
      delete$.current?.unsubscribe()
    }
  }, [])

  const onDelete = (id: number) => {
    delete$.current?.next(id)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value.toLowerCase().trim()
      if (!value) {
        return
      }

      if (keywords.find(kw => kw.name === value)) {
        dispatch(message('Keyword already exists!'))
        return
      }

      create$.current?.next(value)
      ; (e.target as HTMLInputElement).value = ''
    }
  }

  return (
    <AdminPage title="Quote Keywords">
      <NavLink to="/admin/quotes">Back to Quotes</NavLink>
      <div className={styles['section-add']}>
        <input type="text" placeholder="Add new keyword" onKeyDown={onKeyDown} className={styles['tag-input']} />
      </div>
      <div className={styles.grid}>
        {keywords.map(kw => (
          <KeywordChip key={kw.id} keyword={kw} onDelete={onDelete} />
        ))}
      </div>
    </AdminPage>
  )
}

const KeywordChip = function (props: { keyword: { id: number, name: string }, onDelete: (id: number) => void }) {
  const onDelete = () => {
    props.onDelete(props.keyword.id)
  }

  return (
    <Chip
      size="small"
      label={props.keyword.name}
      className={styles.chip}
      onDelete={onDelete}
    />
  )
}

export default QuoteKeywords
