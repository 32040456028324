import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL, LEGACY_URL } from 'config'
import { Observable } from 'rxjs/Observable'
import { TrainingVideo } from 'admin/interfaces'

const ENDPOINT_GET_SECTIONS_ADMIN = `${LEGACY_URL}/administration/FE_sections/get_sections`
const ENDPOINT_GET_VIDEOS_ADMIN = `${LEGACY_URL}/administration/FE_sections/get_items`
const ENDPOINT_GET_SECTIONS = `${LEGACY_URL}/sections/get_sections`
const ENDPOINT_GET_VIDEOS = `${LEGACY_URL}/sections/get_items`
const ENDPOINT_SAVE_SECTION = `${LEGACY_URL}/administration/FE_sections/save_section`
const ENDPOINT_DELETE_SECTION = `${LEGACY_URL}/administration/FE_sections/delete_section/:id`
const ENDPOINT_DELETE_VIDEO = `${LEGACY_URL}/administration/FE_sections/delete_item/:id`
const ENDPOINT_SAVE_VIDEO = `${LEGACY_URL}/administration/FE_sections/save_item`

export function getVideoSections(admin?: boolean) {
  const endpoint = admin ? ENDPOINT_GET_SECTIONS_ADMIN : ENDPOINT_GET_SECTIONS
  const builder = new RequestBuilder(endpoint).asGet().expectJSON()

  return authorizedPipe<any>(
    builder.build()
  )
}

export function getTrainingVideos(sectionId?: string, admin?: boolean) {
  const endpoint = admin ? ENDPOINT_GET_VIDEOS_ADMIN : ENDPOINT_GET_VIDEOS
  const builder = new RequestBuilder(endpoint).asGet().expectJSON()
  if (sectionId) {
    builder.param('section_id', sectionId)
  }

  return authorizedPipe<any>(
    builder.build()
  )
}

export function saveSection(section: { title: string, slug: string, label: string }, id?: string, order? :string) {
  const builder = new RequestBuilder(ENDPOINT_SAVE_SECTION).asPost().expectJSON()
  const data: any = { ...section }
  if (id) {
    data.id = id
  }

  if (order) {
    data.order = order
  }

  return authorizedPipe<any>(
    builder.bodyJSONstringify(data).build()
  )
}

export function deleteSection(id: string) {
  const builder = new RequestBuilder(ENDPOINT_DELETE_SECTION).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.partial(':id', id).build()
  )
}

export function deleteVideo(id: string) {
  const builder = new RequestBuilder(ENDPOINT_DELETE_VIDEO).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.partial(':id', id).build()
  )
}

export function saveVideo(title: string, url: string, thumbnail: string, sectionId: string, id?: string, order?: string) {
  const builder = new RequestBuilder(ENDPOINT_SAVE_VIDEO).asPost().expectJSON()
  const data: any = { title, section_id: sectionId, url, thumbnail_url: thumbnail, type: 'video' }
  if (id) {
    data.id = id
  }
  if (order) {
    data.order = order
  }

  return authorizedPipe<any>(
    builder.bodyJSONstringify(data).build()
  )
}

export function reorderVideos(type: 'section' | 'item', ids: number[]) {
  const builder = new RequestBuilder(`${ADMIN_SERVICE_URL}/FE_sections/reorder`).asPost().expectJSON()

  return authorizedPipe<any>(
    builder.body({ ids }).param('type', type).build()
  )
}
