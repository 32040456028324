import React, { useCallback, useEffect, useState } from 'react'
import styles from './SectionList.pcss'
import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import { DndContext, useDroppable, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import { reorder, sortByKeyAscending } from 'shared/utils'
import Icon from '@mdi/react'
import { mdiDragVertical } from '@mdi/js'
import { TrainingVideo, VideoTrainingSection } from 'admin/interfaces'
import { deleteVideo, getTrainingVideos, reorderVideos, saveSection, saveVideo } from 'admin/services/training/net'

interface SectionDetailsProps {
  sections: VideoTrainingSection[]
}

export const SectionDetails: React.FC<SectionDetailsProps> = ({ sections }) => {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const params = useParams()
  const navigate = useNavigate()
  const id = params.sectionId

  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: 'droppable'
  })

  const section = sections.find((s) => s.id === id)
  const [newSectionTitle, setNewSectionTitle] = useState(section?.title || '')
  const [newSectionSlug, setNewSectionSlug] = useState(section?.slug || '')
  const [newSectionLabel, setNewSectionLabel] = useState(section?.label || '')
  const [newVideo, setNewVideo] = useState({ title: '', url: '', thumbnail: '' })
  const [loading, setLoading] = useState(false)
  const [vids, setVids] = useState<TrainingVideo[]>([])
  const [order, setOrder] = useState(vids.map((v) => v.id))

  useEffect(() => {
    setOrder(vids.map((p) => p.id))
  }, [vids])

  const fetchVideos = useCallback(() => {
    if (id) {
      dispatch(getTrainingVideos(id, true))
        .toPromise()
        .then((response) => {
          setVids(response)
        })
        .catch((err) => {
          console.log(err)
          dispatch(message(`Error fetching videos: ${err.message}`, 'error'))
        })
    }
  }, [dispatch, id])

  const saveUpdates = () => {
    const title = newSectionTitle.trim()
    if (!title) {
      dispatch(message('Section title cannot be empty'))
      return
    }

    setLoading(true)
    const updated = { title, slug: newSectionSlug, label: newSectionLabel }
    dispatch(saveSection(updated, id, section?.order?.toString()))
      .toPromise()
      .then(() => {
        dispatch(message('Section updated!'))
      })
      .catch((err) => {
        console.log(err)
        dispatch(message(`Error: ${err.message}`, 'error'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addVideo = React.useCallback((title: string, url: string, thumbnailUrl: string, sectionId: string, order?: string, id?: string) => {
    dispatch(saveVideo(title, url, thumbnailUrl, sectionId, id, order))
      .toPromise()
      .then(() => {
        dispatch(message(`Video ${id ? 'updated' : 'created'}!`))
        setNewVideo({ title: '', url: '', thumbnail: '' })
      })
      .catch((err) => {
        console.log(err)
        dispatch(message(`Error: ${err.message}`, 'error'))
      })
      .finally(fetchVideos)
  }, [dispatch, fetchVideos])

  const handleAddVideo = () => {
    const { title, url, thumbnail } = newVideo
    if (!title || !url || !thumbnail) {
      dispatch(message('Please enter a title, video URL, and thumbnail URL'))
      return
    }

    if (section) {
      addVideo(newVideo.title, newVideo.url, newVideo.thumbnail, section.id, vids.length?.toString())
    }
  }

  React.useEffect(() => {
    fetchVideos()
  }, [fetchVideos])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddVideo()
    }
  }

  const back = () => {
    navigate('/admin/video-training')
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      dispatch(reorderVideos('item', reordered.map(id => parseInt(id, 10))))
        .toPromise()
        .then(() => {
          dispatch(message('Videos reordered!'))
        })
        .catch((e) => {
          console.log('[error]', e)
          dispatch(message(`Reordering error: ${e.message}`))
        })
    }
  }

  if (!section) {
    return <h2>Section not found!</h2>
  }

  const updateVideo = (id: string, text: string, videoUrl: string, thumbnailUrl: string) => {
    dispatch(saveVideo(text, videoUrl, thumbnailUrl, section.id, id)).toPromise()
      .then(() => {
        dispatch(message('Video edited!'))
      })
      .catch((e) => {
        console.log('[error]', e)
        dispatch(message(`Editing error: ${e.message}`))
      })
  }

  const onDeleteVideo = (id: string) => {
    dispatch(deleteVideo(id)).toPromise()
      .then(() => {
        fetchVideos()
        dispatch(message('Video deleted!'))
      })
      .catch((e) => {
        console.log('[error]', e)
        dispatch(message(`Delete error: ${e.message}`))
      })
  }

  return (
    <div className={styles['category-details-container']}>
      <div><Button className={styles['back-button']} onClick={back}>Back</Button></div>
      <h3 className={styles.subtitle}>Section title</h3>
      <div className={`${styles.row} ${styles['row-col']}`}>
        <div>
          <input
            type="text"
            value={newSectionTitle}
            placeholder="Section Name"
            onChange={(e) => setNewSectionTitle(e.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            value={newSectionLabel}
            placeholder="Section Label"
            onChange={(e) => setNewSectionLabel(e.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            value={newSectionSlug}
            placeholder="Section Slug"
            onChange={(e) => setNewSectionSlug(e.target.value)}
          />
        </div>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={saveUpdates}
          className={styles['save-button']}
        >
          Save
        </Button>
      </div>
      <h3 className={styles.subtitle}>Videos</h3>
      <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]} layoutMeasuring={{ strategy: MeasuringStrategy.Always }}>
        <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
          <ul ref={setDroppableNodeRef}>
            {vids
              .sort(sortByKeyAscending('order'))
              .map((vid) => (
                <VideoListItem
                  key={vid.id}
                  data={vid}
                  onUpdate={updateVideo}
                  onDelete={onDeleteVideo}
                />
              ))}
          </ul>
        </SortableContext>
      </DndContext>
      <div className={styles['create-category']}>
        <h3 className={styles.subtitle}>Add New Video</h3>
        <input
          type="text"
          placeholder="Title"
          value={newVideo.title}
          onChange={(e) => setNewVideo(current => ({ ...current, title: e.target.value }))}
          onKeyDown={onKeyDown}
        />
        <input
          type="text"
          placeholder="Video URL"
          value={newVideo.url}
          onChange={(e) => setNewVideo(current => ({ ...current, url: e.target.value }))}
        />
        <input
          type="text"
          placeholder="Thumbnail URL"
          value={newVideo.thumbnail}
          onChange={(e) => setNewVideo(current => ({ ...current, thumbnail: e.target.value }))}
        />
        <Button variant="contained" color="primary" onClick={handleAddVideo}>Add</Button>
      </div>
    </div>
  )
}

interface VideoListItemProps {
  data: TrainingVideo
  onUpdate: (id: string, text: string, videoUrl: string, thumbnailUrl: string) => void
  onDelete: (id: string) => void
}
function VideoListItem({ data, onUpdate, onDelete }: VideoListItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: data.id })
  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? '2' : '1',
    transition,
    position: isDragging ? 'relative' : 'static',
    cursor: isDragging ? 'grabbing' : 'grab'
  } as any

  const [title, setTitle] = useState(data.title)
  const [url, setURL] = useState(data.url)
  const [thumbnailUrl, setThumbnailUrl] = useState(data.thumbnailUrl)

  const onTitleTextChange = (e: any) => {
    setTitle(e.target.value)
  }

  const onURLTextChange = (e: any) => {
    setURL(e.target.value)
  }

  const onThumbnailTextChange = (e: any) => {
    setThumbnailUrl(e.target.value)
  }

  const handleDelete = () => {
    onDelete(data.id)
  }

  const handleUpdate = () => {
    onUpdate(data.id, title, url, thumbnailUrl)
  }

  return (
    <li key={data.id} className={styles.row} ref={setNodeRef} style={style}>
      <div className={styles.handle} {...listeners} {...attributes}>
        <Icon path={mdiDragVertical} size="24px" color="#333" />
      </div>
      <div className={styles['li-content']}>
        <div className={styles.label}>Video title</div>
        <textarea
          value={title}
          placeholder="Video title"
          onChange={onTitleTextChange}
        />
        <div className={styles.label}>Video URL</div>
        <input placeholder="Video URL" className={styles.input} value={url} onChange={onURLTextChange} />
        <div className={styles.label}>Thumbnail URL</div>
        <input placeholder="Thumbnail URL" className={styles.input} value={thumbnailUrl} onChange={onThumbnailTextChange} />
        <img src={thumbnailUrl} alt={title} className={styles.thumb} />
      </div>
      <Button variant="contained" color="primary" onClick={handleUpdate}>
        Update
      </Button>
      <Button onClick={handleDelete} className={styles['delete-button']}>
        Delete
      </Button>
    </li>
  )
}
