import * as React from 'react'
import { HomeContentBlock as IHomeContentBlock } from 'admin/interfaces'
import Paper from '@mui/material/Paper'
import DragHandleIcon from '@mui/icons-material/DragIndicator'
import DeleteIcon from '@mui/icons-material/Close'
import styles from './HomeContentBlock.pcss'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import EditableLabel from 'components/EditableLabel'
import ConfirmDialog from 'components/ConfirmDialog'

interface HomeContentBlockProps {
  block: IHomeContentBlock
  className?: string
  innerRef?: (element: any) => any
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  draggableProps?: any
  onUpdate: (data: IHomeContentBlock) => void
  onDelete: (id: string) => void
}

export function HomeContentBlock(props: HomeContentBlockProps) {
  const [editKey, setEditKey] = React.useState(0)
  const toggleActive = () => {
    props.onUpdate({ ...props.block, active: !props.block.active })
  }

  const onNameChange = (value: string | undefined) => {
    const newName = value?.trim()
    if (newName && newName !== props.block.name) {
      props.onUpdate({ ...props.block, name: newName })
    }
  }

  const onPanelIdChange = (value: string | undefined) => {
    const id = value?.trim()
    if (id !== undefined && id !== props.block.contentPanelId) {
      // Allow empty string, but stop other values that are not numbers
      if (id.length > 0 && Number.isNaN(parseInt(id, 10))) { // eslint-disable-line no-magic-numbers
        alert('Value must be a number')
        return
      }
      props.onUpdate({ ...props.block, contentPanelId: id })
    }
  }

  const onKeyChange = (value: string | undefined) => {
    const key = value?.trim()
    if (key && key !== props.block.key) {
      const confirmed = confirm('Are you sure you want to change the key? Make sure this change is reflected in the app, too!')
      if (confirmed) {
        props.onUpdate({ ...props.block, key })
      } else {
        setEditKey(current => current + 1)
      }
    }
  }

  const onGroupIdChange = (value: string) => {
    const id = value?.trim()
    if (id !== undefined && id !== props.block.groupId) {
      props.onUpdate({ ...props.block, groupId: id })
    }
  }

  const onDelete = () => {
    props.onDelete(props.block.id)
  }

  return (
    <Paper className={`${styles.paper} ${props.className || ''}`} ref={props.innerRef} {...props.draggableProps}>
      <div className={styles.container}>
        <div className={styles['cell-order']}>
          <span {...props.dragHandleProps}>
            <DragHandleIcon className={styles['drag-handle']} />
          </span>
          {props.block.order}
        </div>
        <div className={styles['cell-l']}>
          <EditableLabel
            value={props.block.name}
            editMode
            className={styles['editable-label']}
            onChange={onNameChange}
          />
        </div>
        <div className={styles['cell-key']}>
          <EditableLabel
            key={editKey}
            value={props.block.key}
            editMode
            className={styles['editable-label']}
            onChange={onKeyChange}
          />
        </div>
        <div className={styles['cell-s']}>
          <Checkbox
            size="small"
            color="primary"
            checked={props.block.active}
            onChange={toggleActive}
          />
        </div>
        <div className={styles['cell-s']}>
          <EditableLabel
            value={props.block.contentPanelId || ''}
            editMode
            allowEmpty
            className={styles['editable-label']}
            onChange={onPanelIdChange}
          />
        </div>
        <div className={styles['cell-s']}>
          <EditableLabel
            value={props.block.groupId || ''}
            editMode
            allowEmpty
            className={styles['editable-label']}
            onChange={onGroupIdChange}
          />
        </div>
        <div className={styles['cell-s']}>
          <ConfirmDialog message="Delete block?">
            {(confirm) => (
              <Tooltip title="Delete" placement="top">
                <IconButton size="small" onClick={confirm(onDelete)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </ConfirmDialog>
        </div>
      </div>
    </Paper>
  )
}

export function HomeContentBlockTableHeader() {
  return (
    <div className={`${styles.container} ${styles.header}`}>
      <div className={styles['cell-order']}>Order</div>
      <div className={styles['cell-l']}>Name</div>
      <div className={styles['cell-key']}>Key</div>
      <div className={styles['cell-s']}>Active</div>
      <div className={styles['cell-s']}>Content panel</div>
      <div className={styles['cell-s']}>Stream Group</div>
      <div className={styles['cell-s']}>Actions</div>
    </div>
  )
}
