import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL } from 'config'

export const ENDPOINT_REFRESH_FB_TOKENS = `${ADMIN_SERVICE_URL}/users/clear_tokens/:id`
export const ENDPOINT_TOGGLE_USER_BLOCKED = `${ADMIN_SERVICE_URL}/users/block_unblock_user/:id`
export const ENDPOINT_GET_INTERCOM_DATA = `${ADMIN_SERVICE_URL}/users/intercom_data/:id`
export const ENDPOINT_GET_USER_DATA = `${ADMIN_SERVICE_URL}/users/preview_user_data/:id`
export const ENDPOINT_RESET_PASSWORD = `${ADMIN_SERVICE_URL}/users/reset_password`
export const ENDPOINT_RESTART_TRIAL = `${ADMIN_SERVICE_URL}/app/reset_trial`
export const ENDPOINT_MAKE_ADMIN = `${ADMIN_SERVICE_URL}/team/set_master_admin`
const ENDPOINT_DELETE_USER_DATA = `${ADMIN_SERVICE_URL}/users/delete_user_data/:id`

export const V1 = Object.freeze({
  clearFBTokens: function () {
    const builder = new RequestBuilder(ENDPOINT_REFRESH_FB_TOKENS).asPost().asFormUrlEncoded().expectJSON()

    return function (id: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .build()
      )
    }
  }(),
  toggleUserBlocked: function () {
    const builder = new RequestBuilder(ENDPOINT_TOGGLE_USER_BLOCKED).asPost().asFormUrlEncoded().expectJSON()

    return function (id: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .build()
      )
    }
  }(),
  getIntercomData: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_INTERCOM_DATA).asPost().asFormUrlEncoded().expectJSON()

    return function (userId: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', userId)
          .build()
      )
    }
  }(),
  getUserData: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_USER_DATA).asPost().asFormUrlEncoded().expectJSON()

    return function (userId: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', userId)
          .build()
      )
    }
  }(),
  resetPassword: function () {
    const builder = new RequestBuilder(ENDPOINT_RESET_PASSWORD).asPost().expectJSON()

    return function (userId: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ user_id: userId })
          .build()
      )
    }
  }(),
  restartTrial: function () {
    const builder = new RequestBuilder(ENDPOINT_RESTART_TRIAL).asPost().expectJSON()

    return function (appId: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ app_id: appId })
          .build()
      )
    }
  }(),
  makeAdmin: function () {
    const builder = new RequestBuilder(ENDPOINT_MAKE_ADMIN).asPost().expectJSON()

    return function (userId: string) {
      return authorizedPipe<any>(
        builder
          .param('user_id', userId)
          .build()
      )
    }
  }(),
  clearUserData: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_USER_DATA).asPost().expectJSON()

    return function (userId: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', userId)
          .build()
      )
    }
  }()
})
