import { streamAdapter } from 'services/content/adapters/streams'
import { ContentPanel } from 'admin/interfaces'
import { Observable } from 'rxjs/Observable'
import { tap } from 'rxjs/operators/tap'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import slugify from 'utils/format/slugify'
import { sortByKeyAscending } from 'utils/sort/order'
import { V1 } from './net'
import { createAction } from '@reduxjs/toolkit'

export const contentPanelsFetched = createAction<any>('content/panelsFetched')

export function getContentPanels(fromCache?: boolean): StoreThunkAction<Observable<ContentPanel[]>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getContentPanels(fromCache))
    .map(response => {
      const data = response.map((panel: any) => ({
        ...panel,
        id: panel.id.toString(),
        active: Boolean(panel.active),
        streamGroups: panel.streamGroups.map((group: any) => ({
          ...group,
          id: group.id.toString(),
          active: Boolean(group.active),
          slug: slugify(group.name),
          streams: group.streams.map((s: any) => streamAdapter(s))
        })).sort(sortByKeyAscending('order'))
      }))

      dispatch(contentPanelsFetched(data))
      return data
    })
}

export function updateContentPanel(id: string, name: string, active: boolean): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.updatePanel(id, name, active))
}

export function deleteContentPanel(id: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.deletePanel(id))
}

export function createContentPanel(name: string): StoreThunkAction<Observable<any>> {
  return dispatch => dispatch(V1.createPanel(name))
}

export function addStreamGroupToPanel(panelId: string, groupId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.addGroup(panelId, groupId)).pipe(tap((response: any) => {
      if (response.error) {
        throw new Error(response.error)
      }
    }))
  }
}
