import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import IconAdd from '@mui/icons-material/Add'
import IconDelete from '@mui/icons-material/Close'
import { IndexedObject } from 'interfaces'
import styles from './CheckoutUIRoute.pcss'

export function StylesTable(props: {
  styles: IndexedObject<string>,
  title: string,
  onAddOrUpdateStyle: (rule: string, value: string) => void,
  onRemoveStyle: (rule: string) => void
}) {
  const [newRuleName, setNewRuleName] = React.useState('')
  const [newRuleValue, setNewRuleValue] = React.useState('')

  const onUpdateRule = (rule: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    props.onAddOrUpdateStyle(rule, value)
  }

  const onNewRuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setNewRuleName(value)
  }

  const onNewRuleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setNewRuleValue(value)
  }

  const removeRule = (rule: string) => () => {
    props.onRemoveStyle(rule)
  }

  const addNewRule = () => {
    if (!newRuleName || !newRuleValue) {
      alert('Missing CSS property or value!')
      return
    }
    props.onAddOrUpdateStyle(newRuleName, newRuleValue)
    setNewRuleName('')
    setNewRuleValue('')
  }

  return (
    <div className={styles['css-table']}>
      <h5>{props.title}</h5>
      {Object.keys(props.styles).map(rule => (
        <div className={styles['css-row']}>
          <span className={styles['css-label']}>{rule}</span>
          <input
            type="text"
            value={props.styles[rule]}
            placeholder="CSS Value"
            onChange={onUpdateRule(rule)}
          />
          <IconButton size="small" onClick={removeRule(rule)}>
            <IconDelete />
          </IconButton>
        </div>
      ))}
      <div className={styles['css-row']}>
        <input
          type="text"
          value={newRuleName}
          placeholder="CSS Property"
          onChange={onNewRuleNameChange}
        />
        <input
          type="text"
          value={newRuleValue}
          placeholder="CSS Value"
          onChange={onNewRuleValueChange}
        />
        <IconButton size="small" onClick={addNewRule}>
          <IconAdd />
        </IconButton>
      </div>
    </div>
  )
}
