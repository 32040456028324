import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from './routes/login'
import { AuthenticatedRoute } from 'components/AuthenticatedRoute'
import AdminRoot, { ADMIN_PATHNAME } from './components/AdminRoot'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { theme } from 'theme/theme-md'
import styles from './AdminApp.pcss'
import ConnectedSnackbar from 'components/ConnectedSnackbar'

export class AdminApp extends React.Component<any, any> {
  componentDidMount() {
    const linkElement = document.createElement('link')
    linkElement.id = 'link-favicon'
    linkElement.rel = 'shortcut icon'
    linkElement.type = 'image/png'
    linkElement.href = '/static/img/favicon-admin.png'
    document.head.appendChild(linkElement)
  }

  componentWillUnmount() {
    document.getElementById('link-favicon')?.remove()
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className={styles['admin-panel']}>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route
                path="/*"
                element={(
                  <AuthenticatedRoute admin redirect="/admin/login">
                    <AdminRoot />
                  </AuthenticatedRoute>
                )}
                errorElement={<Login />}
              />
            </Routes>
            <ConnectedSnackbar />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}

export default AdminApp
