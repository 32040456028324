import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL } from 'config'

export const ENDPOINT_SEARCH_USERS = `${ADMIN_SERVICE_URL}/users/search`
export const ENDPOINT_IMPERSONATE_USER = `${ADMIN_SERVICE_URL}/users/impose/:userId`

export const V1 = Object.freeze({
  searchUsers: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_USERS).asPost().asFormUrlEncoded().expectJSON().withCredentials()

    return function (query: string) {
      return authorizedPipe<any>(
        builder
          .body({ needle: query })
          .build()
      )
    }
  }(),
  impersonate: function () {
    const builder = new RequestBuilder(ENDPOINT_IMPERSONATE_USER).asPost().asFormUrlEncoded().expectJSON()

    return function (userId: string) {
      return authorizedPipe<any>(
        builder
          .partial(':userId', userId)
          .build()
      )
    }
  }()
})
