import { quoteToStatusIdea } from 'adapters'
import { StatusIdea } from 'interfaces'
import { Observable } from 'rxjs/Observable'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { V1 } from './net'

export function getQuotes(page: number, query: string, tag?: string): StoreThunkAction<Observable<StatusIdea[]>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getQuotes(page, query, tag)).map(response => {
      const data = typeof response.quotes.length === 'number' ? response.quotes : response.quotes.quotes
      return data.map(quoteToStatusIdea)
    })
  }
}

export function getQuotesRange(from: number, to: number): StoreThunkAction<Observable<StatusIdea[]>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getQuotesRange(from, to)).map(response => {
      return response.quotes.quotes.map(quoteToStatusIdea)
    })
  }
}

export function getTotalQuotesCount() {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getQuotes(0, '')).map(response => response.quotes.count)
}

export function createQuote(status: string, author?: string, tags?: string[], language?: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.createQuote(status, author, tags, language))
}

export function updateQuote(id: string, status: string, author?: string, tags?: string[]): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.updateQuote(id, status, author, tags))
}

export function deleteQuote(id: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.deleteQuote(id))
}
