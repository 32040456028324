import AdminPage from 'admin/components/AdminPage'
import React from 'react'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { SectionList } from './components/SectionList'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { SectionDetails } from './components/SectionDetails'
import useAsyncAction from 'hooks/useAsyncAction'
import { message } from 'services/snackbar'
import ConfirmDialog from 'components/ConfirmDialog'
import { VideoTrainingSection } from 'admin/interfaces'
import { deleteSection, getVideoSections, reorderVideos, saveSection } from 'admin/services/training/net'

export function VideoTrainingConfig() {
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const fetchData = () => {
    return dispatch(getVideoSections(true))
  }

  const [getSections, sections, error, loading] = useAsyncAction(fetchData)

  React.useEffect(() => {
    if (location.pathname === '/admin/video-training') {
      getSections()
    }
  }, [location.pathname])

  const onEditSection = (c: VideoTrainingSection) => {
    navigate(`/admin/video-training/section/${c.id}`)
  }

  const createNewSection = (title: string, slug: string, label: string) => {
    const data = { title, slug, label }
    dispatch(saveSection(data, undefined, sections.length.toString()))
      .toPromise()
      .then(() => {
        getSections()
        dispatch(message('Section created!'))
      })
      .catch(error => {
        console.log('[error]', error)
        dispatch(message(`Error: ${error.message}`))
      })
  }

  const onDelete = (c: VideoTrainingSection) => {
    dispatch(deleteSection(c.id))
      .toPromise()
      .then(() => {
        getSections()
        dispatch(message('Section deleted!'))
      })
      .catch(e => {
        console.log('[error]', e)
        const msg = e.response.error?.message || e.message
        dispatch(message(`Error: ${msg}`))
      })
  }

  React.useEffect(() => {
    if (error) {
      const msg = (error as any).response?.error.message
      dispatch(message(`Error: ${msg}`))
    }
  }, [dispatch, error])

  const onReorder = (ids: number[]) => {
    dispatch(reorderVideos('section', ids))
      .toPromise()
      .then(() => {
        dispatch(message('Sections reordered!'))
      })
      .catch(e => {
        console.log('[error]', e)
        dispatch(message(`Reordering error: ${e.message}`))
      })
  }

  if (!sections) {
    return null
  }

  return (
    <AdminPage title="Video Training">
      {loading && <div>Loading...</div>}
      {error && <div>Error: {JSON.stringify(error)}</div>}
      {!loading && !error && (
        <ConfirmDialog message="Delete section?">
          {(confirm) => (
            <Routes>
              <Route
                index
                element={(
                  <SectionList
                    sections={sections}
                    onCreateSection={createNewSection}
                    onDeleteSection={confirm(onDelete)}
                    onEditSection={onEditSection}
                    onReorder={onReorder}
                  />
                )}
              />
              <Route path="section/:sectionId" element={<SectionDetails sections={sections} />} />
            </Routes>
          )}
        </ConfirmDialog>
      )}
    </AdminPage>
  )
}
