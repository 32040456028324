import { V1 } from './net'
import { StoreThunkDispatch } from 'store/state'

export function clearFBTokens(userId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.clearFBTokens(userId))
  }
}

export function toggleUserBlocked(userId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.toggleUserBlocked(userId))
  }
}

export function getIntercomData(userId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getIntercomData(userId))
  }
}

export function resetPassword(userId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.resetPassword(userId))
  }
}

export function restartTrial(appId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.restartTrial(appId))
  }
}

export function makeAdmin(userId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.makeAdmin(userId))
  }
}

export function clearUserData(userId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.clearUserData(userId))
  }
}

export function getUserData(userId: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getUserData(userId))
  }
}
