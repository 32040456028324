import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL } from 'config'

export const ENDPOINT_GET_ERRORS = `${ADMIN_SERVICE_URL}/show_errors`

export const V1 = Object.freeze({
  getErrors: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_ERRORS).asGet().expectJSON()
    return function (period: string) {
      return authorizedPipe<any>(
        builder.param('period', period).build()
      )
    }
  }()
})
