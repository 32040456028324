import * as React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Infobox } from 'interfaces'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import { Icon } from '@mdi/react'
import SaveIcon from '@mui/icons-material/Check'
import UndoIcon from '@mui/icons-material/Undo'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from '../InfoBoxesRoute.pcss'
import { availableIcons } from 'components/PageCallout/PageCallout'
import Checkbox from '@mui/material/Checkbox'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export interface InfoboxTableRowProps {
  infobox: Infobox
  onAdd: (infobox: Infobox) => void
  onUpdate: (infobox: Infobox, oldSlug?: string) => void
  onDelete: (slug: string) => void
}
export function InfoboxTableRow(props: InfoboxTableRowProps) {
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [data, setData] = React.useState<Infobox>(props.infobox)
  const slugRef = React.useRef<HTMLDivElement>(null)
  const titleRef = React.useRef<HTMLDivElement>(null)
  const paragraphRef = React.useRef<HTMLDivElement>(null)
  const linkTextRef = React.useRef<HTMLDivElement>(null)
  const linkText2Ref = React.useRef<HTMLDivElement>(null)
  const linkText3Ref = React.useRef<HTMLDivElement>(null)
  const linkUrlRef = React.useRef<HTMLDivElement>(null)
  const linkUrl2Ref = React.useRef<HTMLDivElement>(null)
  const linkUrl3Ref = React.useRef<HTMLDivElement>(null)
  const [exact, setexact] = React.useState(false)
  const [edited, setEdited] = React.useState(false)
  const [newRow, setNewRow] = React.useState(false)

  React.useEffect(() => {
    setEdited(false)
    if (!props.infobox.slug) {
      setNewRow(true)
    }
    setData(props.infobox)
    setexact(data.exact)
  }, [props.infobox, data.exact])

  const handleInput = () => {
    setEdited(true)
  }

  const toggleExact = () => {
    handleInput()
    setexact(current => !current)
  }

  const undoChanges = () => {
    setEdited(false)
    setData(props.infobox)
    setRefreshKey(current => current + 1)
  }

  const deleteInfobox = () => {
    props.onDelete(props.infobox.slug)
  }

  // const onExactChange = () => {
  //   props.onExactChange
  //   props.infobox.exact = !props.infobox.exact
  //   handleInput()
  // }

  const saveChanges = () => {
    const updatedInfobox = {
      ...props.infobox,
      slug: slugRef.current?.innerText.trim() || props.infobox.slug,
      title: titleRef.current?.innerText.trim() || props.infobox.title,
      paragraph: paragraphRef.current?.innerText.trim() || '',
      linkText: linkTextRef.current?.innerText.trim() || '',
      linkText2: linkText2Ref.current?.innerText.trim() || '',
      linkText3: linkText3Ref.current?.innerText.trim() || '',
      linkUrl: linkUrlRef.current?.innerText.trim() || '',
      linkUrl2: linkUrl2Ref.current?.innerText.trim() || '',
      linkUrl3: linkUrl3Ref.current?.innerText.trim() || '',
      linkIcon: props.infobox.linkIcon,
      linkIcon2: props.infobox.linkIcon2,
      linkIcon3: props.infobox.linkIcon3,
      exact: exact || false
    }
    if (newRow) {
      props.onAdd(updatedInfobox)
      setNewRow(false)
    } else {
      props.onUpdate(updatedInfobox, props.infobox.slug)
    }
  }

  const handlePlanChangeLinkIcon = (e: SelectChangeEvent) => {
    props.infobox.linkIcon = e.target.value
    setEdited(true)
  }

  const handlePlanChangeLinkIcon2 = (e: SelectChangeEvent) => {
    props.infobox.linkIcon2 = e.target.value
    setEdited(true)
  }

  const handlePlanChangeLinkIcon3 = (e: SelectChangeEvent) => {
    props.infobox.linkIcon3 = e.target.value
    setEdited(true)
  }

  return (
    <TableRow className={styles.tr} key={refreshKey}>
      <TableCell className={styles['table-cell']}>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={slugRef}
          data-key="slug"
          onInput={handleInput}
        >
          {data.slug}
        </div>
      </TableCell>
      <TableCell className={styles['table-cell']}>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={titleRef}
          data-key="title"
          onInput={handleInput}
        >
          {data.title}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={paragraphRef}
          data-key="paragraph"
          className={`${styles['cell-textarea']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.paragraph}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={linkTextRef}
          data-key="linkText"
          className={`${styles['cell-textarea']} ${styles['cell-stacked']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.linkText}
        </div>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={linkText2Ref}
          data-key="linkText2"
          className={`${styles['cell-textarea']} ${styles['cell-stacked']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.linkText2}
        </div>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={linkText3Ref}
          data-key="linkText3"
          className={`${styles['cell-textarea']} ${styles['cell-stacked']} ${styles['cell-md']}`}
          onInput={handleInput}
        >
          {data.linkText3}
        </div>
      </TableCell>
      <TableCell>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={linkUrlRef}
          data-key="linkUrl"
          className={`${styles['cell-textarea']} ${styles['cell-stacked']}`}
          onInput={handleInput}
        >
          {data.linkUrl}
        </div>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={linkUrl2Ref}
          data-key="linkUrl2"
          className={`${styles['cell-textarea']} ${styles['cell-stacked']}`}
          onInput={handleInput}
        >
          {data.linkUrl2}
        </div>
        <div
          contentEditable
          suppressContentEditableWarning
          ref={linkUrl3Ref}
          data-key="linkUrl3"
          className={`${styles['cell-textarea']} ${styles['cell-stacked']}`}
          onInput={handleInput}
        >
          {data.linkUrl3}
        </div>
      </TableCell>
      <TableCell>
        <div>
          <Select
            value={data.linkIcon}
            inputProps={{ 'data-key': 'linkIcon' }}
            className={`${styles['select-input']} ${styles['cell-xs']}`}
            onChange={handlePlanChangeLinkIcon}
          >
            {
              availableIcons.map(icon => (
                <MenuItem value={icon.key} key={icon.key}>
                  <Icon size={2} path={icon.value} />
                </MenuItem>
              ))
            }
          </Select>
        </div>
        <div>
          <Select
            value={data.linkIcon2}
            inputProps={{ 'data-key': 'linkIcon2' }}
            className={`${styles['select-input']} ${styles['cell-xs']}`}
            onChange={handlePlanChangeLinkIcon2}
          >
            {
              availableIcons.map(icon => (
                <MenuItem value={icon.key} key={icon.key}>
                  <Icon size={2} path={icon.value} />
                </MenuItem>
              ))
            }
          </Select>
        </div>
        <div>
          <Select
            value={data.linkIcon3}
            inputProps={{ 'data-key': 'linkIcon3' }}
            className={`${styles['select-input']} ${styles['cell-xs']}`}
            onChange={handlePlanChangeLinkIcon3}
          >
            {
              availableIcons.map(icon => (
                <MenuItem value={icon.key} key={icon.key}>
                  <Icon size={2} path={icon.value} />
                </MenuItem>
              ))
            }
          </Select>
        </div>
      </TableCell>

      <TableCell className={styles['cell-actions']}>
        <Checkbox
          onChange={toggleExact}
          checked={exact}
        />
        <div>
          <Tooltip title="Save" placement="top">
            <span>
              <Fab size="small" color="primary" disabled={!edited} onClick={saveChanges}>
                <SaveIcon />
              </Fab>
            </span>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Undo" placement="top">
            <span>
              <Fab size="small" disabled={!edited} onClick={undoChanges}>
                <UndoIcon fontSize="small" />
              </Fab>
            </span>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete" placement="top">
            <span>
              <Fab size="small" onClick={deleteInfobox}>
                <DeleteIcon fontSize="small" />
              </Fab>
            </span>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default InfoboxTableRow
