import AdminPage from 'admin/components/AdminPage'
import React from 'react'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { PromptCategory } from 'shared/types'
import { CategoryList } from './components/CategoryList'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { CategoryDetails } from './components/CategoryDetails'
import useAsyncAction from 'hooks/useAsyncAction'
import { deletePromptCategory, getPromptCategories, reorderPrompts, savePromptCategory } from 'services/compose/assistant/net'
import { message } from 'services/snackbar'
import ConfirmDialog from 'components/ConfirmDialog'

export function WriterConfig() {
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()

  const fetchData = () => {
    return dispatch(getPromptCategories(true))
  }

  const [getCategories, categories, error, loading] = useAsyncAction(fetchData)

  React.useEffect(() => {
    getCategories()
  }, [])

  const onEditCategory = (c: PromptCategory) => {
    navigate(`/admin/writer/cat/${c.id}`)
  }

  const createNewCategory = (name: string, type: string, visible: boolean) => {
    dispatch(savePromptCategory(name, type, visible))
      .toPromise()
      .then(() => {
        getCategories()
        dispatch(message('Category created!'))
      })
      .catch(error => {
        console.log('[error]', error)
        dispatch(message(`Error: ${error.message}`))
      })
  }

  const deleteCategory = (c: PromptCategory) => {
    dispatch(deletePromptCategory(c.id))
      .toPromise()
      .then(() => {
        getCategories()
      })
      .catch(e => {
        console.log('[error]', e)
        dispatch(message(`Error: ${e.message}`))
      })
  }

  React.useEffect(() => {
    if (error) {
      const msg = (error as any).response?.error.message
      dispatch(message(`Error: ${msg}`))
    }
  }, [dispatch, error])

  const onReorder = (ids: number[]) => {
    dispatch(reorderPrompts('category', ids))
      .toPromise()
      .then(() => {
        dispatch(message('Categories reordered!'))
      })
      .catch(e => {
        console.log('[error]', e)
        dispatch(message(`Reordering error: ${e.message}`))
      })
  }

  if (!categories) {
    return null
  }

  return (
    <AdminPage title="AI Writer">
      {loading && <div>Loading...</div>}
      {error && <div>Error: {JSON.stringify(error)}</div>}
      {!loading && !error && (
        <ConfirmDialog message="Delete category?">
          {(confirm) => (
            <Routes>
              <Route
                index
                element={(
                  <CategoryList
                    categories={categories}
                    onCreateCategory={createNewCategory}
                    onDeleteCategory={confirm(deleteCategory)}
                    onEditCategory={onEditCategory}
                    onReorder={onReorder}
                  />
                )}
              />
              <Route path="cat/:categoryId" element={<CategoryDetails categories={categories} />} />
            </Routes>
          )}
        </ConfirmDialog>
      )}
    </AdminPage>
  )
}
