import { CONTENT_SERVICE_URL } from 'config'
import { authorizedPipe, RequestBuilder } from 'services/net'

const ENDPOINT_GET_QUOTES = `${CONTENT_SERVICE_URL}/quotes/search`
const ENDPOINT_GET_QUOTES_RANGE = `${CONTENT_SERVICE_URL}/quotes/admin_search`
const ENDPOINT_DELETE_QUOTE = `${CONTENT_SERVICE_URL}/quotes/:id`
const ENDPOINT_UPDATE_QUOTE = `${CONTENT_SERVICE_URL}/quotes/:id`
const ENDPOINT_CREATE_QUOTE = `${CONTENT_SERVICE_URL}/quotes`
const ENDPOINT_GET_QUOTES_BY_TAG = `${CONTENT_SERVICE_URL}/quotes/search_tags`
const ENDPOINT_KEYWORDS = `${CONTENT_SERVICE_URL}/quotes/keywords`
const ENDPOINT_DELETE_KEYWORD = `${CONTENT_SERVICE_URL}/quotes/keywords/:id`

export const V1 = Object.freeze({
  getQuotes: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_QUOTES).asPost().asJSON()
    return function (page: number, search?: string, tag?: string) {
      const data: any = { search }
      if (tag) {
        data.tag = tag
      }
      return authorizedPipe<any>(
        builder
          .param('page', page.toString())
          .body(data)
          .build()
      )
    }
  }(),
  getQuotesByTag: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_QUOTES_BY_TAG).asPost().asJSON()
    return function (page: number, tag: string) {
      return authorizedPipe<any>(
        builder
          .param('page', page.toString())
          .body({ search: tag })
          .build()
      )
    }
  }(),
  getQuotesRange: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_QUOTES_RANGE).asPost().asJSON()
    return function (from: number, to: number) {
      return authorizedPipe<any>(
        builder
          .body({ start_id: from, end_id: to })
          .build()
      )
    }
  }(),
  createQuote: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_QUOTE).asPut().asJSON()
    return function (status: string, author?: string, tags?: string[], language = 'English') {
      const data: any = { status, language, source: 'admin-panel' }
      if (author) {
        data.author = author
      }
      if (tags) {
        data.tags = tags
      }

      return authorizedPipe<any>(
        builder.body(data).build()
      )
    }
  }(),
  deleteQuote: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_QUOTE).asDelete().asJSON()
    return function (id: string) {
      return authorizedPipe<any>(
        builder.partial(':id', id).build()
      )
    }
  }(),
  updateQuote: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_QUOTE).asPost().asJSON()
    return function (id: string, status: string, author?: string, tags?: string[]) {
      const data: any = { status, author, tags }
      // if (tags) {
      //   data.tags = tags.map(id => ({ id }))
      // }

      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .body(data)
          .build()
      )
    }
  }(),
  getKeywords: function () {
    const builder = new RequestBuilder(ENDPOINT_KEYWORDS).asGet()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  addKeyword: function () {
    const builder = new RequestBuilder(ENDPOINT_KEYWORDS).asPut().asJSON()
    return function (keyword: string) {
      return authorizedPipe<any>(
        builder.body({ name: keyword }).build()
      )
    }
  }(),
  deleteKeyword: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_KEYWORD).asDelete().asJSON()
    return function (id: number) {
      return authorizedPipe<any>(
        builder.partial(':id', id.toString()).build()
      )
    }
  }()
})
