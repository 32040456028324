import React from 'react'
import { PPProduct } from 'interfaces'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import { Subject } from 'rxjs/Subject'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AdminPage from 'admin/components/AdminPage'
import styles from './ErrorsRoute.pcss'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { DateRange } from '@mui/x-date-pickers-pro'
import { getErrors } from 'admin/services/errors/actions'
import { Error } from 'admin/interfaces'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { sub } from 'date-fns'

export function ErrorsRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const today = new Date()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [range, setRange] = React.useState<DateRange<Date>>([sub(today, { months: 1 }), today])
  const [datePeriod, setDatePeriod] = React.useState('1-days')
  const [errors, setErrors] = React.useState<Error[]>([])
  const [loading, setLoading] = React.useState(false)
  const [selectedPeriodText, setSelectedPeriodText] = React.useState('')

  const fetch$ = React.useRef<Subject<void>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    setLoading(true)
    fetch$.current.flatMap(() => dispatch(getErrors(datePeriod)))
      .subscribe((data) => {
        setSelectedPeriodText(`${data.from} to ${data.to}`)
        setErrors(data.errors)
        setLoading(false)
      }, () => {
        dispatch(message('Error fetching posts errors!'))
        setLoading(false)
      })

    fetch$.current.next()

    return () => {
      fetch$.current?.unsubscribe()
    }
  }, [dispatch, datePeriod])

  const confirmCustomRange = () => {
    const start = range[0]?.toISOString()
    const end = range[1]?.toISOString()
    console.log(start, end)

    // props.onValueChange('custom', start, end)
    closeDialog()
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  // const dateOptions = [{
  //   value: '1-days',
  //   label: '1 Day'
  // }, {
  //   value: '30-minutes',
  //   label: '30 Minutes'
  // }, {
  //   value: '2-days',
  //   label: '2 Days'
  // }]

  const dateOptions: PPSelectOptions = {
    '1-days': { label: '1 day ago (24 hours)' },
    '30-minutes': { label: 'Last 30 minutes' },
    '3-hours': { label: 'Last 3 hours' },
    '2-days': { label: '2 days ago (24 hours)' },
    '3-days': { label: '3 days ago (24 hours)' },
    '4-days': { label: '4 days ago (24 hours)' },
    '5-days': { label: '5 days ago (24 hours)' }
  }

  const rangeSelected = Boolean(range[0] && range[1])

  const onSelectedDatePeriodChange = (value: string) => {
    setDatePeriod(value)
  }

  return (
    <AdminPage title="Errors">
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <div className={styles.heading}>
        {/* <Button variant="contained" color="primary" className={styles.btn} onClick={() => setDialogOpen(true)}>
          <FormattedMessage id="actions.choose-period" />
        </Button>
        <div className={styles['heading-row']}>
          {range[0] && range[1] && (
            <span className={styles.range}>
              {range[0]?.format('DD MMM YYYY')} - {range[1]?.format('DD MMM YYYY')}
            </span>
          )}
        </div>
        <Dialog className={styles.dialog} open={dialogOpen}>
          <DialogTitle className={styles['dialog-title']}>
            <FormattedMessage id="post.posted.time-range-popup.title" />
          </DialogTitle>
          <div className={styles['dialog-content']}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={range}
                maxDate={today}
                onChange={setRange}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className={styles.actions}>
            <Button className={styles.btn} onClick={closeDialog}>
              <FormattedMessage id="actions.cancel" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={styles.btn}
              disabled={!rangeSelected}
              onClick={confirmCustomRange}
            >
              <FormattedMessage id="actions.ok" />
            </Button>
          </div>
        </Dialog> */}
        <PPSelect
          options={dateOptions}
          selectedValue={datePeriod}
          onSelectionChange={onSelectedDatePeriodChange}
          className={styles.select}
        />
        <span className={styles.range}>
          {selectedPeriodText}
        </span>
      </div>
      <TableContainer component={Paper} sx={{ marginBottom: '24px' }}>
        <Table sx={{ minWidth: 850 }} aria-label="plans table">
          <TableHead>
            <TableRow className={styles.thead}>
              <TableCell align="center">Total posts count</TableCell>
              <TableCell align="center">Network</TableCell>
              <TableCell align="center">Error</TableCell>
              <TableCell align="center">Affected users</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((error) => (
              <ErrorTableRow
                error={error}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AdminPage>
  )
}

interface ErrorTableRowProps {
  error: Error
}

const ErrorTableRow = ({ error }: ErrorTableRowProps) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {error.totalCount}
      </TableCell>
      <TableCell align="center">{error.networkType}</TableCell>
      <TableCell align="center">{error.error || 'Successfully published ✅'} </TableCell>
      <TableCell align="center">{error.affectedUsers}</TableCell>
    </TableRow>
  )
}

export default ErrorsRoute
