import * as React from 'react'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import styles from './Quotes.pcss'
import { StoreThunkDispatch } from 'store/state'
import { Subject } from 'rxjs/Subject'
import { createQuote } from 'admin/services/quotes/actions'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'

export function CreateStatusIdeaPanel(props: { onCreated: () => void }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const textRef = React.useRef<HTMLTextAreaElement>(null)
  const authorRef = React.useRef<HTMLInputElement>(null)
  const languageRef = React.useRef<HTMLInputElement>(null)
  const tagsRef = React.useRef<HTMLInputElement>(null)

  const create$ = React.useRef<Subject<{ status: string, author?: string, tags?: string[], language?: string }>>()

  React.useEffect(() => {
    create$.current = new Subject()
    create$.current.flatMap((params: { status: string, author?: string, tags?: string[], language?: string }) => {
      return dispatch(createQuote(params.status, params.author, params.tags, params.language)).pipe(
        catchError((e: Error) => {
          dispatch(message(`An error occurred: ${e.message}`))
          return Observable.of({ error: true })
        })
      )
    })
      .subscribe((response) => {
        if (!response.error) {
          dispatch(message('Status added!'))
          props.onCreated()
        }
      })

    return () => create$.current?.unsubscribe()
  }, [])

  const create = () => {
    const status = textRef.current?.value.trim()
    const author = authorRef.current?.value.trim()
    const language = languageRef.current?.value.trim()
    const tags = tagsRef.current?.value.split(',').map(value => value.trim()).filter(Boolean)

    if (status) {
      create$.current?.next({ status, author, tags, language })
      ; (textRef.current as any).value = ''
      ; (authorRef.current as any).value = ''
      ; (tagsRef.current as any).value = ''
      ; (languageRef.current as any).value = ''
    }
  }
  return (
    <section className={styles['box-create']}>
      <h3 className={styles['add-title']}>Add new quote</h3>
      <textarea className={styles.textarea} ref={textRef} placeholder="Enter text" rows={1} />
      <div className={styles['box-create-footer']}>
        <input type="text" placeholder="Author" ref={authorRef} />
        <input type="text" placeholder="Tags (comma-separated)" ref={tagsRef} />
        <input type="text" placeholder="Language" ref={languageRef} />
        <Button color="primary" variant="contained" size="small" onClick={create}>
          <FormattedMessage id="actions.save" />
        </Button>
      </div>
    </section>
  )
}
