import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'

import styles from './CheckoutUIRoute.pcss'
import { IndexedObject } from 'interfaces'
import { StylesTable } from './StylesTable'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Subject } from 'rxjs/Subject'
import { updateConfig } from 'admin/services/checkout-ui/net'
import { StoreThunkDispatch } from 'store/state'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { INPUT_ACCEPT_PHOTO } from 'shared/constants'
import { uploadFile } from 'admin/services/upload/net'
import { capitalize } from 'shared'
import { FAQEditor } from './FAQEditor'

interface AccountConfig {
  title: string
  titleNoCC: string
  titleStyles: IndexedObject<string>
  firstNamePlaceholder: string
  lastNamePlaceholder: string
  emailPlaceholder: string
  mainButtonLabel: string
  mainButtonStyles: IndexedObject<string>
  facebookButtonStyles: IndexedObject<string>
}

interface PaymentConfig {
  title: string
  titleCC: string
  titleStyles: IndexedObject<string>
  mainButtonLabel: string
  mainButtonStyles: IndexedObject<string>
  formContainerStyles: IndexedObject<string>
  footerVisible: boolean
  cardNumberLabel: string
  cardExpDateLabel: string
  cardCvvLabel: string
  priceLabel: string
  priceLabelStyles: IndexedObject<string>
  inputLabelStyles: IndexedObject<string>
  priceAfterTrialLabel: string
  priceAfterTrialLabelStyles: IndexedObject<string>
  imageUrl: string
  trialImageUrl: string
  faqs: Record<string, { id: string, q: string, a: string, isTrial: boolean }>
  planSelectHint: string
}

interface UIConfig {
  createAccountConfig: AccountConfig
  paymentDetailsConfig: PaymentConfig
  progress: Record<string, string>
}

declare const __DEV__: boolean

export function CheckoutUIRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [config, setConfig] = React.useState<UIConfig | null>(null)
  const [error, setError] = React.useState(false)
  const [fetchKey, setFetchKey] = React.useState(0)
  const update$ = React.useRef<Subject<UIConfig>>()

  React.useEffect(() => {
    const configUrl = `https://staticpp.postplanner.com/config/ui.config.${__DEV__ ? 'dev.' : ''}json?v=${Date.now()}`
    fetch(configUrl)
      .then(response => response.json())
      .then(c => {
        setError(false)
        setConfig(c)
      })
      .catch(() => {
        dispatch(message('Error fetching config. Please try again.'))
        setError(true)
      })
  }, [fetchKey])

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current
      .flatMap(c => dispatch(updateConfig(c, !__DEV__)).pipe(
        catchError(() => {
          return Observable.of({ error: true })
        })
      ))
      .subscribe((response: any) => {
        if (response.error) {
          dispatch(message('Update failed!'))
          setFetchKey(current => current + 1)
        } else {
          dispatch(message('Config updated!'))
          setFetchKey(current => current + 1)
        }
      })
  }, [])

  const updateConfigAccountStep = (key: string, value: string) => {
    setConfig(current => {
      if (!current) {
        return null
      }

      return {
        ...current,
        createAccountConfig: {
          ...current.createAccountConfig,
          [key]: value
        }
      } as any
    })
  }

  const updateConfigPaymentStep = (key: string, value: string) => {
    setConfig(current => {
      if (!current) {
        return null
      }

      return {
        ...current,
        paymentDetailsConfig: {
          ...current.paymentDetailsConfig,
          [key]: value
        }
      } as any
    })
  }

  const onProgressbarValueChange = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setConfig(current => {
      if (!current) {
        return null
      }

      return {
        ...current,
        progress: {
          ...current.progress,
          [key]: value
        }
      } as any
    })
  }

  const onValueChange = (step: number, key: keyof AccountConfig | keyof PaymentConfig) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (step === 1) {
      updateConfigAccountStep(key, value)
    } else {
      updateConfigPaymentStep(key, value)
    }
  }

  const removeStyle = (step: number, key: keyof AccountConfig | keyof PaymentConfig) => (rule: string) => {
    if (!config) {
      return
    }

    if (step === 1) {
      const newStyles = Object.assign({}, (config.createAccountConfig as any)[key])
      delete newStyles[rule]
      setConfig(current => current ? ({
        ...current,
        createAccountConfig: {
          ...current?.createAccountConfig,
          [key]: newStyles
        }
      }) : null)
    } else {
      const newStyles = Object.assign({}, (config.paymentDetailsConfig as any)[key])
      delete newStyles[rule]
      setConfig(current => current ? ({
        ...current,
        paymentDetailsConfig: {
          ...current?.paymentDetailsConfig,
          [key]: newStyles
        }
      }) : null)
    }
  }

  const addUpdateStyle = (step: number, key: keyof AccountConfig | keyof PaymentConfig) => (rule: string, value: string) => {
    if (!config) {
      return
    }
    if (step === 1) {
      setConfig(current => current ? ({
        ...current,
        createAccountConfig: {
          ...current?.createAccountConfig,
          [key]: {
            ...(current.createAccountConfig as any)[key],
            [rule]: value
          }
        }
      }) : null)
    } else {
      setConfig(current => current ? ({
        ...current,
        paymentDetailsConfig: {
          ...current.paymentDetailsConfig,
          [key]: {
            ...(current.paymentDetailsConfig as any)[key],
            [rule]: value
          }
        }
      }) : null)
    }
  }

  const togglePaymentPageFooterVisible = () => {
    setConfig(current => current ? ({
      ...current,
      paymentDetailsConfig: {
        ...current.paymentDetailsConfig,
        footerVisible: !current.paymentDetailsConfig.footerVisible
      }
    }) : null)
  }

  const saveChanges = () => {
    if (config) {
      update$.current?.next(config)
    }
  }

  const addUpdateFAQItem = (data: { id: string, q?: string, a?: string, isTrial: boolean }) => {
    const item = (config?.paymentDetailsConfig.faqs || {})[data.id]

    if (!item) {
      // add
      if (!data.q || !data.a) {
        dispatch(message('Please fill in all fields'))
        return
      }
      setConfig(current => current ? ({
        ...current,
        paymentDetailsConfig: {
          ...current.paymentDetailsConfig,
          faqs: {
            ...current.paymentDetailsConfig.faqs,
            [data.id]: data as any
          }
        }
      }) : null)
      return
    }

    // update
    setConfig(current => current ? ({
      ...current,
      paymentDetailsConfig: {
        ...current.paymentDetailsConfig,
        faqs: {
          ...current.paymentDetailsConfig.faqs,
          [data.id]: {
            ...item,
            q: data.q || item.q,
            a: data.a || item.a,
            isTrial: data.isTrial === undefined ? item.isTrial : data.isTrial
          }
        }
      }
    }) : null)
  }

  const removeFAQItem = (id: string) => {
    setConfig(current => {
      if (!current) {
        return null
      }

      const newFaqs = Object.assign({}, current.paymentDetailsConfig.faqs)
      delete newFaqs[id]
      return {
        ...current,
        paymentDetailsConfig: {
          ...current.paymentDetailsConfig,
          faqs: newFaqs
        }
      }
    })
  }

  const onSidebarImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      const path = `checkout-ui//sidebar-image.${file.name.split('.').pop()}`
      dispatch(uploadFile(file, path)).toPromise()
        .then(({ url }) => {
          setConfig(current => current ? ({
            ...current,
            paymentDetailsConfig: {
              ...current.paymentDetailsConfig,
              imageUrl: url
            }
          }) : null)
          dispatch(message('Upload successful! Click "Save Changes" below to confirm.'))
        }).catch((e: any) => {
          console.log(e)
          dispatch(message('Upload failed!'))
        })
    }
  }

  const onSidebarTrialImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      const path = `checkout-ui//sidebar-image-trial.${file.name.split('.').pop()}`
      dispatch(uploadFile(file, path)).toPromise()
        .then(({ url }) => {
          setConfig(current => current ? ({
            ...current,
            paymentDetailsConfig: {
              ...current.paymentDetailsConfig,
              trialImageUrl: url
            }
          }) : null)
          dispatch(message('Upload successful! Click "Save Changes" below to confirm.'))
        }).catch((e: any) => {
          console.log(e)
          dispatch(message('Upload failed!'))
        })
    }
  }

  return (
    <AdminPage title="Checkout UI config">
      {!error && !config && (
        <div>Loading...</div>
      )}
      {error && (
        <div>An error occurred. Please refresh.</div>
      )}
      {config && (
        <div className={styles.container}>
          <section className={styles.section}>
            <h5 className={styles['section-title']}>Progress</h5>
            <div className={styles.table}>
              {Object.keys(config.progress).map((key: any) => (
                <div className={styles.row} key={key}>
                  <div className={styles.cell}>{key.split('-').join(' ')}</div>
                  <div className={styles.cell}>
                    <input type="text" value={config.progress[key]} onChange={onProgressbarValueChange(key)} />
                  </div>
                </div>
              ))}
            </div>
          </section>
          <section className={styles.section}>
            <h5 className={styles['section-title']}>Create Account Step:</h5>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.cell}>Title</div>
                <div className={styles.cell}>
                  <input type="text" value={config.createAccountConfig.title} onChange={onValueChange(1, 'title')} />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Title - No-CC users</div>
                <div className={styles.cell}>
                  <input type="text" value={config.createAccountConfig.titleNoCC} onChange={onValueChange(1, 'titleNoCC')} />
                </div>
              </div>
              <div className={styles.row}>
                <StylesTable
                  title="Title Styles"
                  styles={config.createAccountConfig.titleStyles || {}}
                  onRemoveStyle={removeStyle(1, 'titleStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(1, 'titleStyles')}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>First Name - Placeholder</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.createAccountConfig.firstNamePlaceholder}
                    onChange={onValueChange(1, 'firstNamePlaceholder')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Last Name - Placeholder</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.createAccountConfig.lastNamePlaceholder}
                    onChange={onValueChange(1, 'lastNamePlaceholder')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Email - Placeholder</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.createAccountConfig.emailPlaceholder}
                    onChange={onValueChange(1, 'emailPlaceholder')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Main Button Label</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.createAccountConfig.mainButtonLabel}
                    onChange={onValueChange(1, 'mainButtonLabel')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <StylesTable
                  title="Main Button Styles"
                  styles={config.createAccountConfig.mainButtonStyles}
                  onRemoveStyle={removeStyle(1, 'mainButtonStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(1, 'mainButtonStyles')}
                />
              </div>
              <div className={styles.row}>
                <StylesTable
                  title="Facebook Login Button Styles"
                  styles={config.createAccountConfig.facebookButtonStyles}
                  onRemoveStyle={removeStyle(1, 'facebookButtonStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(1, 'facebookButtonStyles')}
                />
              </div>
            </div>
          </section>

          <section className={styles.section}>
            <h5 className={styles['section-title']}>Payment Step:</h5>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.cell}>Title</div>
                <div className={styles.cell}>
                  <input type="text" value={config.paymentDetailsConfig.title} onChange={onValueChange(2, 'title')} />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Title - CC-trial users</div>
                <div className={styles.cell}>
                  <input type="text" value={config.paymentDetailsConfig.titleCC} onChange={onValueChange(2, 'titleCC')} />
                </div>
              </div>
              <div className={styles.row}>
                <StylesTable
                  title="Title Styles"
                  styles={config.paymentDetailsConfig.titleStyles || {}}
                  onRemoveStyle={removeStyle(2, 'titleStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(2, 'titleStyles')}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Plan select hint</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.paymentDetailsConfig.planSelectHint}
                    onChange={onValueChange(2, 'planSelectHint')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Card Number Label</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.paymentDetailsConfig.cardNumberLabel}
                    onChange={onValueChange(2, 'cardNumberLabel')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Card Exp. Date Label</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.paymentDetailsConfig.cardExpDateLabel}
                    onChange={onValueChange(2, 'cardExpDateLabel')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Card CVV Label</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.paymentDetailsConfig.cardCvvLabel}
                    onChange={onValueChange(2, 'cardCvvLabel')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Main Button Label</div>
                <div className={styles.cell}>
                  <input
                    type="text"
                    value={config.paymentDetailsConfig.mainButtonLabel}
                    onChange={onValueChange(2, 'mainButtonLabel')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <StylesTable
                  title="Main Button Styles"
                  styles={config.paymentDetailsConfig.mainButtonStyles}
                  onRemoveStyle={removeStyle(2, 'mainButtonStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(2, 'mainButtonStyles')}
                />
              </div>
              {/* <div className={styles.row}>
                <StylesTable
                  title="Form Container Styles"
                  styles={config.paymentDetailsConfig.formContainerStyles}
                  onRemoveStyle={removeStyle(2, 'formContainerStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(2, 'formContainerStyles')}
                />
              </div> */}
              <div className={styles.row}>
                <StylesTable
                  title="Input Label Styles"
                  styles={config.paymentDetailsConfig.inputLabelStyles || {}}
                  onRemoveStyle={removeStyle(2, 'inputLabelStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(2, 'inputLabelStyles')}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Price Label</div>
                <div className={styles.cell}>
                  <div className={styles['input-price']}>
                    <input
                      type="text"
                      value={config.paymentDetailsConfig.priceLabel}
                      onChange={onValueChange(2, 'priceLabel')}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <StylesTable
                  title="Price Label Styles"
                  styles={config.paymentDetailsConfig.priceLabelStyles}
                  onRemoveStyle={removeStyle(2, 'priceLabelStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(2, 'priceLabelStyles')}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>Price After Trial Label</div>
                <div className={styles.cell}>
                  <div className={styles['input-price-after-trial']}>
                    <input
                      type="text"
                      value={config.paymentDetailsConfig.priceAfterTrialLabel}
                      onChange={onValueChange(2, 'priceAfterTrialLabel')}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <StylesTable
                  title="Price After Trial Label Styles"
                  styles={config.paymentDetailsConfig.priceAfterTrialLabelStyles}
                  onRemoveStyle={removeStyle(2, 'priceAfterTrialLabelStyles')}
                  onAddOrUpdateStyle={addUpdateStyle(2, 'priceAfterTrialLabelStyles')}
                />
              </div>
              <div className={styles.row}>
                <FormControlLabel
                  label="Footer visible"
                  classes={{ label: styles['cb-label'] }}
                  control={(
                    <Checkbox
                      checked={config.paymentDetailsConfig.footerVisible}
                      color="primary"
                      size="small"
                      onChange={togglePaymentPageFooterVisible}
                    />
                  )}
                />
              </div>
              <div className={styles.row}>
                <FAQEditor
                  faqs={config.paymentDetailsConfig.faqs || {}}
                  onAddOrUpdateItem={addUpdateFAQItem}
                  onRemoveItem={removeFAQItem}
                />
              </div>
              {/* <div className={styles.row}>
                <FormControlLabel
                  label="Sidebar Image"
                  classes={{ label: styles['cb-label'], root: styles['upload-box'] }}
                  control={(
                    <div>
                      <input
                        type="file"
                        title="Upload Image"
                        accept={INPUT_ACCEPT_PHOTO.join(',')}
                        onChange={onSidebarImageFileChange}
                      />
                      {config.paymentDetailsConfig.imageUrl && (
                        <img src={config.paymentDetailsConfig.imageUrl} alt="sidebar" width={200} />
                      )}
                    </div>
                  )}
                />
              </div> */}
              {/* <div className={styles.row}>
                <FormControlLabel
                  label="Sidebar Image (Trial)"
                  classes={{ label: styles['cb-label'], root: styles['upload-box'] }}
                  control={(
                    <div>
                      <input
                        type="file"
                        title="Upload Image (Trial)"
                        accept={INPUT_ACCEPT_PHOTO.join(',')}
                        onChange={onSidebarTrialImageFileChange}
                      />
                      {config.paymentDetailsConfig.imageUrl && (
                        <img src={config.paymentDetailsConfig.trialImageUrl} alt="trial sidebar" width={200} />
                      )}
                    </div>
                  )}
                />
              </div> */}
            </div>
          </section>
          <div>
            <Button variant="contained" color="primary" onClick={saveChanges}>Save changes</Button>
          </div>
        </div>
      )}
    </AdminPage>
  )
}

export default CheckoutUIRoute
