import { CONTENT_SERVICE_URL } from 'config'
import { authorizedPipe, RequestBuilder } from 'services/net'

const ENDPOINT_CREATE_CONTENT_PANEL = `${CONTENT_SERVICE_URL}/content_panel`
const ENDPOINT_GET_CONTENT_PANELS = `${CONTENT_SERVICE_URL}/content_panel`
const ENDPOINT_DELETE_CONTENT_PANEL = `${CONTENT_SERVICE_URL}/content_panel/:id`
const ENDPOINT_UPDATE_CONTENT_PANEL = `${CONTENT_SERVICE_URL}/content_panel/:id`
const ENDPOINT_ADD_GROUP_TO_PANEL = `${CONTENT_SERVICE_URL}/content_panel/:id/groups`

export const V1 = Object.freeze({
  createPanel: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_CONTENT_PANEL).asPut().asJSON().expectJSON()
    return function (name: string) {
      return authorizedPipe<any>(
        builder
          .body({ name })
          .build()
      )
    }
  }(),
  getContentPanels: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_CONTENT_PANELS).asGet().expectJSON()
    return function (fromCache?: boolean) {
      if (!fromCache) {
        builder.param('query_from_admin', 'true')
      }
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  deletePanel: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_CONTENT_PANEL).asDelete().asJSON().expectJSON()
    return function (id: string) {
      return authorizedPipe<any>(
        builder.partial(':id', id).build()
      )
    }
  }(),
  updatePanel: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_CONTENT_PANEL).asPatch().asJSON().expectJSON()
    return function (id: string, name: string, active: boolean) {
      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .body({ name, active: active ? '1' : '0' })
          .build()
      )
    }
  }(),
  addGroup: function () {
    const builder = new RequestBuilder(ENDPOINT_ADD_GROUP_TO_PANEL).asPost().expectJSON().asJSON()
    return function (panelId: string, groupId: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', panelId)
          .body({ group_id: groupId })
          .build()
      )
    }
  }()
})
