import { initialState, AdminState } from './state'
import { ACTION_ADMIN_LOGIN_SUCCESS, ACTION_ADMIN_LOGIN_FAILURE, ACTION_ADMIN_LOGOUT } from './actions'
import { ACTION_IMPERSONATE_STOP, startImpersonation } from './impersonate/actions'
import { AnyAction } from 'store/state'

export function adminReducer(state = initialState(), action: AnyAction): AdminState {
  switch (action.type) {
    case ACTION_ADMIN_LOGIN_SUCCESS:
      return { ...state, isLogged: true }

    case ACTION_ADMIN_LOGOUT:
    case ACTION_ADMIN_LOGIN_FAILURE:
      return initialState()

    case startImpersonation.type:
      const { userId, fullName, accessToken } = action.payload.user
      return {
        ...state,
        impersonate: {
          userId,
          name: fullName,
          token: accessToken,
          productInfo: action.payload.productInfo
        }
      }

    case ACTION_IMPERSONATE_STOP:
      return { ...state, impersonate: undefined }

    default:
      return state
  }
}

export default adminReducer
