import { HomeContentBlock } from 'admin/interfaces'
import { CONTENT_SERVICE_URL } from 'config'
import { authorizedPipe, RequestBuilder } from 'services/net'

const ENDPOINT_GET_HOME_CONTENT_BLOCKS = `${CONTENT_SERVICE_URL}/content_block`
const ENDPOINT_DELETE_BLOCK = `${CONTENT_SERVICE_URL}/content_block/:id`
const ENDPOINT_UPDATE_BLOCK = `${CONTENT_SERVICE_URL}/content_block/:id`
const ENDPOINT_CREATE_HOME_BLOCK = `${CONTENT_SERVICE_URL}/content_block`
const ENDPOINT_REORDER_HOME_CONTENT_BLOCKS = `${CONTENT_SERVICE_URL}/content_block/reorder`

export const V1 = Object.freeze({
  getHomeContentBlocks: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_HOME_CONTENT_BLOCKS).asGet().expectJSON().asJSON()
    return function (fromCache?: boolean) {
      if (!fromCache) {
        builder.param('query_from_admin', 'true')
      }

      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  createBlock: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_HOME_BLOCK).asPut().expectJSON().asJSON()
    return function (name: string, key: string, order: number) {
      return authorizedPipe<any>(
        builder.body({ name, key, order }).build()
      )
    }
  }(),
  reorderHomeBlocks: function () {
    const builder = new RequestBuilder(ENDPOINT_REORDER_HOME_CONTENT_BLOCKS).asPatch().asJSON().expectJSON()
    return function (order: string[]) {
      return authorizedPipe<any>(
        builder.body({ ids: order }).build()
      )
    }
  }(),
  deleteBlock: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_BLOCK).asDelete().asJSON().expectJSON()
    return function (id: string) {
      return authorizedPipe<any>(
        builder.partial(':id', id).build()
      )
    }
  }(),
  updateBlock: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_BLOCK).asPatch().asJSON().expectJSON()
    return function (block: HomeContentBlock) {
      const data: any = {
        ...block,
        active: block.active ? '1' : '0',
        content_panel_id: block.contentPanelId || null,
        group_id: block.groupId || null
      }

      return authorizedPipe<any>(
        builder
          .partial(':id', block.id)
          .body(data)
          .build()
      )
    }
  }()
})
