import * as React from 'react'
import { IQuote } from 'admin/interfaces'
import styles from './Quotes.pcss'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import IconDelete from '@mui/icons-material/Close'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ConfirmDialog from 'components/ConfirmDialog'

const LABEL_AUTHOR_EMPTY = 'add author'
const POPUP_ELEVATION = 7

interface EditableQuoteProps {
  quote: IQuote
  savedTags: string[]
  onUpdate: (id: string, status: string, author?: string, tags?: string[]) => void
  onDelete: (id: string) => void
}

export function EditableQuote(props: EditableQuoteProps) {
  const quote = props.quote
  const statusRef = React.useRef<HTMLDivElement | null>(null)
  const authorRef = React.useRef<HTMLDivElement | null>(null)
  const [deletedTags, setDeletedTags] = React.useState<string[]>([])
  const [addedTags, setAddedTags] = React.useState<string[]>([])
  const [edited, setEdited] = React.useState(false)
  const [tagsListAnchor, setTagsListAnchor] = React.useState<HTMLButtonElement | null>(null)

  const openTagsList = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setTagsListAnchor(e.target as HTMLButtonElement)
  }

  const closeTagsList = () => {
    setTagsListAnchor(null)
  }

  const removeTag = (text: string) => {
    setDeletedTags(current => [...current, text])
    const index = addedTags.indexOf(text)
    if (index !== -1) {
      setAddedTags(current => current.filter(t => t !== text))
    }
    setEdited(true)
  }

  const addTag = (text: string) => {
    setAddedTags(current => [...current, text])
    const index = deletedTags.indexOf(text)
    if (index !== -1) {
      setDeletedTags(current => current.filter(t => t !== text))
    }
    setEdited(true)
  }

  const toggleTag = (text: string) => {
    if (visibleTags.includes(text)) {
      removeTag(text)
    } else {
      addTag(text)
    }
  }

  const save = () => {
    const status = statusRef.current?.innerText.trim()
    if (!status) {
      alert('Status is empty!')
      return
    }

    let author = authorRef.current?.innerText.trim()
    if (author === LABEL_AUTHOR_EMPTY) {
      author = ''
    }
    const tags = props.quote.tags.concat(addedTags).filter(t => !deletedTags.includes(t))
    props.onUpdate(quote.id, status, author, tags)
    setEdited(false)
  }

  const deleteQuote = () => {
    props.onDelete(quote.id)
  }

  const handleInput = () => {
    setEdited(true)
  }

  const onTagKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value.toLowerCase().trim()
      if (value) {
        addTag(value)
        setEdited(true)
      }

      (e.target as HTMLInputElement).value = ''
    }
  }

  const onAuthorFocus = () => {
    if (authorRef.current?.innerText === LABEL_AUTHOR_EMPTY) {
      authorRef.current.innerText = ''
    }
  }

  const onAuthorBlur = () => {
    if (authorRef.current?.innerText.trim() === '') {
      authorRef.current.innerText = LABEL_AUTHOR_EMPTY
    }
  }

  const visibleTags = quote.tags.concat(addedTags).filter(t => !deletedTags.includes(t))

  return (
    <div className={styles['quote-box']}>
      <div className={styles['cell-id']}>{props.quote.id}</div>
      <div
        contentEditable
        suppressContentEditableWarning
        className={styles['cell-status']}
        ref={statusRef}
        onInput={handleInput}
      >
        {quote.status}
      </div>
      {quote.author && (
        <div
          contentEditable
          suppressContentEditableWarning
          ref={authorRef}
          className={`${styles['cell-fixed-m']} ${styles['cell-author']}`}
          onInput={handleInput}
          onFocus={onAuthorFocus}
          onBlur={onAuthorBlur}
        >
          {quote.author}
        </div>
      )}
      <div className={styles['cell-30']}>
        <div className={styles['tags-box']}>
          {visibleTags.map(tag => (
            <Tag key={tag} text={tag} onDelete={removeTag} />
          ))}
        </div>
        <input type="text" placeholder="Add new tag" onKeyDown={onTagKeyDown} className={styles['tag-input']} />
        <div>
          <Button
            size="small"
            className={styles['btn-select-tags']}
            onClick={openTagsList}
          >
            select tags
          </Button>
        </div>
      </div>
      {quote.language && <div>{quote.language}</div>}
      {quote.source && <div>{quote.source}</div>}
      <div className={styles.actions}>
        <Button size="small" color="primary" disabled={!edited} variant="contained" onClick={save}>Save</Button>
        <ConfirmDialog message="Delete status?">
          {(confirm) => (
            <IconButton size="small" onClick={confirm(deleteQuote)}>
              <IconDelete />
            </IconButton>
          )}
        </ConfirmDialog>
      </div>
      <Popper
        open={Boolean(tagsListAnchor)}
        anchorEl={tagsListAnchor}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={closeTagsList}>
          <Paper classes={{ root: styles['tags-list-paper'] }} elevation={POPUP_ELEVATION}>
            <div className={styles['tags-list']}>
              {props.savedTags.map(t => (
                <Tag
                  key={t}
                  text={t}
                  selected={visibleTags.includes(t)}
                  onClick={toggleTag}
                />
              ))}
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

const Tag = function (props: { text: string, selected?: boolean, onDelete?: (text: string) => void, onClick?: (text: string) => void }) {
  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.text)
    }
  }

  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.text)
    }
  }

  return (
    <Chip
      size="small"
      label={props.text}
      className={`${styles.chip} ${props.selected ? styles['chip-active'] : ''}`}
      onDelete={props.onDelete ? onDelete : undefined}
      onClick={onClick}
    />
  )
}
