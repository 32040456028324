import * as React from 'react'
import AdminPage from 'admin/components/AdminPage'
import { IQuote } from 'admin/interfaces'
import styles from './Statuses.pcss'
import Input from '@mui/material/Input'
import { StoreThunkDispatch } from 'store/state'
import { useDispatch } from 'react-redux'
import { StatusesTable } from './StatusesTable'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import SearchIcon from '@mui/icons-material/Search'
import { CreateStatusIdeaPanel } from './CreateStatusIdeaPanel'
import ScrollListener from 'components/ScrollListener'
import { NavLink } from 'react-router-dom'
import Button from '@mui/material/Button'
import useAsyncAction from 'hooks/useAsyncAction'
import { getStatusesRange, searchStatusIdeas, getTotalStatusesCount, saveSearch } from 'services/content/statuses/actions'

const SCROLL_EMIT_TRESHOLD = 450

export function StatusesRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [statuses, setStatuses] = React.useState<IQuote[]>([])
  const [loading, setLoading] = React.useState(true)
  const [query, setQuery] = React.useState('')
  const [tagQuery, setTagQuery] = React.useState('')
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [getCount, count, _loading] = useAsyncAction(() => dispatch(getTotalStatusesCount()))

  const isTagSearch = React.useRef(false)
  const isRangeSearch = React.useRef(false)
  const pageRef = React.useRef(0)
  const startIDRef = React.useRef<HTMLInputElement>(null)
  const endIDRef = React.useRef<HTMLInputElement>(null)
  const fetch$ = React.useRef<Subject<{ page: number, query: string, isTag: boolean }>>()
  const fetchRange$ = React.useRef<Subject<{ from: number, to: number }>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .flatMap(({ page, query, isTag }) => {
        isTagSearch.current = isTag
        isRangeSearch.current = false
        if (startIDRef.current && endIDRef.current) {
          startIDRef.current.value = ''
          endIDRef.current.value = ''
        }
        setLoading(true)
        dispatch(saveSearch(query)).toPromise()
        return dispatch(searchStatusIdeas(isTag ? '' : query, page, isTag ? query : '')).pipe(catchError(() => {
          dispatch(message('Could not fetch data! Please refresh.'))
          return Observable.of([])
        }))
      })
      .subscribe((response: any) => {
        if (response.length > 0) {
          setStatuses(current => [...current, ...response])
        } else {
          setHasNextPage(false)
        }
        setLoading(false)
      })

    fetch$.current.next({ page: 0, query: '', isTag: false })
    getCount()
  }, [])

  React.useEffect(() => {
    fetchRange$.current = new Subject()
    fetchRange$.current
      .flatMap(({ from, to }) => {
        isTagSearch.current = false
        isRangeSearch.current = true
        setLoading(true)
        return dispatch(getStatusesRange(from, to)).pipe(catchError(() => {
          dispatch(message('Could not fetch data! Please refresh.'))
          return Observable.of([])
        }))
      })
      .subscribe((response: any) => {
        if (response.length > 0) {
          setStatuses(response)
        } else {
          setHasNextPage(false)
        }
        setLoading(false)
      })
  }, [])

  const onSearchKeyDown = (isTag?: boolean) => (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      pageRef.current = 0
      setStatuses([])
      fetch$.current?.next({
        page: pageRef.current,
        query: (e.target as HTMLInputElement).value.trim(),
        isTag: Boolean(isTag)
      })

      if (isTag) {
        setQuery('')
      } else {
        setTagQuery('')
      }
    }
  }

  const onSearchChange = (isTag?: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setHasNextPage(true)
    if (isTag) {
      setTagQuery(value)
    } else {
      setQuery(value)
    }
  }

  const loadNextPage = () => {
    if (!loading && hasNextPage && !isRangeSearch.current) {
      pageRef.current += 1
      fetch$.current?.next({
        page: pageRef.current,
        query: query.trim(),
        isTag: isTagSearch.current
      })
    }
  }

  const resetSearch = () => {
    pageRef.current = 0
    setStatuses([])
    setTagQuery('')
    setQuery('')
    getCount()
    fetch$.current?.next({
      page: pageRef.current,
      query: '',
      isTag: false
    })
  }

  const getStatusesForRange = () => {
    try {
      const startId = parseInt(startIDRef.current?.value + '', 10)
      const endId = parseInt(endIDRef.current?.value + '', 10)

      if (startId > endId) {
        throw new Error('Start ID must be less or equal to End ID')
      }
      pageRef.current = 0
      setStatuses([])
      setTagQuery('')
      setQuery('')

      fetchRange$.current?.next({ from: startId, to: endId })
    } catch (e: any) {
      dispatch(message('Enter valid values for start and end ID! ' + e?.message))
    }
  }

  const scrollElement = document.querySelector('[data-test="admin-content"]') as HTMLElement

  return (
    <AdminPage title="Post Ideas">
      <NavLink to="/admin/conversation-starters/tags">
        Tags
      </NavLink> | <NavLink to="/admin/conversation-starters/keywords">Keywords</NavLink>
      <ScrollListener
        emitTreshold={SCROLL_EMIT_TRESHOLD}
        scrollElement={scrollElement}
        onScroll={loadNextPage}
      >
        <div>
          <CreateStatusIdeaPanel onCreated={resetSearch} />
          <div className={styles['search-box']}>
            <Button variant="contained" size="small" color="primary" onClick={resetSearch}>
              see all{count ? ` (${count})` : ''}
            </Button>
            <Input
              startAdornment={<SearchIcon />}
              placeholder="Search Post Ideas"
              value={query}
              classes={{ root: styles['search-input'] }}
              onChange={onSearchChange()}
              onKeyDown={onSearchKeyDown()}
            />
            <Input
              startAdornment={<SearchIcon />}
              placeholder="Search by tag"
              value={tagQuery}
              classes={{ root: styles['search-input'] }}
              onChange={onSearchChange(true)}
              onKeyDown={onSearchKeyDown(true)}
            />
          </div>
          <div className={`${styles['search-box']} ${styles['search-range-box']}`}>
            Show statuses between ID
            <input type="number" ref={startIDRef} />
            And
            <input type="number" ref={endIDRef} />
            <Button variant="contained" color="primary" size="small" onClick={getStatusesForRange}>Show statuses</Button>
          </div>
          <StatusesTable statuses={statuses} />
          {loading && <h3 className={styles.loader}>Loading...</h3>}
          {!loading && !hasNextPage && statuses.length > 0 && <h3 className={styles.loader}>That's all!</h3>}
          {!loading && !hasNextPage && statuses.length === 0 && <h3 className={styles.loader}>No results found.</h3>}
        </div>
      </ScrollListener>
    </AdminPage>
  )
}

export default StatusesRoute
