import * as React from 'react'
import StreamGroupsManager from './StreamGroupsManager'
import { ContentPanel, StreamGroup } from 'admin/interfaces'
import { StoreThunkDispatch } from 'store/state'
import { updateStreamGroup, reorderStreamGroups, streamRemove, deleteGroup, createGroup } from 'admin/services/actions'
import { connect } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { sortByKeyAscending, reorder } from 'utils/sort/order'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { catchError } from 'rxjs/operators/catchError'
import {
  addStreamGroupToPanel,
  createContentPanel,
  deleteContentPanel,
  getContentPanels,
  updateContentPanel
} from 'admin/services/content-panels/actions'

function getNewOrder(groups: StreamGroup[], itemToMove: { order: number }, newPosition: number) {
  const currentOrder = [...groups].sort(sortByKeyAscending('order'))

  const startIndex = currentOrder.findIndex(item => item.order === itemToMove.order)
  const endIndex = currentOrder.findIndex(item => item.order === newPosition)

  const newOrder = reorder(currentOrder, startIndex, endIndex).map((item, index) => ({ ...item, order: index + 1 }))
  const orderedStreamGroups = newOrder.sort(sortByKeyAscending('order')).map(item => item.id)
  return orderedStreamGroups
}

interface StreamGroupsManagerContainerConnectedProps {
  createGroup: (name: string) => Observable<any>
  updateStreamGroup: (group: Partial<StreamGroup>) => Observable<any>
  streamRemove: (groupId: string, streamId: string) => Observable<any>
  deleteGroup: (groupId: string) => Observable<any>
  message: (text: string) => void
  reorderStreamGroups: (panelId: string, order: string[]) => Observable<any>
  getContentPanels: () => Observable<any>
  updateContentPanel: (id: string, name: string, active: boolean) => Observable<any>
  deleteContentPanel: (id: string) => Observable<any>
  createContentPanel: (name: string) => Observable<any>
  addGroupToPanel: (panelId: string, groupId: string) => Observable<any>
}

export function StreamGroupsManagerContainer(props: StreamGroupsManagerContainerConnectedProps) {
  const [panels, setPanels] = React.useState<ContentPanel[]>([])
  const [loading, setLoading] = React.useState(true)

  const createGroup$ = React.useRef<Subject<{ name: string, panelId: string }>>()
  const updateGroup$ = React.useRef<Subject<{ panelId: string, group: Partial<StreamGroup>, order?: string[] }>>()
  const deleteGroup$ = React.useRef<Subject<string>>()
  const createPanel$ = React.useRef<Subject<string>>()
  const updatePanel$ = React.useRef<Subject<{ id: string, name: string, active: boolean }>>()
  const deletePanel$ = React.useRef<Subject<string>>()
  const removeStream$ = React.useRef<Subject<{ groupId: string, streamId: string }>>()

  const onGetPanelsSuccess = (response: ContentPanel[]) => {
    if (response.length > 0) {
      setPanels(response)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    createGroup$.current = new Subject()
    updateGroup$.current = new Subject()
    deleteGroup$.current = new Subject()
    createPanel$.current = new Subject()
    updatePanel$.current = new Subject()
    deletePanel$.current = new Subject()
    removeStream$.current = new Subject()

    const createPanelSub = createPanel$.current
      .flatMap((name: string) => props.createContentPanel(name).pipe(
        tap(() => {
          props.message('Content panel created')
        }),
        catchError(error => {
          props.message('Create content panel error: ' + error.message)
          return Observable.of([])
        })
      ))
      .flatMap(props.getContentPanels)
      .subscribe(onGetPanelsSuccess)

    const updatePanelSub = updatePanel$.current
      .flatMap(({ id, name, active }) => props.updateContentPanel(id, name, active).pipe(
        tap(() => {
          props.message('Update successful')
        }),
        catchError(error => {
          props.message('Update error: ' + error.message)
          return Observable.of([])
        })
      ))
      .flatMap(props.getContentPanels)
      .subscribe(onGetPanelsSuccess)

    const deletePanelSub = deletePanel$.current
      .flatMap((id: string) => props.deleteContentPanel(id).pipe(
        tap(() => {
          props.message('Content panel deleted!')
        }),
        catchError(error => {
          props.message('Delete error: ' + error.message)
          return Observable.of([])
        })
      ))
      .flatMap(props.getContentPanels)
      .subscribe(onGetPanelsSuccess)

    const createSub = createGroup$.current
      .flatMap(({ name, panelId }) => {
        return props.createGroup(name).flatMap((g) => props.addGroupToPanel(panelId, g.id)).pipe(
          tap(() => {
            props.message('Stream group created')
          }),
          catchError(error => {
            props.message('Create group error: ' + error.message)
            return Observable.of([])
          })
        )
      })
      .flatMap(props.getContentPanels)
      .subscribe(onGetPanelsSuccess)

    const updateSub = updateGroup$.current
      .flatMap(({ panelId, group, order }) => {
        const req = order
          ? props.updateStreamGroup(group).zip(props.reorderStreamGroups(panelId, order))
          : props.updateStreamGroup(group)
        return req.pipe(
          tap(() => {
            props.message('Update successful')
          }),
          catchError(error => {
            props.message('Update error: ' + error.message)
            return Observable.of([])
          })
        )
      })
      .flatMap(props.getContentPanels)
      .subscribe(onGetPanelsSuccess)

    const deleteSub = deleteGroup$.current
      .flatMap((id: string) => props.deleteGroup(id).pipe(
        tap(() => {
          props.message('Group deleted!')
        }),
        catchError(error => {
          props.message('Delete error: ' + error.message)
          return Observable.of({})
        })
      ))
      .flatMap(props.getContentPanels)
      .subscribe(onGetPanelsSuccess)

    const removeStreamSub = removeStream$.current
      .flatMap(({ groupId, streamId }) => props.streamRemove(groupId, streamId).pipe(
        tap(() => {
          props.message('Stream removed!')
        }),
        catchError(error => {
          props.message('Stream remove error: ' + error.message)
          setLoading(false)
          return Observable.of([])
        })
      ))
      .flatMap(props.getContentPanels)
      .subscribe(onGetPanelsSuccess)

    return () => {
      createSub.unsubscribe()
      updateSub.unsubscribe()
      deleteSub.unsubscribe()
      createPanelSub.unsubscribe()
      updatePanelSub.unsubscribe()
      deletePanelSub.unsubscribe()
      removeStreamSub.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    const sub = props.getContentPanels()
      .subscribe((data) => {
        setPanels(data)
        setLoading(false)
      }, (error) => {
        props.message('Failed to fetch content: ' + error.message)
        setLoading(false)
      })
    return () => {
      sub.unsubscribe()
    }
  }, [])

  const createNewGroup = (name: string, panelId: string) => {
    setLoading(true)
    createGroup$.current?.next({ name, panelId })
  }

  const updateStreamGroup = (panel: ContentPanel, group: Partial<StreamGroup>, order?: number) => {
    const streamGroupsOrder = typeof order === 'number'
      ? getNewOrder(panel.streamGroups, group as any, order)
      : undefined
    updateGroup$.current?.next({ group, order: streamGroupsOrder, panelId: panel.id })
    setLoading(true)
  }

  const streamRemove = (groupId: string, streamId: string) => {
    setLoading(true)
    removeStream$.current?.next({ groupId, streamId })
  }

  const deleteGroup = (groupId: string) => {
    setLoading(true)
    deleteGroup$.current?.next(groupId)
  }

  const createContentPanel = (name: string) => {
    setLoading(true)
    createPanel$.current?.next(name)
  }

  const deleteContentPanel = (id: string) => {
    setLoading(true)
    deletePanel$.current?.next(id)
  }

  const updateContentPanel = (id: string, name: string, active: boolean) => {
    setLoading(true)
    updatePanel$.current?.next({ id, name, active })
  }

  return (
    <StreamGroupsManager
      panels={panels}
      loading={loading}
      onUpdateStreamGroup={updateStreamGroup}
      onStreamRemove={streamRemove}
      onDeleteGroup={deleteGroup}
      onCreateGroup={createNewGroup}
      onCreatePanel={createContentPanel}
      onDeletePanel={deleteContentPanel}
      onUpdatePanel={updateContentPanel}
    />
  )
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    updateStreamGroup: (group: Partial<StreamGroup>) => dispatch(updateStreamGroup(group)),
    streamRemove: (groupId: string, streamId: string) => dispatch(streamRemove(groupId, streamId)),
    deleteGroup: (groupId: string) => dispatch(deleteGroup(groupId)),
    message: (text: string) => dispatch(message(text)),
    reorderStreamGroups: (panelId: string, order: string[]) => dispatch(reorderStreamGroups(panelId, order)),
    createGroup: (name: string) => dispatch(createGroup(name)),
    getContentPanels: () => dispatch(getContentPanels()).pipe(catchError(() => {
      dispatch(message('Could not fetch data!'))
      return Observable.of([])
    })),
    createContentPanel: (name: string) => dispatch(createContentPanel(name)),
    updateContentPanel: (id: string, name: string, active: boolean) => dispatch(updateContentPanel(id, name, active)),
    deleteContentPanel: (id: string) => dispatch(deleteContentPanel(id)),
    addGroupToPanel: (panelId: string, groupId: string) => dispatch(addStreamGroupToPanel(panelId, groupId))
  }
}

export default connect(null, mapDispatchToProps)(StreamGroupsManagerContainer)
