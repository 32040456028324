import { V1 } from './net'
import { StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'

export function searchTeamMembers(emails: string[] = [], userIds: string[] = [], appIds: string[] = []) {
  return (dispatch: StoreThunkDispatch) => {
    if (emails.length + userIds.length + appIds.length === 0) {
      return Observable.of([])
    }
    return dispatch(V1.searchTeamMembers(emails, userIds, appIds))
  }
}

export function switchChargifyAccounts(ids: string[]) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.switchChargifyAccounts(ids))
  }
}
