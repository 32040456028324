import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL } from 'config'

export const ENDPOINT_SEARCH_TEAM_MEMBERS = `${ADMIN_SERVICE_URL}/team/status`
export const ENDPOINT_SWITCH_CHARGIFY_ACCOUNTS = `${ADMIN_SERVICE_URL}/team/switch_chargify_accounts`

export const V1 = Object.freeze({
  searchTeamMembers: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_TEAM_MEMBERS).asGet().expectJSON()

    return function (emails: string[], userIds: string[], appIds: string[]) {
      return authorizedPipe<any>(
        builder
          .param('emails', emails.join(','))
          .param('user_ids', userIds.join(','))
          .param('app_ids', appIds.join(','))
          .build()
      )
    }
  }(),
  switchChargifyAccounts: function () {
    const builder = new RequestBuilder(ENDPOINT_SWITCH_CHARGIFY_ACCOUNTS).asPost().expectJSON()

    return function (userIds: string[]) {
      const data = new FormData()
      userIds.forEach(id => { data.append('user_ids[]', id) })

      return authorizedPipe<any>(
        builder
          .form(data)
          .build()
      )
    }
  }()
})
